import moment from 'moment';
import {
  CartesianGrid,
  Line,
  LineChart,
  ResponsiveContainer,
  XAxis,
  YAxis,
  Tooltip,
} from 'recharts';
import ToolTip from '../../shiftLoaderGraph/components/ToolTip';
import TrafficLightIndicator from './TrafficLightIndicator';
import { convertHoursToTimeString } from '../utils';

//@ts-ignore
const DailyCustomizedAxisTick = (props) => {
  return (
    <g transform={`translate(${props.x},${props.y})`}>
      <text
        x={0}
        y={0}
        dx={5}
        dy={5}
        fontSize={12}
        textAnchor="end"
        stroke="#21B291"
        strokeWidth={0}
        fontWeight={'600'}
        fill={'#707070'}
      >
        {props.payload?.value}
      </text>
    </g>
  );
};
//@ts-ignore
const CustomizedLabel = (props) => {
  const { x, y, width } = props.viewBox;

  return (
    <text
      x={x + width}
      y={y + 3}
      dx={33}
      textAnchor="end"
      fill={'#666'}
      fontSize={10}
      fontWeight={'bold'}
    >
      Date
    </text>
  );
};

//@ts-ignore
const DailyCustomizedAxisTick2 = (props) => {
  return (
    <g transform={`translate(${props.x},${props.y})`}>
      <text
        x={0}
        y={0}
        dx={15}
        dy={5}
        fontSize={12}
        textAnchor="end"
        stroke="#21B291"
        strokeWidth={0}
        fontWeight={'600'}
        fill={'#707070'}
      >
        {parseInt(props.payload?.value)}
      </text>
    </g>
  );
};
//@ts-ignore
const CustomizedLabel2 = (props) => {
  const { x, y, width, height } = props.viewBox;

  return (
    <text
      x={x + 75}
      y={y - 10}
      // dy={10}
      textAnchor="end"
      fill={'#666'}
      fontSize={12}
      // rotate={-90}
      transform={`rotate(-90, ${x + width}, ${y + Math.round(height / 2)})`}
      fontWeight={'bold'}
    >
      Hours
    </text>
  );
};
// Custom tooltip component
// @ts-ignore
const CustomToolTip = (props) => {
  const { label, payload, active, month, year } = props;

  if (payload?.length && active && label) {
    return (
      <ToolTip
        date={`${label}/${year}`}
        metrics={[
          {
            Component: (
              <div
                style={{ fontSize: 14 }}
                className=" mt-1 me-3 d-flex align-items-center"
              >
                <TrafficLightIndicator stop={false} shadow={false} size={8} />
                <div className="ms-1">Runtime</div>
              </div>
            ),
            isComponent: true,
            value: convertHoursToTimeString(payload[0].payload?.runtimeHours),
          },
          {
            Component: (
              <div
                style={{ fontSize: 14 }}
                className=" mt-1 me-3 d-flex align-items-center"
              >
                <TrafficLightIndicator stop={true} shadow={false} size={8} />
                <div className="ms-1">Stopped</div>
              </div>
            ),
            isComponent: true,
            value: convertHoursToTimeString(payload[0].payload?.stoppedHours),
          },
        ]}
      />
    );
  }
  return <></>;
};

const MonthlyHopperGraph = (props: any) => {
  return (
    <ResponsiveContainer height={140}>
      <LineChart
        data={props.data}
        margin={{ left: 20, top: 30, bottom: 10, right: 45 }}
      >
        <CartesianGrid
          vertical={false}
          horizontal={true}
          strokeDasharray="3 3"
        />
        <XAxis
          tickMargin={15}
          interval={'preserveStart'}
          stroke="#3E5D90"
          padding={{ left: 30, right: 30 }}
          dataKey="Day"
          tickLine={false}
          label={<CustomizedLabel />}
          tick={<DailyCustomizedAxisTick />}
        />

        <YAxis
          domain={[0, 24]}
          label={<CustomizedLabel2 />}
          tick={<DailyCustomizedAxisTick2 />}
          tickMargin={20}
          axisLine={false}
          tickLine={false}
        />
        <Tooltip
          cursor={{ stroke: '#07795F', strokeWidth: 1 }}
          content={
            <CustomToolTip
              month={props.month?.toString().padStart(2, '0')}
              year={props.year}
            />
          }
        />
        <Line
          dot={false}
          activeDot={{ r: 0 }}
          dataKey="stoppedHours"
          stroke="#EB6060"
        />
        <Line
          dot={false}
          activeDot={{ r: 0 }}
          dataKey="runtimeHours"
          stroke="#04D149"
        />
      </LineChart>
    </ResponsiveContainer>
  );
};

export default MonthlyHopperGraph;
