import { Load } from '../../types';
import { TimerData } from './types';

/**
 * Finds the seconds remaining
 * based on the endTime of the first load(last load according to time) in the provided array.
 * @param loads Array of loads to determine the seconds remaining from
 * @returns TimerData object containing seconds remaining and loads due
 */

export const findLoadSecondsRemaining = (loads: Load[]): TimerData => {
  const currentTime = new Date();

  // Calculate start and end of the current hour
  const currentHourStart = new Date(currentTime);
  currentHourStart.setMinutes(0, 0, 0);
  const currentHourEnd = new Date(currentHourStart);
  currentHourEnd.setHours(currentHourEnd.getHours() + 1);

  return {
    secondsRemaining: Math.max(
      3600 -
        Math.floor(
          (currentTime.getTime() - new Date(loads[0]?.endTime).getTime()) /
            1000,
        ),
      0,
    ),
    loadsDue: 3,
  };
};
