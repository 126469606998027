export enum DateTimeFormats {
  TIMESTAMP = 'YYYY-MM-DD hh:mm a',
  DATE = 'YYYY-MM-DD',
  TIME_U = 'hh:mm A',
  TIME_L = 'hh:mm a',
  START_HOUR_U = 'hh:00 A',
}

export enum DefaultTimes {
  DEFAULT_FROM_TIME = '12:00 am',
  DEFAULT_TO_TIME = '11:59 pm',
}

export enum TrafficLightColor {
  GREEN = 'green',
  RED = 'red',
}
