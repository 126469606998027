import { Image } from 'react-bootstrap';

const TopButton = ({ onClick, disabled, style, img, title, active }) => {
  return (
    <div
      className="d-flex align-items-center justify-content-center"
      onClick={disabled ? null : onClick}
      style={{
        zIndex: 10,
        height: '2rem',
        padding: '0.1rem 0.7rem',
        boxShadow: '0px 0.25rem 0.33rem #00000029',
        borderRadius: '0.34rem',
        border: active ? '1px solid #21B291' : '',
        cursor: 'pointer',
        font: active
          ? 'normal normal bold 12px/18px Open Sans'
          : 'normal normal normal 12px/18px Open Sans',
        background: active ? '#F2FFF7' : '#E2E2E2',
        color: active ? 'black' : '#3C3B3B',
        width: '6rem',
        marginLeft: '0px',
        ...style,
      }}
    >
      <Image
        style={{ height: '1rem' }}
        src={require(`../../assets/images/${img}`)}
      />
      <p style={{ fontSize: '0.9rem', margin: '5px 0px 5px 5px' }}>{title}</p>
    </div>
  );
};

export default TopButton;
