import moment from 'moment';
import {
  Load,
  FilterValues,
  ShiftLoaderData,
  UnprocessedShiftLoaderData,
  DateRangeDetails,
} from './types';
import {
  ShiftLoaderDateRangeType,
  ShiftLoadsGraphOption,
} from '../../utils/constants';
import { DateTimeFormats, DefaultTimes } from './constants';

export const isShiftLoadWithinTimeRangeOfFilters = (
  filterValues: FilterValues,
  load: Load,
) => {
  const fromTime = moment(
    `${filterValues.dateRange.from} ${
      filterValues.from || DefaultTimes.DEFAULT_FROM_TIME
    }`,
    DateTimeFormats.TIMESTAMP,
  );
  const toTime = moment(
    `${filterValues.dateRange.to || filterValues.dateRange.from} ${
      filterValues.to || DefaultTimes.DEFAULT_TO_TIME
    }`,
    DateTimeFormats.TIMESTAMP,
  );

  const loadEndTime = moment(load.endTime);
  return fromTime <= loadEndTime && toTime >= loadEndTime;
};

export const typeCorrectedLoadsAfterFetching = (
  loadData: UnprocessedShiftLoaderData,
): ShiftLoaderData => {
  const processedLoads = loadData.loads.map((load) => ({
    ...load,
    startTime: new Date(load.startTime),
    endTime: new Date(load.endTime),
  }));
  return { ...loadData, loads: processedLoads };
};

export const getUnixTimestampsFromFilter = (
  filters: FilterValues,
  addExtraMinute?: boolean,
) => {
  const { from, to, dateRange } = filters;
  const { from: fromDate, to: toDate } = dateRange;

  // Determine the times to use
  const fromTime = from || DefaultTimes.DEFAULT_FROM_TIME;
  const toTime = to || DefaultTimes.DEFAULT_TO_TIME;

  // Calculate Unix timestamps
  const fromDateTimestamp = getUnixTimestamp(fromDate, fromTime);
  let toDateTimestamp = getUnixTimestamp(toDate || fromDate, toTime);

  if (!to && addExtraMinute) {
    // Add one minute to toDate
    // If default to time of 11:59 pm is used, so the whole day is included
    toDateTimestamp += 1000 * 60;
  }

  return {
    fromDate: fromDateTimestamp,
    toDate: toDateTimestamp,
  };
};

export const getUnixTimestamp = (date: string, time: string): number =>
  moment(`${date} ${time}`, DateTimeFormats.TIMESTAMP).unix() * 1000;

export const isCurrentDate = (
  dateString: string,
  graphType: ShiftLoadsGraphOption,
  value?: string,
) => {
  const today = moment();
  const inputDate = moment(dateString, DateTimeFormats.DATE);
  // return today.date() === inputDate.date() && today.month() === inputDate.month();
  switch (graphType) {
    case ShiftLoadsGraphOption.DAYS:
      return (
        today.date() === inputDate.date() && today.format('DD/MM') === value
      );
    case ShiftLoadsGraphOption.MONTHS:
      return today.format('MMMM') === value;
    default:
      return false;
  }
};

export const getDateRangeType = (
  filterValues: FilterValues,
): ShiftLoaderDateRangeType => {
  const fromDateStr = filterValues.dateRange.from;
  const toDateStr = filterValues.dateRange.to;

  const fromTimeStr = filterValues.from;
  const toTimeStr = filterValues.to;

  if (!toDateStr) {
    if (fromDateStr == moment().format(DateTimeFormats.DATE)) {
      if (!fromTimeStr && !toTimeStr)
        return ShiftLoaderDateRangeType.CURRENT_DAY;
      else return ShiftLoaderDateRangeType.CURRENT_DAY_WITH_RANGE;
    } else {
      if (!fromTimeStr && !toTimeStr) return ShiftLoaderDateRangeType.PAST_DAY;
      else return ShiftLoaderDateRangeType.PAST_DAY_WITH_RANGE;
    }
  }
  const fromDateTime = moment(
    `${fromDateStr} ${fromTimeStr || DefaultTimes.DEFAULT_FROM_TIME}`,
    DateTimeFormats.TIMESTAMP,
  );
  const toDateTime = moment(
    `${toDateStr} ${toTimeStr || DefaultTimes.DEFAULT_TO_TIME}`,
    DateTimeFormats.TIMESTAMP,
  );

  const hoursDifference = toDateTime.diff(fromDateTime, 'hours');

  return hoursDifference > 48
    ? ShiftLoaderDateRangeType.RANGE_48_PLUS
    : ShiftLoaderDateRangeType.RANGE_48;
};

export const getDateRangeDetails = (
  filterValues: FilterValues,
): DateRangeDetails => {
  const fromDateStr = filterValues.dateRange.from;
  const toDateStr = filterValues.dateRange.to || fromDateStr;

  const fromTimeStr = filterValues.from || DefaultTimes.DEFAULT_FROM_TIME;
  const toTimeStr = filterValues.to || DefaultTimes.DEFAULT_TO_TIME;

  const fromDateTime = moment(
    `${fromDateStr} ${fromTimeStr}`,
    DateTimeFormats.TIMESTAMP,
  );
  const toDateTime = moment(
    `${toDateStr} ${toTimeStr}`,
    DateTimeFormats.TIMESTAMP,
  );

  if (!fromDateTime.isValid() || !toDateTime.isValid()) {
    throw new Error('Invalid date or time format.');
  }

  return {
    startTimeUnix: fromDateTime.unix(),
    endTimeUnix: toDateTime.unix(),
    startHourUnix: fromDateTime.startOf('hour').unix(),
    startDayUnix: fromDateTime.startOf('day').unix(),
    endDayUnix: toDateTime.endOf('day').unix(),
    startMonthUnix: fromDateTime.startOf('month').unix(),
    endMonthUnix: toDateTime.endOf('month').unix(),
    days: toDateTime.diff(fromDateTime, 'days') + 1,
    hours: toDateTime.diff(fromDateTime, 'hours'),
    months: toDateTime.diff(fromDateTime, 'months', true),
  };
};
