import React, { useState, useEffect } from 'react';
import {
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  ReferenceLine,
  ResponsiveContainer,
  ReferenceArea,
  LineChart,
  Tooltip,
} from 'recharts';
import moment from 'moment';
import { FilterValues } from '../../../types';
import ToolTip from '../components/ToolTip';
import {
  GraphData,
  HourlyGraphData,
  ShiftLoadGraphProps,
} from '../../../../../utils/types';
import { DateTimeFormats } from '../../../constants';

//@ts-ignore
const CustomizedAxisTick = (props) => {
  const { x, y, payload, filterValues } = props;
  const isLastTick =
    moment().format(DateTimeFormats.DATE) == filterValues.date &&
    payload?.value === moment().format(DateTimeFormats.TIME_U);
  return (
    <g transform={`translate(${x},${y})`}>
      <text
        x={0}
        y={0}
        dy={5}
        dx={25}
        textAnchor="end"
        fill={isLastTick ? 'black' : '#666'}
        stroke="black"
        strokeWidth={isLastTick ? 0.75 : 0}
        fontWeight={isLastTick ? '900' : 'normal'}
      >
        {payload?.value}
      </text>
    </g>
  );
};

// Customized label component for the x-axis
// @ts-ignore
const CustomizedLabel = (props) => {
  const { x, y, width } = props.viewBox;
  return (
    <text
      x={x + width}
      y={y + 3}
      dx={33}
      textAnchor="end"
      fill={'#666'}
      fontWeight={'bold'}
    >
      Time
    </text>
  );
};

// Custom tooltip component
// @ts-ignore
const CustomToolTip = (props) => {
  const { label, payload, active } = props;

  if (payload?.length && active && label) {
    return (
      <ToolTip
        date={
          label +
          ' - ' +
          moment(label, 'hh:mm A').add(1, 'hours').format('hh:mm A')
        }
        description={payload[0].payload?.date}
        metrics={[
          { label: 'Loads', value: payload[0]?.value },
          {
            label: 'Tonnes',
            value: payload[0]?.value * 10,
          },
        ]}
      />
    );
  }
  return <></>;
};

// Interface for the component props
interface HourlyProps {
  data: GraphData[];
  filterValues: FilterValues;
}

const HourlyLine: React.FC<ShiftLoadGraphProps<HourlyGraphData>> = ({
  data,
  filterValues,
  children,
}) => {
  return (
    <>
      <ResponsiveContainer height={250}>
        {data.length > 0 ? (
          <LineChart
            data={data}
            margin={{ top: 5, right: 50, left: 10, bottom: 5 }}
          >
            <CartesianGrid vertical={false} strokeDasharray="3 3" />
            <Tooltip content={<CustomToolTip />} />
            <ReferenceArea
              x1={data[0].time}
              x2={data[data.length - 1].time}
              y1={3}
              fill="#FF00000D"
            />
            <XAxis
              tickMargin={20}
              tickLine={false}
              interval={'preserveStart'}
              label={<CustomizedLabel />}
              stroke="#3E5D90"
              minTickGap={1}
              dataKey="time"
              tick={<CustomizedAxisTick filterValues={filterValues} />}
              padding={{ left: 40, right: 40 }}
            />
            <YAxis
              tickLine={false}
              domain={[0, (dataMax: number) => Math.max(dataMax, 5)]}
              ticks={Array.from(
                {
                  length: Math.max(...data.map((d) => d.loads), 5) + 1,
                },
                (_, i) => i,
              )}
              padding={{ top: 15 }}
              label={{
                value: 'Loads',
                fontWeight: 'bold',
                angle: -90,
                position: 'insideLeft',
              }}
              tickMargin={10}
              axisLine={false}
            />
            <Line
              dataKey="loads"
              stroke="#2626DB"
              activeDot={{ r: 0 }}
              dot={false}
            />
            <ReferenceLine
              label={{
                value: 'Target',
                fontWeight: 'bold',
                position: 'right',
              }}
              y={3}
              stroke="#07795F"
            />
          </LineChart>
        ) : (
          <p className="d-flex justify-content-center align-items-center">
            No Hourly data found
          </p>
        )}
      </ResponsiveContainer>
      {children}
    </>
  );
};

export default HourlyLine;
