import React from 'react';
import KeyValueLabels from './KeyValueLabels';
import './styles.scss';
import { TruckSubSectionProps } from './types.js';
import LabelWithLine from './LabelWithLine';
import StatusDot from '../../../dustControl/components/StatusDot';
import { truckColorMapping } from '../../utils/constants';
import { getShiftSummaryFields } from './helper';
import { formatDateToDDMMYYYY } from '../../utils/helper';
import JobDetails from './JobDetails';

const TruckSubSections: React.FC<TruckSubSectionProps> = ({
  pickup,
  truck,
}) => {
  return (
    <>
      <div className="pt-3">
        <KeyValueLabels
          value={
            <StatusDot
              style={{ background: truckColorMapping[truck.status] }}
              className="large"
            >
              <span>{truck?.status}</span>
            </StatusDot>
          }
          title={'Truck Status:'}
        />
        <KeyValueLabels value={truck.operator ?? '-'} title={'Operator:'} />
      </div>

      {/* Job Summary section */}
      <JobDetails pickup={pickup} isTruckSection={true} />

      {/* Shift summary section */}
      <div className="m-1">
        <LabelWithLine
          title={`Shift Summary for ${formatDateToDDMMYYYY(new Date())} `}
        />
        <div className="mt-3">
          {getShiftSummaryFields(truck).map(({ title, value }) => (
            <KeyValueLabels key={title} title={title} value={value} />
          ))}
        </div>
      </div>
    </>
  );
};

export default TruckSubSections;
