import React from 'react';
import ReactDOM from 'react-dom';
import source from "../assets/images/source-tooltip-icon.svg";
import close from "../assets/icons/close-icon.svg";
import { Image } from 'react-bootstrap';
import moment from 'moment';
import materialIcon from './../assets/svgs/material_breakdown.svg';
import "./index.css"
import { DetailRow } from './DetailRow';

export const MaterialPopup = (coordinate, { title, data, onClose = () => { } }) => {
    const div = document.createElement('div');
    const type = data?.type === 'source' ? 'Source Point' : 'Dump Point';

    const popup = (
        <div className="detail-popup d-flex flex-column p-1 px-2 d-flex" >
            <div className="d-flex p-0 m-0 justify-content-end">
                <Image onClick={onClose} src={close} style={{ height: "0.8rem" }} />
            </div>
            <div className="d-flex py-2 pt-1 px-1 justify-content-between">
                <div className="d-flex justify-content-start  ">
                    <div className="">
                       
                        <Image src={materialIcon} style={{ height: "1rem" }} />
                    </div>
                    <div
                        className="p-0 m-0 pb-1 px-2 text-black color-black"
                        style={{ font: " normal normal 600 0.8rem Open Sans" }}
                    >
                        MATERIAL BREAKDOWN
                    </div>
                </div>
            </div>
            <div style={{ borderTop: "1px solid #EBEBEB" }} className=" py-2 px-1 w-100 d-flex flex-column">
                <DetailRow label="Truck" value={data?.objectTitle} />
                <DetailRow label="From" value={data?.sourcePoint ?? '-'} />
                <DetailRow label="At" value={data?.timestamp ? moment.unix(data?.timestamp * 1000)?.format("hh:mm a")  : "-"} />

            </div>
            <div className="popup-tip-container_start_popup" />

        </div>
    );

    ReactDOM.render(popup, div);
    return div;
};
