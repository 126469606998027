//@ts-nocheck
import { useEffect, useMemo, useState } from 'react';
import { GroupedItem } from './GroupedItem';
import {
  addColorsToGroupedTrucks,
  getFilterData,
  getSortedDataLength,
  groupBinsByStatus,
} from '../../utils/helpers';
import {
  BinWithPickup,
  GeneralBinStatus,
  ListForMapProps,
  MODULES,
  TruckData,
  SortStatus,
} from '../../utils/types';
import { useSearchParams } from 'react-router-dom';
import { isEmpty } from 'lodash';
import {
  areBinsSortedByLastEmptiedOrLastUpdated,
  applyDefaultSortAndSearchQueryToBins,
  isBinsModuleAndSearchOrSortApplied,
  isTrucksModuleSearchAndSort,
  applyDefaultSortAndSearchQueryToTrucks,
} from '../../utils/helper';
import DropdownField from '../FormDropdown/DropdownField';
import { BinRow } from './BinRow';
import { TruckRow } from './TruckRow';
import { GroupedItem as GroupedItemType } from './types';
const ListForMap: React.FC<ListForMapProps> = ({
  data,
  isLoading,
  showDetails,
  module,
  binDetails,
  truckDetails,
  setShowDetailsSection,
}) => {
  const [binSearchQuery, setBinSearchQuery] = useState('');
  const [truckSearchQuery, setTruckSearchQuery] = useState('');
  const [filter, setFilter] = useSearchParams();
  const [selectedOption, setSelectedOption] = useState(SortStatus.BIN_LEVEL);
  const [selectedTruckOption, setSelectedTruckOption] = useState(
    SortStatus.TRUCK_AVAILABILITY,
  );
  const { binFilterQueries, truckFilterQueries } = getFilterData(filter);
  const [dataLength, setDataLength] = useState();
  const [truckdataLength, setTruckdataLength] = useState();
  const selectedBinId = binDetails?._id;
  const selectedTruckId = truckDetails?._id;

  const handleSelectRow = (bin) => {
    showDetails(bin);
  };

  const handleClearFilter = () => {
    if (module === MODULES.BINS) {
      setFilter((urlSearchParams: URLSearchParams) => {
        urlSearchParams.delete('b');
        setSelectedOption(SortStatus.BIN_LEVEL);
        return new URLSearchParams(urlSearchParams);
      });
    } else
      setFilter((urlSearchParams: URLSearchParams) => {
        urlSearchParams.delete('t');
        return new URLSearchParams(urlSearchParams);
      });
  };

  const groupedData: GroupedItemType[] = useMemo(() => {
    if (module === MODULES.BINS) return groupBinsByStatus(data);
    else return addColorsToGroupedTrucks(data);
  }, [data, module]);

  useEffect(() => {
    const arrays = [];
    groupedData?.forEach((item: BinWithPickup) => {
      if (Array.isArray(item.data)) {
        arrays.push(...item.data);
      }
    });
    setDataLength(data.length);
    setTruckdataLength(arrays.length);
  }, [isLoading]);

  const sortedData: GroupedItemType[] | TruckData[] | BinWithPickup[] =
    useMemo(() => {
      if (module === MODULES.BINS) {
        return applyDefaultSortAndSearchQueryToBins(
          groupedData,
          binSearchQuery.trim(),
          selectedOption,
        );
      }
      if (module === MODULES.TRUCKS) {
        let truckData = [];
        groupedData?.forEach((item) => {
          if (Array.isArray(item.data)) {
            truckData.push(...item.data);
          }
        });
        if (truckSearchQuery || selectedTruckOption === SortStatus.LAST_LOAD) {
          return applyDefaultSortAndSearchQueryToTrucks(
            truckData,
            truckSearchQuery.trim(),
            selectedTruckOption,
          );
        } else return groupedData;
      }
      return groupedData;
    }, [
      groupedData,
      binSearchQuery,
      selectedOption,
      selectedTruckOption,
      module,
      truckSearchQuery,
    ]);

  const inProgressAndRequestedItems = groupedData?.filter(
    (item) =>
      item.title === GeneralBinStatus.IN_PROGRESS ||
      item.title === GeneralBinStatus.REQUESTED,
  );

  const handleSearch = (value: string) => {
    if (module === MODULES.BINS) {
      setBinSearchQuery(value);
    } else {
      setTruckSearchQuery(value);
    }
  };

  const sortedDataLength: number = useMemo(
    () => getSortedDataLength(sortedData),
    [sortedData],
  );

  return (
    <div>
      <div>
        <div>
          <div className="list-maincontainer">
            <div className="d-flex justify-content-between align-items-center">
              <div className="search-container">
                <i className="mr-2 fa-regular fa-magnifying-glass search-button"></i>
                <div style={{ width: '0.5rem' }}></div>
                <input
                  type="search"
                  className="shadow-none w-100 input-style"
                  value={
                    module === MODULES.BINS ? binSearchQuery : truckSearchQuery
                  }
                  placeholder={'Search'}
                  onChange={(e) => handleSearch(e.target.value)}
                />
              </div>
            </div>
            {module === MODULES.BINS && (
              <div style={{ display: 'flex' }}>
                <div style={{ flex: 1 }}>
                  <DropdownField
                    arrowOutside
                    textColor={'input-dropdown-color'}
                    selectedOptions={selectedOption}
                    customDropdownlayout={'customDropDownLayout'}
                    allOptions={[
                      {
                        name: SortStatus.BIN_LEVEL_TITLE,
                        value: SortStatus.BIN_LEVEL,
                      },
                      {
                        name: SortStatus.LAST_EMTIED_TITLE,
                        value: SortStatus.LAST_EMPTIED,
                      },
                      {
                        name: SortStatus.LAST_UPDATED_TITLE,
                        value: SortStatus.LAST_UPDATED,
                      },
                    ]}
                    lowerLevelClassName="inner-date-input p-0"
                    multiselect={false}
                    fieldKey={'b'}
                    handleChange={(fieldName) => {
                      setSelectedOption(fieldName.optionValue);
                      setFilter({ b: fieldName.optionValue });
                    }}
                  />
                </div>

                {selectedBinId && (
                  <div
                    style={{
                      color: '#0053D8',
                      fontSize: '14px',
                      fontWeight: 'bold',
                      textDecoration: 'underline',
                      cursor: 'pointer',
                      flex: 0,
                      textWrap: 'nowrap',
                    }}
                    onClick={() => {
                      setShowDetailsSection(true);
                    }}
                  >
                    Bin details {'>'}
                  </div>
                )}
              </div>
            )}
            {module === MODULES.TRUCKS && (
              <div style={{ display: 'flex' }}>
                <div style={{ flex: 1 }}>
                  <DropdownField
                    arrowOutside
                    textColor={'input-dropdown-color'}
                    selectedOptions={selectedTruckOption}
                    customDropdownlayout={'customDropDownLayout'}
                    allOptions={[
                      {
                        name: SortStatus.TRUCK_AVAILABILITY_TITLE,
                        value: SortStatus.TRUCK_AVAILABILITY,
                      },
                      {
                        name: SortStatus.TRUCK_STATUS_TITLE,
                        value: SortStatus.TRUCK_STATUS,
                      },
                      {
                        name: SortStatus.LAST_LOAD_TITLE,
                        value: SortStatus.LAST_LOAD,
                      },
                    ]}
                    lowerLevelClassName="inner-date-input p-0"
                    multiselect={false}
                    fieldKey={'t'}
                    handleChange={(fieldName) => {
                      setSelectedTruckOption(fieldName.optionValue);
                      setFilter({ t: fieldName.optionValue });
                    }}
                  />
                </div>

                {selectedTruckId && (
                  <div
                    style={{
                      color: '#0053D8',
                      fontSize: '14px',
                      fontWeight: 'bold',
                      textDecoration: 'underline',
                      cursor: 'pointer',
                      flex: 0,
                      textWrap: 'nowrap',
                    }}
                    onClick={() => {
                      setShowDetailsSection(true);
                    }}
                  >
                    Truck details {'>'}
                  </div>
                )}
              </div>
            )}
          </div>
        </div>

        {isLoading ? (
          <div className="list p-2">Loading...</div>
        ) : (
          <div className="list">
            {/* If sort but NO search applied, the inprogress and requested items must be displayed at the top*/}
            {areBinsSortedByLastEmptiedOrLastUpdated(
              module,
              selectedOption,
              binSearchQuery,
            ) &&
              inProgressAndRequestedItems.map((group: BinWithPickup, index) => (
                <GroupedItem
                  key={index}
                  group={group}
                  onSelectRow={handleSelectRow}
                  selectedBinId={selectedBinId}
                  showDetails={showDetails}
                />
              ))}

            {/* Render truck rows without grouping if trucks is being searched */}
            {isTrucksModuleSearchAndSort(
              module,
              truckSearchQuery,
              selectedTruckOption,
            )
              ? sortedData.map((truck: TruckData, index) => (
                  <TruckRow
                    onSelectRow={handleSelectRow}
                    isSelected={selectedTruckId === truck._id}
                    truck={truck}
                    isFirstItem={index === 0}
                  />
                ))
              : // Else render bin rows without grouping if bins are being searched/sorted
                isBinsModuleAndSearchOrSortApplied(
                    module,
                    binSearchQuery,
                    selectedOption,
                  )
                ? sortedData?.map((bin: BinWithPickup, index) => (
                    <BinRow
                      key={bin?._id} // Use bin.id for a unique key
                      bin={bin}
                      isFirstItem={index === 0}
                      onSelectRow={handleSelectRow}
                      isSelected={selectedBinId === bin._id}
                      sortType={selectedOption}
                    />
                  ))
                : // else render grouped data
                  sortedData?.map((group: BinWithPickup, index) => (
                    <GroupedItem
                      key={index}
                      group={group}
                      onSelectRow={handleSelectRow}
                      selectedBinId={selectedBinId}
                      showDetails={showDetails}
                      selectedTruckId={selectedTruckId}
                    />
                  ))}
          </div>
        )}
      </div>

      {!isLoading && (
        <div className="footer">
          {module === MODULES.TRUCKS ? (
            <div className="display-footer">{`Displaying ${sortedDataLength}/${truckdataLength} trucks`}</div>
          ) : (
            <div className="display-footer">{`Displaying ${sortedDataLength}/${dataLength} scrap bins`}</div>
          )}
          {!isEmpty(binFilterQueries) || !isEmpty(truckFilterQueries) ? (
            <div onClick={handleClearFilter} className="clear-button">
              Clear Filter
            </div>
          ) : null}
        </div>
      )}
    </div>
  );
};

export default ListForMap;
