import React, { useEffect, useState } from 'react';
import Select from 'react-select';
import { GET_MATERIALS } from '../../action/apiPath';
import { CallWithAuth } from '../../../pages/service/apiActions';

const PolygonForm = ({ data, onChange }) => {
  const [materialsData, setMaterialsData] = useState([]);

  useEffect(() => {
    const fetchMaterials = async () => {
      try {
        const response = await CallWithAuth('GET', GET_MATERIALS);
        if (response?.res?.data?.data?.length) {
          setMaterialsData(
            response?.res?.data?.data?.map(
              ({ materialType, MaterialType }) => ({
                label: MaterialType || materialType,
                value: MaterialType || materialType,
              }),
            ),
          );
        }
      } catch (error) {
        console.log(error);
      }
    };
    fetchMaterials();
  }, []);

  const handleMaterialChange = (selectedOptions) => {
    const selectedMaterials = selectedOptions?.map((option) => option.value);
    onChange({
      ...data,
      materials: selectedMaterials,
    });
  };

  return (
    <div style={{ fontSize: '0.8rem', marginTop: '0.8rem' }}>
      <div style={{ borderTop: '1px solid #707070' }}>
        <p style={{ fontFamily: 'Open Sans', fontSize: '0.8rem' }}>Details</p>
      </div>
      <div className="my-2" style={{ display: 'flex' }}>
        <div style={{ width: '25%' }}>Name*</div>
        <div style={{ width: '80%' }}>
          <input
            type="text"
            name="name"
            value={data.name}
            onChange={(e) => {
              onChange({ ...data, name: e?.target?.value });
            }}
            style={{
              background: '#FFFFFF',
              border: '1px solid #EBEBEB',
              borderRadius: 6,
              width: '100%',
              fontSize: '0.8rem',
              fontWeight: 600,
              height: '2.3rem',
            }}
          />
        </div>
      </div>
      <div className="my-2" style={{ display: 'flex' }}>
        <div style={{ width: '25%' }}>Material*</div>
        <div className="mm-custom_select" style={{ width: '80%' }}>
          <Select
            styles={{
              control: (provided) => ({
                ...provided,
                width: '100%',
                padding: '0',
                fontSize: '0.8rem',
                border: '1px solid #EBEBEB',
                borderRadius: '6px',
                boxShadow: 'none',
              }),
              menuList: (provided) => ({
                ...provided,
                width: '100%',
                maxHeight: '230px',
                overflowY: 'auto',
                fontSize: '0.8rem',
                overflowX: 'hidden',
              }),
              valueContainer: (provided, state) => ({
                ...provided,
                width: '100%',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                justifyItems: 'center',
                alignItems: 'center',
                padding: '0rem 0px',
              }),
              indicatorsContainer: (provided, state) => ({
                ...provided,
                height: '3rem',
                fontSize: '0.8rem',
                size: '0.8rem',
              }),
              indicatorSeparator: (state) => ({
                display: 'none',
              }),
              option: (provided, state) => ({
                ...provided,
                padding: '0px 10px',
                margin: '0px',
              }),
            }}
            onChange={handleMaterialChange}
            value={data.materials?.map((value) => ({
              label: value,
              value: value,
            }))}
            isMulti
            name="materials"
            options={materialsData}
            className="basic-multi-select"
            classNamePrefix="select"
          />
        </div>
      </div>
      <div className="my-2" style={{ display: 'flex' }}>
        <div style={{ width: '25%' }}>Description</div>
        <div style={{ width: '80%' }}>
          <textarea
            rows="4"
            name="description"
            value={data.description}
            onChange={(e) => {
              onChange({ ...data, description: e?.target?.value });
            }}
            style={{
              background: '#FFFFFF',
              border: '1px solid #EBEBEB',
              borderRadius: 4,
              width: '100%',
              resize: 'none',
              outline: '0',
            }}
          />
        </div>
      </div>
    </div>
  );
};

export default PolygonForm;
