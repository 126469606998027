import {
  createContext,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { API, apiHeaders, ec2BaseUrl } from './apiHandler';
import {
  API_METHODS,
  ENDPOINTS,
  UserType,
} from '../../dustControl/utils/constants';
import { Spinner } from 'react-bootstrap';
import { setAccessToken } from './accessToken';
import { useNavigate } from 'react-router';
export const UserContext = createContext();

export const UserState = (props) => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [minIdleTime, setMinIdleTime] = useState(0);
  const [userInfo, setUserInfo] = useState({});
  const [departments, setDepartments] = useState([]);
  const [licenses, setLicenses] = useState([]);

  const navRef = useRef(navigate);
  useEffect(() => {
    navRef.current = navigate;
  }, [navigate]);

  const usersRestrictedDepartments = useMemo(() => {
    if (!userInfo.departments) return [];
    if (!departments) return [];
    if (userInfo.status === UserType.admin.value) {
      return departments.filter(({ is_restricted }) => is_restricted);
    }
    return departments.filter(
      ({ is_restricted, code }) =>
        is_restricted && userInfo.departments.includes(code),
    );
  }, [userInfo, departments]);

  const ApiHandler = useCallback(
    async ({
      endPoint,
      method,
      reqParam,
      blobType = false,
      signal,
      withCredentials,
    }) => {
      const acceptType = 'application/json';
      const contentType =
        reqParam instanceof FormData
          ? 'multipart/form-data'
          : 'application/json';
      let response = null;
      let error = null;
      return new Promise(async (resolve, reject) => {
        try {
          let content = {
            method: method.toUpperCase(),
            headers: apiHeaders(reqParam, contentType, acceptType),
          };

          if (
            method === 'post' ||
            method === 'delete' ||
            method === 'patch' ||
            method === 'put'
          ) {
            reqParam instanceof FormData
              ? console.log('no form data')
              : (reqParam = JSON.stringify(reqParam));
          }

          let url = endPoint;
          response = await API({
            url: ec2BaseUrl + url,
            data: reqParam,
            headers: content.headers,
            method: content.method,
            responseType: blobType ? 'arraybuffer' : 'json',
            signal,
            withCredentials: withCredentials ?? false,
          });

          if (response.headers['content-type'] === 'application/pdf') {
            response = {
              data: response.data,
              status: response?.status,
              statusText: response?.statusText,
            };
          } else {
            response = {
              data: response.data.data,
              status: response?.status,
              statusText: response?.statusText,
            };
          }
          resolve(response);
        } catch (e) {
          error = e.response;
          if (error) {
            response = {
              data: error?.data?.data,
              message: error?.data?.details ?? error?.data?.message,
              status: error?.status,
              statusText: error?.statusText,
            };
            // log out user when status code is 403.
            if (error.status === 403) {
              setAccessToken('');
              localStorage.setItem('accessToken', '');
              return reject(navRef.current('/'));
            } else if (error.status === 500)
              response.message = 'Something went wrong';
            return reject(response);
          }
        }
      });
    },
    [],
  );

  const getDepartmentsAndLicenses = async () => {
    let allDepartments = [];
    const [departmentResponse, licenceResponse] = await Promise.all([
      ApiHandler({
        method: API_METHODS.GET,
        endPoint: ENDPOINTS.getDepartments,
      }),
      ApiHandler({
        method: API_METHODS.GET,
        endPoint: ENDPOINTS.getLicenses,
      }),
    ]);
    if (departmentResponse.status === 200) {
      setDepartments(departmentResponse.data.departments);
      allDepartments = departmentResponse.data.departments;
    }
    setLicenses(licenceResponse.data.licenses);

    return {
      allDepartments: allDepartments,
      licenses: licenceResponse.data.licenses,
      departments: allDepartments,
    };
  };

  const setDataWhenLogging = useCallback(
    async (userInfo) => {
      const { allDepartments, licenses } = await getDepartmentsAndLicenses();
      const userData = { ...userInfo };
      if (userInfo.departments && userInfo.departments === 'superadmin') {
        userData.departments = allDepartments.map(({ code }) => code);
      } else {
        const unRestrictedDpts = allDepartments
          .filter(({ is_restricted }) => !is_restricted)
          .map(({ code }) => code);
        const userDepartment = userInfo.departments
          .split('-')
          .filter((dptnum) => dptnum.length > 0)
          .map(Number);
        userData.departments = [...userDepartment, ...unRestrictedDpts];
      }
      const departMentsDropDownData = {
        options: allDepartments?.map(({ name, code }) => ({
          name,
          value: code,
        })),
        defaultValues: allDepartments.map(({ code }) => code),
      };
      const licensesDropDownData = {
        options: licenses?.map(({ name, departmentCode }) => ({
          name,
          value: name,
          code: departmentCode,
        })), //value is name as we are getting name from backend
        defaultValues: licenses.map(({ name, departmentCode }) => name),
      };
      setUserInfo({
        ...userData,
        departMentsDropDownData,
        licensesDropDownData,
      });
    },
    [ApiHandler],
  );

  const fetchData = useCallback(async () => {
    try {
      const res = await ApiHandler({
        reqParam: undefined,
        method: API_METHODS.GET,
        endPoint: ENDPOINTS.getNewToken,
        withCredentials: true,
      });

      if (res.status === 200) {
        const userData = res.data;
        const [token, departments] = userData.accessToken?.split('||');
        userData.departments = departments;
        setAccessToken(token);
        localStorage.setItem('accessToken', token);
        localStorage.setItem('refreshToken', userData.refreshToken);

        await setDataWhenLogging(userData);
      }
    } catch (err) {
      setAccessToken('');
      localStorage.setItem('accessToken', '');
    }
  }, [ApiHandler, setDataWhenLogging]);

  if (loading)
    return (
      <div className="d-flex justify-content-center align-items-center h-100">
        <Spinner />
      </div>
    );

  return (
    <UserContext.Provider
      value={{
        userInfo,
        usersRestrictedDepartments,
        departments,
        licenses,
        setDataWhenLogging,
        ApiHandler,
        minIdleTime,
        setMinIdleTime,
        loading,
        setLoading,
        fetchData,
        getDepartmentsAndLicenses,
      }}
    >
      {props.children}
    </UserContext.Provider>
  );
};
