import { FC } from 'react';
import CustomButton from '../../dustControl/components/CustomButton';
import PopupModal from '../../scrapBins/components/popUp';

// styles
import styles from '../maintenance.module.css';

type DownLoadProps = {
  isOpen: boolean;
  handleOKClick: () => void;
  onClose: () => void;
};
const AttentionModal: FC<DownLoadProps> = (props) => {
  return (
    <PopupModal isOpen={props.isOpen} onClose={props.onClose}>
      <div className={styles.attention_modal_container}>
        <h6 className="text-danger fw-bold">Attention!</h6>
        <p className="fw-bold">
          Make sure the asset’s engine is OFF before updating the Engine Hours
        </p>

        <CustomButton
          id="downloadButton"
          style={{
            background: 'black',
            paddingLeft: '2rem',
            paddingRight: '2rem',
            width: 'fit-content',
            alignSelf: 'center',
          }}
          loading={false}
          title={'OK'}
          onClick={props.handleOKClick}
        />
      </div>
    </PopupModal>
  );
};

export default AttentionModal;
