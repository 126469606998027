import {
  BinDetailsItem,
  BinDetailsSection,
  DetailComponentType,
} from './types';
import { EllipsisDropdown } from '../Ellipsis';
import {
  BinWithPickup,
  DetailedBinStatus,
  TruckData,
  TruckStatusForList,
} from '../../utils/types';
import * as binDetailsHelper from '../../utils/helpers';
import CloseIcon from '@mui/icons-material/Close';
import {
  binStatusLabelBackgroundStyle,
  getRenderContentForBinDefaultDetails,
} from './helper';
import {
  detailedBinStatusLabels,
  getBinActionsByStatus,
  truckStatusColors,
} from '../../utils/constants';
import { EllipsisItem } from '../Ellipsis/types';
import { Button, Grid } from '@mui/material';
import { Fragment } from 'react';
import './styles.css';
import { AlertCircle } from '../../assets/images/alert_circle';
import { AlertPink } from '../../assets/images/alertPink';

/*
  The below function renders the header for the details component.
  Eg: the Bin name
*/
export const renderHeader = (
  detailComponentType: DetailComponentType,
  detailData: BinWithPickup | TruckData,
): JSX.Element => {
  let name = detailData.name;
  if (detailComponentType === 'Bin') {
    // detailData is a bin
    name = 'Bin ' + detailData.name; // Assuming BinWithPickup has a name property
  }

  return (
    <div className="details-header-container">
      <h5 className="details-header-text">{name} - Details</h5>
    </div>
  );
};

export const renderTruckDetails = (truckData: any): JSX.Element => {
  const truckDetails = [
    { label: 'Truck ID', value: truckData.id || 'Not Available' },
    { label: 'Status', value: truckData.status || 'Unknown' },
    {
      label: 'Last Location',
      value: truckData.lastLocation || 'Not Available',
    },
  ];

  return (
    <Grid item xs={12} marginTop={2} className="truck-details-section">
      <Grid container direction="row" className="truck-details-header">
        <Grid item xs={12}>
          <h4>Truck Details</h4>
        </Grid>
      </Grid>
      <Grid container direction="row" spacing={2}>
        {truckDetails.map((detail) => (
          <Grid item xs={4} key={detail.label}>
            <div className="truck-detail-item">
              <span className="truck-detail-label">{detail.label}: </span>
              <span className="truck-detail-value">{detail.value}</span>
            </div>
          </Grid>
        ))}
      </Grid>
    </Grid>
  );
};

/*
  This function renders the ellipse section and the close button which is used to 
  control the the details section 
*/
export const renderOptionsForEllipsisDropdown = (
  setShowDetails: (showDetailState: boolean) => void,
  setActionModal: React.Dispatch<React.SetStateAction<JSX.Element>>,
  binWithPickupData?: BinWithPickup,
): JSX.Element => {
  const ellipseItems: EllipsisItem[] = [];

  if (binWithPickupData) {
    const binDetailedStatus: DetailedBinStatus =
      binDetailsHelper.getDetailedBinStatus(binWithPickupData);
    const isMissingBin: boolean =
      binDetailsHelper.isBinMissing(binWithPickupData);

    /*
    This fills the allipse dropdown options based on the bin status
   */
    getBinActionsByStatus(binDetailedStatus, isMissingBin).forEach(
      (binAction) => {
        ellipseItems.push({
          label: binAction.action,
          onClick: () => {
            let showComponent: boolean = true;
            const onClose = (): unknown => {
              /*
             This is used to close the modal
            */
              setActionModal(<></>);
              return { isOpen: showComponent };
            };
            setActionModal(
              binAction.component(binWithPickupData, showComponent, onClose),
            );
          },
        });
      },
    );
  }
  return (
    <div className="details-container-ellipses">
      <EllipsisDropdown items={ellipseItems} />
      <button className="frameless-close-button">
        <CloseIcon
          onClick={() => {
            setShowDetails(false);
          }}
        />
      </button>
    </div>
  );
};

/*
  The details section first shows the current status of the bin.
  The below function shows the status of the bin
  eg: Back at source, Bin missing etc
*/
export const renderBinStatusLabelForBinDetails = (
  binWithPickupData: BinWithPickup,
  hideTime?: boolean,
  history?: boolean,
): JSX.Element => {
  const binDetailedStatus: DetailedBinStatus =
    binDetailsHelper.getDetailedBinStatus(binWithPickupData);
  let binStatusLabel: string =
    binDetailsHelper.getBinDetailedStatusLabel(binDetailedStatus);
  if (
    binStatusLabel === detailedBinStatusLabels['pick-up-requested'] &&
    !hideTime
  ) {
    binStatusLabel += ` - ${binDetailsHelper.getDurationAgo(
      binWithPickupData.pickupRequest?.time,
    )}`;
  }

  const binStatusDisplayColour: string =
    binDetailsHelper.getBinColorByDetailedStatus(binDetailedStatus);

  const jobCompleteIncompleteStatus: DetailedBinStatus =
    binDetailsHelper.getBinJobStatusCompleteIncomplete(binWithPickupData);

  const jobCompleteIncompleteLabel: string =
    binDetailsHelper.getBinDetailedStatusLabel(jobCompleteIncompleteStatus);

  const jobStatusDisplayColour: string =
    binDetailsHelper.getBinColorByDetailedStatus(jobCompleteIncompleteStatus);

  return (
    <div style={{ display: 'flex', gap: '10px' }}>
      {jobCompleteIncompleteStatus !== DetailedBinStatus.STATUS_UNAVAILABLE && (
        <div style={binStatusLabelBackgroundStyle(jobStatusDisplayColour)}>
          {/* This renders only the job complete and incomplete status */}
          <b>{jobCompleteIncompleteLabel}</b>
        </div>
      )}
      {history ? (
        <></>
      ) : binDetailedStatus !== jobCompleteIncompleteStatus ? (
        <div style={binStatusLabelBackgroundStyle(binStatusDisplayColour)}>
          {/* Here it checks if status is equal to complete or incomplete then it skips to prevent showing same status twice */}
          {binDetailedStatus === DetailedBinStatus.PICK_UP_REQUESTED ? (
            <AlertCircle
              style={{ marginRight: 7, marginBottom: '5px' }}
              height={15}
              width={16}
            />
          ) : binDetailedStatus === DetailedBinStatus.BIN_MISSING ? (
            <AlertPink
              style={{ marginRight: 7, marginBottom: '5px' }}
              height={15}
              width={16}
            />
          ) : null}

          <b>
            {binStatusLabel}{' '}
            {binDetailedStatus === DetailedBinStatus.PICK_UP_REQUESTED &&
            binWithPickupData.pickupRequest?.dropoffOnly
              ? '(Dump not required)'
              : ''}
          </b>
        </div>
      ) : (
        <></>
      )}
    </div>
  );
};

export const renderTruckStatusLabel = (
  truckAvailability: TruckStatusForList,
): JSX.Element => {
  const statusColour: string = truckStatusColors[truckAvailability];

  return (
    <div style={binStatusLabelBackgroundStyle(statusColour)}>
      <b className="text-white">{truckAvailability}</b>
    </div>
  );
};

/*
 The below function displays the details of the bin like scrap type, last seen location etc
*/
export const renderBinDetailsItems = (
  binDetailItems: BinDetailsItem[],
): JSX.Element[] => {
  return binDetailItems.map((binDetailItem) => (
    <Fragment key={binDetailItem.data.label}>
      <Grid item xs={1.5} className="bin-details-icon">
        {binDetailItem.icon}
      </Grid>
      <Grid item xs={10.5}>
        <Grid container direction="column">
          <Grid item xs={12} className="bin-details-item-label">
            {binDetailItem.data.label}
          </Grid>
          <Grid item xs={12} className="bin-details-item-value">
            {binDetailItem.data.value}
          </Grid>
        </Grid>
      </Grid>
    </Fragment>
  ));
};

/*
 This function displays the bottom section (default values) of the details component. 
 Eg: After the above functions which display the bin details like the last updated time, 
 scrap type etc, the bottom section before the action buttons is showing the pickup point, 
 dump point and drop off point of a bin which are constants for the bin.
*/
export const renderBinDefaultDetailsItems = (
  binWithPickupData: BinWithPickup,
): JSX.Element => {
  const defaultBinDetails: BinDetailsItem[] =
    getRenderContentForBinDefaultDetails(binWithPickupData);

  return (
    <Grid item xs={12} marginTop={2}>
      {/* We first display the heading for the default details sub-section */}
      <Grid container direction="row">
        <Grid
          item
          xs={4}
          className="bin-default-details-section-heading"
          style={{ textWrap: 'nowrap' }}
        >
          {BinDetailsSection.OTHER_DETAILS}
        </Grid>
        <Grid item xs={7.5} className="horizontal-separation">
          <hr />
        </Grid>
      </Grid>
      {/* After the heading for the default subsection, we then show the default values of the bin */}
      <Grid container direction="row">
        {defaultBinDetails.map((binDefaultDetailsItem: BinDetailsItem) => (
          <Grid item xs={4}>
            {generateRenderForBinDefaultDetailsItem(binDefaultDetailsItem)}
          </Grid>
        ))}
      </Grid>
    </Grid>
  );
};

/* The below function is used to generate the detail items in the detail component for the default data of a bin
   like drop off point, dump point, and pickup point */
const generateRenderForBinDefaultDetailsItem = (
  binDefaultDetailItem: BinDetailsItem,
): JSX.Element => {
  return (
    <Grid container direction="row" columnSpacing={2}>
      <div
        className="bin-details-icon"
        style={{ alignItems: 'flex-start', width: '18px' }}
      >
        {binDefaultDetailItem.icon}
      </div>
      <Grid item xs={10.5}>
        <Grid container direction="column">
          <Grid item xs={12} className="bin-details-item-label">
            {binDefaultDetailItem.data.label}
          </Grid>
          <Grid item xs={12} className="bin-details-item-value">
            {binDefaultDetailItem.data.value}
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

/*
  The below function displays the action buttons for the bins
  The action buttons may differ based on their statuses
*/
export const renderDetailActionButtons = (
  detailComponentType: DetailComponentType,
  binWithPickupData: BinWithPickup,
  setActionModal: React.Dispatch<React.SetStateAction<JSX.Element>>,
): JSX.Element => {
  const isMissingBin: boolean =
    binDetailsHelper.isBinMissing(binWithPickupData);
  const binDetailedStatus: DetailedBinStatus =
    binDetailsHelper.getDetailedBinStatus(binWithPickupData);

  if (binDetailedStatus === DetailedBinStatus.STATUS_UNAVAILABLE) {
    return <></>;
  }

  const binStatusActionsByBinStatus = getBinActionsByStatus(
    binDetailedStatus,
    isMissingBin,
  );

  const availableActionsForButtons =
    detailComponentType === 'Bin'
      ? [binStatusActionsByBinStatus[0], binStatusActionsByBinStatus[1]]
      : [];
  const actionButtons: JSX.Element[] = availableActionsForButtons.map(
    (availableAction) => (
      <Button
        variant="contained"
        className="details-action-button"
        onClick={() => {
          let showComponent: boolean = true;
          const onClose = (): unknown => {
            setActionModal(<></>);
            return { isOpen: showComponent };
          };
          setActionModal(
            availableAction.component(
              binWithPickupData,
              showComponent,
              onClose,
            ),
          );
        }}
      >
        {availableAction.action}
      </Button>
    ),
  );
  return (
    <Grid
      container
      direction="row"
      className="details-action-button-container"
      columnSpacing={1}
    >
      {actionButtons.map((actionButton: JSX.Element) => (
        <Grid item xs={5}>
          {actionButton}
        </Grid>
      ))}
    </Grid>
  );
};
