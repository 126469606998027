export const DetailRow = ({ label, value, underlined }) => (
    <div className="row w-100 p-0 m-0 pt-1 ">
        <div className="col-5 p-0 m-0">
            <span style={{ color: '#707070' }}>
                <div className="d-flex justify-content-between">
                    <div>{label}</div>
                    <div className="me-2">:</div>
                </div>
            </span>
        </div>
        <div className="col-7 p-0 m-0">
            {underlined ?
                <strong style={{ textDecoration: "underline" }}>{value}</strong>
                :
                <strong>{value}</strong>
            }

        </div>
    </div>
)