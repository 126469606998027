import { FC, useContext, useState } from 'react';

import PopupModal from '../../scrapBins/components/popUp';
import CustomButton from '../../dustControl/components/CustomButton';
import { SelectedTruck } from '../utils/types';
import FloatingInput from '../../dustControl/components/FloatingInput';
import * as toast from '../../dustControl/components/toast';

// styles
import styles from '../maintenance.module.css';
import { UserContext } from '../../pages/service/userContext';
import { API_METHODS, ENDPOINTS } from '../../dustControl/utils/constants';

const UPDATED_ENGINE_HOUR_FIELD_ID = 1;

type EditEngineHourModalProps = {
  isOpen: boolean;
  onClose: (updatedDate?: Required<SelectedTruck>) => void;
  selectedTruck: SelectedTruck;
};

const EditEngineHourModal: FC<EditEngineHourModalProps> = (props) => {
  const { ApiHandler } = useContext(UserContext);

  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [apiErrorMsg, setApiErrorMsg] = useState<string>('');
  const [forms, setForms] = useState([
    {
      errorMsg: '',
      label: 'Current Engine hours',
      disabled: true,
      id: 0,
      value: props.selectedTruck.engineHour ?? 0,
    },
    {
      errorMsg: '',
      label: 'New Engine hours',
      disabled: false,
      id: UPDATED_ENGINE_HOUR_FIELD_ID,
      value: '',
    },
  ]);

  const editEngineHour = async (): Promise<void> => {
    const newEngineHourField = forms.find(
      ({ id }) => id === UPDATED_ENGINE_HOUR_FIELD_ID,
    );
    let newEngineHourNumber: number = +newEngineHourField?.value!;

    setApiErrorMsg('');

    // Check newEngineHourValue exists
    if (!newEngineHourField?.value) {
      setForms((previousForm) =>
        previousForm.map((fieldData) => {
          if (fieldData.id === UPDATED_ENGINE_HOUR_FIELD_ID)
            return {
              ...fieldData,
              errorMsg: 'New Engine hours cannot be empty ',
            };
          else return fieldData;
        }),
      );
      return;
    }

    // Check newEngineHourValue is a number
    if (isNaN(newEngineHourNumber)) {
      setForms((previousForm) =>
        previousForm.map((fieldData) => {
          if (fieldData.id === UPDATED_ENGINE_HOUR_FIELD_ID)
            return {
              ...fieldData,
              errorMsg: 'New Engine hours must be a valid number',
            };
          else return fieldData;
        }),
      );
      return;
    }

    newEngineHourNumber = +newEngineHourNumber.toFixed(1);
    setIsLoading(true);

    // Clear any old errors
    setForms((previousForm) =>
      previousForm.map((fieldData) => {
        if (fieldData.id === UPDATED_ENGINE_HOUR_FIELD_ID)
          return {
            ...fieldData,
            errorMsg: '',
          };
        else return fieldData;
      }),
    );

    try {
      await ApiHandler({
        method: API_METHODS.PUT,
        endPoint: ENDPOINTS.updateEngineHours,
        reqParam: {
          truckName: props.selectedTruck.name,
          engineHours: newEngineHourNumber,
        },
      });
      toast.success('Successfully updated engine hours');
      props.onClose({
        name: props.selectedTruck.name!,
        engineHour: newEngineHourNumber,
      });
    } catch (error: any) {
      setApiErrorMsg(error?.message);
    }
    setIsLoading(false);
  };

  const handleInputChange = (value: string, id: number) => {
    // Cannot convert value to a number here because user may want to type in floating point numbers
    setForms((prevForm) =>
      prevForm.map((form) => (id === form.id ? { ...form, value } : form)),
    );
  };

  return (
    <PopupModal
      width="26rem"
      isOpen={props.isOpen}
      onClose={props.onClose}
      header={`${props.selectedTruck.name} - Edit Engine hours`}
    >
      <div className={styles.edit_engine_hour_container}>
        <p className={styles.text_left}>
          Please enter this asset engine hours and hit Save
        </p>

        <div className={styles.flex_column}>
          {forms.map(({ disabled, errorMsg, label, value, id }) => (
            <div key={id}>
              <p className={styles.text_left}>{label}</p>
              <div className="position-relative">
                <FloatingInput
                  disabled={disabled}
                  style={{ width: '100%', marginLeft: '-2px' }}
                  value={value}
                  setValue={(value: string) => handleInputChange(value, id)}
                  isError={errorMsg ? true : false}
                />
                <span className={styles.hrs_container}>hrs</span>
              </div>
              <p className="error-msg-2 text-start">{errorMsg}</p>
            </div>
          ))}
          <p className="error-msg-2 text-start">{apiErrorMsg}</p>
        </div>

        <div className={styles.save_button_container}>
          <CustomButton
            disabled={isLoading}
            style={{
              background: 'black',
              paddingLeft: '2rem',
              paddingRight: '2rem',
              width: 'fit-content',
              alignSelf: 'end',
            }}
            loading={isLoading}
            id="editEngineHour"
            onClick={editEngineHour}
            title={`Save`}
          />
        </div>
      </div>
    </PopupModal>
  );
};

export default EditEngineHourModal;
