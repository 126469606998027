const STAGING_URL = 'https://api.optitrac.com/steelserv-staging';
const PRODUCTION_URL = 'https://api.optitrac.com/steelserv-production';

export enum Endpoint {
  GetLoads = '/shift-loader/loads',
  getTrafficLightStatus = '/shift-loader/traffic-light',
  getTrafficLightTimeline = '/shift-loader/traffic-light/timeline',
  getTimelineLoads = '/shift-loader/timeline',
}

export const getUrl = (endpoint?: Endpoint) => {
  let baseUrl = '';
  if (process.env.REACT_APP_ENV === 'staging') baseUrl = STAGING_URL;
  else baseUrl = PRODUCTION_URL;
  if (endpoint) baseUrl += endpoint;
  else baseUrl += '/';
  return baseUrl;
};
