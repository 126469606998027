import { API } from './apiHandler';

export function CallWithOutAuth(method, url, body) {
  return new Promise((resolve, reject) => {
    try {
      if (method === 'POST') {
        API.post(url, body)
          .then((response) => {
            if (response.status === 200 || response.status === 201) {
              resolve({ status: true, res: response });
            } else {
              resolve({ status: false, res: response });
            }
          })
          .catch((err) => {
            resolve({ status: false, res: err.response });
          });
      }
      if (method === 'PUT') {
        API.put(url, body)
          .then((response) => {
            if (response.status === 200 || response.status === 201) {
              resolve({ status: true, res: response });
            } else {
              resolve({ status: false, res: response });
            }
          })
          .catch((err) => {
            resolve({ status: false, res: err.response });
          });
      }
      if (method === 'PATCH') {
        API.patch(url, body)
          .then((response) => {
            if (response.status === 200 || response.status === 201) {
              resolve({ status: true, res: response });
            } else {
              resolve({ status: false, res: response });
            }
          })
          .catch((err) => {
            resolve({ status: false, res: err.response });
          });
      }
      if (method === 'GET') {
        API.get(url, body)
          .then((response) => {
            if (response.status === 200 || response.status === 201) {
              resolve({ status: true, res: response });
            } else {
              resolve({ status: false, res: response });
            }
          })
          .catch((err) => {
            resolve({ status: false, res: err.response });
          });
      }
    } catch (err) {
      console.log('Catch block error in CallWithOutAuth method');
      console.log(err.response);
      reject(err.response);
    }
  });
}
export function CallWithAuth(method, url, body = {}, cancelToken) {
  const axiosRequest = {
    POST: API.post,
    PUT: API.put,
    PATCH: API.patch,
    GET: API.get,
    DELETE: API.delete,
  };

  const axiosMethod = axiosRequest[method];

  if (!axiosMethod) {
    return Promise.reject(new Error(`Unsupported method: ${method}`));
  }

  const headercustom = {
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${localStorage.getItem('accessToken')}`,
      ...(cancelToken ? { cancelToken } : {}),
    },
  };

  return new Promise(async (resolve, reject) => {
    try {
      if (method === 'POST') {
        const response = await API.post(url, body, headercustom);
        if (response.status === 200 || response.status === 201) {
          resolve({ status: true, res: response });
        }
      }
      if (method === 'PUT') {
        const response = await API.put(url, body, headercustom);
        if (response.status === 200 || response.status === 201) {
          resolve({ status: true, res: response });
        }
      }
      if (method === 'PATCH') {
        const response = await API.patch(url, body, headercustom);
        if (response.status === 200 || response.status === 201) {
          resolve({ status: true, res: response });
        }
      }
      if (method === 'GET') {
        const response = await API.get(url, headercustom);
        if (response.status === 200 || response.status === 201) {
          resolve({ status: true, res: response });
        }
      }
    } catch (err) {
      if (err.response?.status === 403) {
        localStorage.clear();
        window.location.href = `/`;
      } else {
        resolve({ status: false, res: err.response });
      }
    }
  });
}

export function CallWithAuthFormData(method, url, body = {}) {
  const headercustom = {
    headers: {
      Authorization: `Bearer ${localStorage.getItem('accessToken')}`,
      'Content-Type': 'multipart/form-data',
    },
  };

  // Print fromData
  // for (let pair of body.entries()) {
  //   console.log(pair[0]+ ', ' + pair[1]);
  // }

  return new Promise((resolve, reject) => {
    try {
      if (method === 'POST') {
        API.post(url, body, headercustom)
          .then((response) => {
            if (response.status === 200 || response.status === 201) {
              resolve({ status: true, res: response });
            } else {
              resolve({ status: false, res: response });
            }
          })
          .catch((err) => {
            resolve({ status: false, res: err.response });
          });
      }
      if (method === 'PUT') {
        API.put(url, body, headercustom)
          .then((response) => {
            // console.log(response);
            if (response.status === 200 || response.status === 201) {
              resolve({ status: true, res: response });
            } else {
              resolve({ status: false, res: response });
            }
          })
          .catch((err) => {
            resolve({ status: false, res: err.response });
          });
      }
    } catch (err) {
      console.log('Catch block error in CallWithAuthFormData method');
      console.log(err);
      reject(err.response);
    }
  });
}
