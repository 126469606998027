import { DetailRow } from "./DetailRow"

export const UniqueAssetRow = ({ label, value, }) => {
    return <>
        {/* <DetailRow label={label} value={value?.length} /> */}
        {value?.length ?
            <div >
                <ul className='pe-2 ps-0 py-0 m-0'>
                    {value?.map((e, i) =>
                        <li className='d-flex justify-content-start align-items-center' style={{ fontSize: "0.8rem" }} key={i}>
                            {e.name} &nbsp; <span style={{ color: '#707070' }}>({e.loads} loads)</span>
                        </li>)}
                </ul>
            </div> : ""}
    </>
}