import axios, { AxiosError } from 'axios';
import { getAccessToken, setAccessToken } from './accessToken';
import { ENDPOINTS } from '../../dustControl/utils/constants';

export const ec2BaseUrl = '/dust-control/api/';
export const REACT_APP_AES_KEY = 'yhvSXFw7C6X0qLIKhpdLkxDiqLyn7Z';

export const API = axios.create();

let refreshingFunc = undefined;

// Add a response interceptor
API.interceptors.response.use(
  (response) => {
    // Any status code that lie within the range of 2xx cause this function to trigger
    // Do something with response data
    return response;
  },
  async (error) => {
    // Any status codes that fall outside the range of 2xx cause this function to trigger
    const { config: originalRequest, response } = error;
    let url = ec2BaseUrl + ENDPOINTS.getNewToken;
    if (response?.status === 406 && !originalRequest._retry) {
      originalRequest._retry = true; // Add the retry flag to the originalRequest object
      try {
        delete originalRequest.headers.Authorization;
        if (!refreshingFunc) {
          const refreshPromise = axios.get(url, {
            withCredentials: true,
          });
          refreshingFunc = refreshPromise;
        }
        const refreshRes = await refreshingFunc;
        const userData = refreshRes?.data?.data;
        const [token, _] = userData?.accessToken?.split('||');
        originalRequest.headers.Authorization = `Bearer ${token}`;
        setAccessToken(token);
        localStorage.setItem('accessToken', token);
        localStorage.setItem('refreshToken', userData.refreshToken);
        try {
          return await API(originalRequest);
        } catch (innerError) {
          throw innerError;
        }
      } catch (err) {
        // Only clear access token if error 403, meaning Invalid Refresh Token
        if (err instanceof AxiosError && err.response?.status === 403) {
          setAccessToken('');
          localStorage.setItem('accessToken', '');
        }
        return Promise.reject(err);
      } finally {
        refreshingFunc = undefined;
      }
    } else {
      originalRequest._retry = false;
      return Promise.reject(error);
    }
  },
);

export function apiHeaders(reqParam, contentType, acceptType) {
  const requestHeader =
    reqParam instanceof FormData
      ? {
          responseType: 'blob',
          'x-api-key': 'web',
          'Content-Type': contentType,
          Authorization: getAccessToken() ? 'Bearer ' + getAccessToken() : '',
        }
      : {
          Accept: acceptType,
          'Content-Type': contentType,
          Authorization: getAccessToken() ? 'Bearer ' + getAccessToken() : '',
        };
  const headers = requestHeader;

  return headers;
}
