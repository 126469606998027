import React from 'react';
import './styles.scss';
import { KeyValueLabelProps } from './types';

const KeyValueLabels: React.FC<KeyValueLabelProps> = ({
  title,
  value,
  subValue,
}) => {
  return (
    <div className="flex-row d-flex w-100">
      <div className="bin_key_value-title">{title}</div>

      <div className="bin_key_value-answer d-flex flex-column align-items-start ">
        <div className="d-flex"> {value}</div>
        <div> {subValue}</div>
      </div>
    </div>
  );
};

export default KeyValueLabels;
