import React, { FunctionComponent } from 'react';

type AlertProps = {
  height: number;
  width: number;
};

export const DefaultGrayAlert: FunctionComponent<AlertProps> = (
  props: AlertProps,
) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="34.096"
    height="30.294"
    viewBox="0 0 34.096 30.294"
  >
    <g
      id="Group_46131"
      data-name="Group 46131"
      transform="translate(7362.356 14273.694)"
    >
      <g
        id="Polygon_10"
        data-name="Polygon 10"
        transform="translate(-7363 -14275)"
        fill="rgb(167, 167, 167)"
      >
        <path
          d="M 30.13224029541016 30.79974746704102 L 5.252069473266602 30.79974746704102 C 3.870959758758545 30.79974746704102 2.637449741363525 30.08376693725586 1.95242965221405 28.88450622558594 C 1.267409682273865 27.68524742126465 1.277289628982544 26.25903701782227 1.978879690170288 25.06939697265625 L 14.41897010803223 3.975337028503418 C 15.10938930511475 2.804626941680908 16.33300971984863 2.105686902999878 17.69215965270996 2.105686902999878 C 19.05130004882812 2.105686902999878 20.2749195098877 2.804626941680908 20.9653491973877 3.975337028503418 L 33.40542984008789 25.06939697265625 C 34.10702133178711 26.25903701782227 34.11690902709961 27.68524742126465 33.43188858032227 28.88450622558594 C 32.74686813354492 30.08376693725586 31.51335906982422 30.79974746704102 30.13224029541016 30.79974746704102 Z"
          stroke="none"
        />
        <path
          d="M 17.69215393066406 2.905681610107422 C 16.69026947021484 2.905681610107422 15.68838500976562 3.397697448730469 15.10805892944336 4.38172721862793 L 2.66796875 25.47578811645508 C 1.488559722900391 27.47565650939941 2.930320739746094 29.99974632263184 5.252069473266602 29.99974632263184 L 30.13224029541016 29.99974632263184 C 32.4539909362793 29.99973678588867 33.89575958251953 27.47565650939941 32.71633911132812 25.47578811645508 L 20.27624893188477 4.38172721862793 C 19.69592475891113 3.397697448730469 18.69404029846191 2.905681610107422 17.69215393066406 2.905681610107422 M 17.6921501159668 1.30567741394043 C 19.3374195098877 1.30567741394043 20.81864929199219 2.151756286621094 21.6544303894043 3.568946838378906 L 34.09452819824219 24.66300582885742 C 34.94380950927734 26.10309600830078 34.95579147338867 27.82954788208008 34.12656021118164 29.28128623962402 C 33.29732894897461 30.7330265045166 31.80413055419922 31.5997371673584 30.13224983215332 31.59974670410156 L 5.252069473266602 31.59974670410156 C 3.5802001953125 31.59974670410156 2.086997985839844 30.73303604125977 1.257759094238281 29.28130722045898 C 0.42852783203125 27.82957649230957 0.4404983520507812 26.10310745239258 1.289779663085938 24.66300582885742 L 13.7298698425293 3.568946838378906 C 14.56563949584961 2.151767730712891 16.0468692779541 1.30567741394043 17.6921501159668 1.30567741394043 Z"
          stroke="none"
          fill="#2b2d2f"
        />
      </g>
      <path
        id="Path_15008"
        data-name="Path 15008"
        d="M5.733-26.295H3.386l-.491-9.4H6.223ZM2.856-23a1.613,1.613,0,0,1,.433-1.222,1.756,1.756,0,0,1,1.26-.414,1.706,1.706,0,0,1,1.236.423A1.609,1.609,0,0,1,6.223-23a1.608,1.608,0,0,1-.443,1.2,1.675,1.675,0,0,1-1.231.438A1.725,1.725,0,0,1,3.3-21.8,1.6,1.6,0,0,1,2.856-23Z"
        transform="translate(-7350.005 -14227.759)"
        fill="#2b2d2f"
      />
    </g>
  </svg>
);
