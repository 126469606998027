import { useState, FunctionComponent } from 'react';
import { DetailsProps } from './types';
import { BinWithPickup, PickupStatus, TruckData } from '../../utils/types';
import {
  renderHeader,
  renderOptionsForEllipsisDropdown,
  renderBinStatusLabelForBinDetails,
  renderDetailActionButtons,
} from './renders';
import './styles.css';
import { BinDetails } from './binDetails';
import TruckDetails from './truckDetails'; // Assuming you have a similar component for trucks
import CloseIcon from '@mui/icons-material/Close';
import {
  getBinDetailedStatusLabel,
  getDetailedBinStatus,
} from '../../utils/helpers';

export const Details: FunctionComponent<DetailsProps> = ({
  trucks,
  bins,
  detailComponentType,
  detailData,
  toggleShowDetailsVisibility,
}) => {
  const [actionModal, setActionModal] = useState<JSX.Element>(<></>);

  const getTruckStatusDetails = () => {
    let details = { statusLabel: '', backgroundColor: '' };

    if (detailComponentType === 'Truck' && detailData) {
      const truckData = detailData;

      const associatedBin = bins.find((bin) => bin.name === truckData.bin);

      if (associatedBin) {
        const binStatus = getDetailedBinStatus(associatedBin);
        const binStatusString = getBinDetailedStatusLabel(binStatus);

        if (
          trucks[PickupStatus.IN_PROGRESS]?.some(
            (truck) => truck.name === truckData.name,
          )
        ) {
          details = {
            statusLabel: `Job In Progress - ${binStatusString}`,
            backgroundColor: '#3D63C9',
          };
        } else if (
          trucks.standby?.some((truck) => truck.name === truckData.name)
        ) {
          details = {
            statusLabel: 'Standby',
            backgroundColor: '#C6770D',
          };
        } else if (
          trucks.Workshop?.some((truck) => truck.name === truckData.name)
        ) {
          details = {
            statusLabel: 'Workshop',
            backgroundColor: '#BF3636',
          };
        }
      }
    }

    return details;
  };

  const { statusLabel, backgroundColor } = getTruckStatusDetails();

  if (!detailData) return null;
  return (
    <>
      <div className="details-grid-container">
        {renderHeader(detailComponentType, detailData)}
        {detailComponentType !== 'Bin' && (
          <div className="details-container-operation-truck">
            <button className="frameless-close-button">
              <CloseIcon
                onClick={() => {
                  toggleShowDetailsVisibility(false);
                }}
              />
            </button>
          </div>
        )}

        {detailComponentType === 'Bin' &&
          renderOptionsForEllipsisDropdown(
            toggleShowDetailsVisibility,
            setActionModal,
            detailData,
          )}

        {detailComponentType === 'Bin'
          ? renderBinStatusLabelForBinDetails(detailData as BinWithPickup)
          : statusLabel && (
              <div
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  gap: '10px',
                }}
              >
                <span
                  style={{
                    backgroundColor,
                    color: 'white',
                    padding: '8px 16px', // Padding around the text
                    borderRadius: '20px', // Rounded corners
                  }}
                >
                  <b>{statusLabel}</b>
                </span>
              </div>
            )}

        {actionModal}
      </div>

      {detailComponentType === 'Bin' ? (
        <BinDetails binWithPickupData={detailData as BinWithPickup} />
      ) : (
        <TruckDetails
          trucks={trucks}
          setActionModal={setActionModal}
          truckData={detailData as TruckData}
        />
      )}

      {/* render two buttons that will be commonly used for a bin based on its status*/}
      {detailComponentType === 'Bin' ? (
        detailData &&
        renderDetailActionButtons('Bin', detailData, setActionModal)
      ) : (
        <></>
      )}
    </>
  );
};
