import { FunctionComponent } from 'react';
import { BinIconProps } from './types';

const BinWhite: FunctionComponent<BinIconProps> = (props: BinIconProps) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 54.523 52.998"
    {...props}
  >
    <g
      stroke="#6e6e6e"
      strokeLinecap="round"
      strokeWidth={2.5}
      data-name="Group 57875"
      transform="translate(1823.332 9554.467)"
    >
      <path
        fill="none"
        d="M-1812.08-9510.126a5.186 5.186 0 0 1-5.016-3.873l-4.907-18.743a2.045 2.045 0 0 1 1.974-2.561h47.913a2.044 2.044 0 0 1 1.978 2.561l-4.906 18.744a5.187 5.187 0 0 1-5.016 3.873h-.014"
        data-name="Path 34729"
      />
      <circle
        cx={5.068}
        cy={5.068}
        r={5.068}
        fill="#fff"
        data-name="Ellipse 3347"
        transform="translate(-1812.64 -9512.855)"
      />
      <path fill="none" d="M-1789.066-9510.127h-14.011" data-name="Line 2164" />
      <circle
        cx={5.068}
        cy={5.068}
        r={5.068}
        fill="#fff"
        data-name="Ellipse 3348"
        transform="translate(-1789.638 -9512.855)"
      />
      <path
        fill="none"
        d="m-1802.162-9517.824-.877-11.692"
        data-name="Line 2165"
      />
      <path
        fill="none"
        d="m-1808.217-9517.824-1.754-11.692"
        data-name="Line 2166"
      />
      <path fill="none" d="M-1796.034-9517.824v-11.692" data-name="Line 2167" />
      <path
        fill="none"
        d="m-1789.979-9517.824.877-11.692"
        data-name="Line 2168"
      />
      <path
        fill="none"
        d="m-1783.214-9522.559 1.043-6.957"
        data-name="Line 2169"
      />
      <path
        fill="none"
        d="m-1818.797-9535.305 11.081-11.081a4.921 4.921 0 0 1 6.959 0l3.088 3.089"
        data-name="Path 34731"
      />
      <path
        fill="none"
        d="m-1801.46-9547.089 4.687-4.687a4.921 4.921 0 0 1 6.958 0l16.466 16.471"
        data-name="Path 34732"
      />
    </g>
  </svg>
);
export default BinWhite;
