//@ts-nocheck
import { LinearScale, ArcElement, Chart as ChartJS } from 'chart.js';
import { FunctionComponent } from 'react';
import { Doughnut } from 'react-chartjs-2';
import './styles.scss';
import { generateDistinctColours } from './helper';
import { PieChartLegendData, PieChartProps } from './types';
import { sum } from 'lodash';
import { renderLegendDataForPieChart } from './render';

const shadowPlugin = {
  id: 'shadow',
  afterDatasetsDraw: (chart, easing) => {
    const ctx = chart.ctx;
    chart.data.datasets.forEach((dataset, i) => {
      const meta = chart.getDatasetMeta(i);
      if (!meta.hidden) {
        meta.data.forEach((element, index) => {
          // Draw the shadow here
          ctx.fillStyle = 'rgba(0, 0, 0, 0.15)'; // This sets the shadow color
          const { startAngle, endAngle, innerRadius, outerRadius, x, y } =
            element;
          let shadowOffsetX = 1;
          let shadowOffsetY = 0;
          let shadowBlur = 0;

          // Save the context state
          ctx.save();
          // Apply the shadow
          ctx.shadowOffsetX = shadowOffsetX;
          ctx.shadowOffsetY = shadowOffsetY;
          ctx.shadowBlur = shadowBlur;
          ctx.shadowColor = 'rgba(0,0,0,0.25)';

          // Create the arc with the shadow
          ctx.beginPath();
          ctx.arc(x, y, outerRadius - 13, startAngle, endAngle);
          ctx.arc(x, y, innerRadius, endAngle, startAngle, true);
          ctx.closePath();
          ctx.fill();

          // Restore the context state
          ctx.restore();
        });
      }
    });
  },
};

ChartJS.register(ArcElement, LinearScale, shadowPlugin);

const PieChart: FunctionComponent<PieChartProps> = (props: PieChartProps) => {
  const plugins = [
    {
      id: 'textInsideDoughnut',
      beforeDraw: (chart) => {
        let width = chart.width,
          height = chart.height,
          ctx = chart.ctx;
        ctx.restore();
        let fontSize = 12;
        ctx.font = `bold ${fontSize}px sans-serif`;
        ctx.textBaseline = 'middle';

        const textX = props.legend.header
          ? Math.round(
              (width - ctx.measureText(props.legend.header.headerValue).width) /
                2,
            )
          : Math.round((width - ctx.measureText(sum(props.data)).width) / 2);
        const textY = height / 2;
        if (props.legend.header) {
          ctx.fillText(props.legend.header.headerValue, textX, textY);
        } else {
          ctx.fillText(sum(props.data), textX, textY);
        }
        ctx.save();
      },
    },
  ];

  const datasetTotalValue: number = props.data.reduce(
    (total: number, value: number) => total + (value ?? 0),
    0,
  );

  const isTotalDataSetCountZero: boolean = datasetTotalValue === 0;

  // Extract the bin names and load counts from the data
  const binStages = props.legend.data.map(
    (legendData: PieChartLegendData) => `${legendData.key}`,
  );
  const dataSetCount = props.data.length;

  const colours: string[] = generateDistinctColours(
    dataSetCount,
    props.legend.data,
  );
  // Setup the chart data
  const chartData = {
    labels: binStages,
    datasets: [
      {
        data: isTotalDataSetCountZero ? [1] : props.data,
        backgroundColor: isTotalDataSetCountZero
          ? ['rgba(211, 211, 211, 0.5)']
          : colours,
        hoverBackgroundColor: colours,
        borderWidth: 0,
      },
    ],
  };

  const options = {
    responsive: true,
    maintainAspectRatio: true,
    cutout: '60%',
    plugins: {
      legend: {
        display: false,
      },
      tooltip: {
        enabled: true,
      },
    },
  };

  return (
    <div
      key={JSON.stringify(props.data)}
      className="d-flex flex-column flex-xl-row gap-xl-4 mt-3"
    >
      <div style={{ height: '100px', width: '100px' }}>
        <Doughnut data={chartData} options={options} plugins={plugins} />
      </div>

      <div className="flex-grow-1">
        <div className="d-flex justify-content-between gap-5">
          {props.legend.header && (
            <>
              <div className="d-flex legend-cycle-key align-items-center mr-4">
                {props.legend.header.headerKey}
              </div>
              <div>
                <span className="bin-legend-pie-bold-text">
                  {props.legend.header.headerValue}
                </span>
              </div>
            </>
          )}
        </div>
        {renderLegendDataForPieChart(
          props.legend.data,
          colours,
          props.subscriber,
        )}
      </div>
    </div>
  );
};

export default PieChart;
