import { useContext, useState } from 'react';
import FloatingInput from '../../../dustControl/components/FloatingInput';
import * as toast from '../../../dustControl/components/toast';
import { API_METHODS, ENDPOINTS } from '../../../dustControl/utils/constants';
import BasicButton from '../../../dustControl/components/BasicButton';
import { FormModal } from '../../../dustControl/components/FormModal';
import { lengthValidation } from '../../../dustControl/utils/helper';
import { UserContext } from '../../service/userContext';
import { useNavigate } from 'react-router-dom';

export const EditProfile = ({ user, onHide }) => {
  const navigate = useNavigate();
  const { ApiHandler } = useContext(UserContext);
  const [firstName, setFirstName] = useState(user.first_name);
  const [lastName, setLastName] = useState(user.last_name);
  const [errorMsg, setErrorMsg] = useState({
    firstErrorMsg: '',
    lastErrorMsg: '',
  });

  const handleSubmitClick = async (e) => {
    e.preventDefault();

    const trimmedFirst = firstName.trim();
    const trimmedLast = lastName.trim();
    const firstErrorMsg = lengthValidation(trimmedFirst, 'first name');
    const lastErrorMsg = lengthValidation(trimmedLast, 'last name');

    setErrorMsg({ firstErrorMsg, lastErrorMsg });
    if (firstErrorMsg || lastErrorMsg) {
      return;
    }

    try {
      const data = {
        first_name: trimmedFirst,
        last_name: trimmedLast,
        user_id: user._id,
        status: user.status,
        user_type: user.type,
      };
      const response = await ApiHandler({
        reqParam: data,
        method: API_METHODS.PUT,
        endPoint: ENDPOINTS.editUser,
      });

      if (response.status === 200) {
        setTimeout(() => {
          // refreshes the page
          navigate(0);
        }, 4000);
        toast.success('Details successfully updated');
        onHide();
      }
    } catch (error) {
      toast.error(error?.message);
    }
  };
  return (
    <FormModal header="Edit Account Details">
      <div className="edit-profile-fields">
        <FloatingInput
          value={firstName}
          setValue={setFirstName}
          label="First Name *"
          type="text"
          isError={errorMsg.firstErrorMsg ? true : false}
        />

        <FloatingInput
          value={lastName}
          setValue={setLastName}
          label="Last Name *"
          type="text"
          isError={errorMsg.lastErrorMsg ? true : false}
        />
      </div>

      <p className="error-msg-2 text-start">
        {errorMsg.firstErrorMsg || errorMsg.lastErrorMsg}
      </p>

      <FloatingInput
        style={{ width: '100%' }}
        value={user.email}
        label="Email *"
        type="text"
        disabled={true}
      />

      <div className="d-flex gap-2 w-75 align-self-end justify-content-end mt-3">
        <BasicButton onClick={onHide} outlined variant="outline-dark">
          Cancel
        </BasicButton>
        <BasicButton onClick={(e) => handleSubmitClick(e)} variant="dark">
          Save Changes
        </BasicButton>
      </div>
    </FormModal>
  );
};
