import ReactDOM from 'react-dom';

export const TitlePopup = (
  coordinate,
  { title, data, onSelect = () => {} , onClose = () => {} },
) => {
  const div = document.createElement('div');
  const popup = (
    <div onClick={onSelect}>
      <div className="title-popup d-flex flex-column p-1 px-2">
        <div>{data?.title}</div>
      </div>
    </div>
  );

  ReactDOM.render(popup, div);
  return div;
};
