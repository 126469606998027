import React, { ReactNode } from 'react';

interface ToolTipProps {
  date: string;
  metrics: {
    label?: string;
    value: string | number;
    isComponent?: boolean;
    Component?: ReactNode;
  }[];
  description?: string;
}

const ToolTip: React.FC<ToolTipProps> = ({ date, metrics, description }) => {
  return (
    <div
      style={{ width: 210 }}
      className="container rounded-3 shadow-sm px-4 m-5 py-2"
    >
      <div className="card-body">
        <h5 className="fw-bold mb-1" style={{ fontSize: 14 }}>
          {date}
        </h5>
        <h6
          className="fw-bold "
          style={{ fontSize: 12, fontStyle: 'italic', color: '#5F6672' }}
        >
          {description}
        </h6>
        <div className="mt-3">
          {metrics.map((metric, index) => (
            <div
              key={index}
              className="d-flex justify-content-between align-items-center me-3"
            >
              {!metric.isComponent ? (
                <span>{metric.label}</span>
              ) : (
                metric.Component
              )}
              <span style={{ fontSize: 14 }} className="fw-bold">
                {metric.value}
              </span>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default ToolTip;
