import { FC, useContext, useState } from 'react';
import { BinWithPickup } from '../../utils/types';
import PopupModal from '.';
import { UserContext } from '../../../pages/service/userContext';
import * as toast from '../../../dustControl/components/toast';

import { Routes } from '../../utils/routes';
import { API_METHODS } from '../../utils/constants';
import CustomButton from '../../../dustControl/components/CustomButton';

type CancelPickupProps = {
  isOpen: boolean;
  onClose: () => void;
  bin: BinWithPickup;
};

const CancelPickUp: FC<CancelPickupProps> = (props) => {
  const [loading, setLoading] = useState(false);
  const { ApiHandler } = useContext(UserContext);

  const handleConfirmClick = async () => {
    try {
      setLoading(true);
      await ApiHandler({
        endPoint: Routes.CANCEL_PICKUP,
        method: API_METHODS.PUT,
        reqParam: {
          binName: props.bin.name,
        },
      });
      props.onClose();
      toast.success(`Pickup successfully cancelled`);
    } catch (err) {
      toast.error((err as Error)?.message);
    } finally {
      setLoading(false);
    }
  };
  return (
    <PopupModal isOpen={props.isOpen} onClose={props.onClose}>
      <div className=" d-flex flex-column  gap-3 justify-content-evenly align-content-center">
        <h6 className="fw-bolder  ">Cancel Pickup - Bin {props.bin.name}</h6>
        <p>Are you sure you want to cancel the pickup request?</p>
        <CustomButton
          style={{
            background: 'black',
            paddingLeft: '2rem',
            paddingRight: '2rem',
            width: 'fit-content',
            alignSelf: 'center',
          }}
          loading={loading}
          id="confirmButton"
          title={'Confirm'}
          onClick={handleConfirmClick}
        />
      </div>
    </PopupModal>
  );
};

export default CancelPickUp;
