import React, { useEffect, useState } from 'react';
import { GetAllTrucksData, TruckData } from '../../utils/types';
import { Button, Grid, Paper } from '@mui/material';
import { TruckIconGreen } from '../../assets/images/TruckGreen';
import { TruckIconGrey } from '../../assets/images/TruckGrey';
import { Operator } from '../../assets/images/Operator';
import { HistoryBlack } from '../../assets/images/HistoryBlack';
import BinIcon from '../../assets/images/Bin';
import { HourglassIcon } from '../../assets/images/hourglass';
import { DefaultMarker } from '../../assets/images/DefaultMarker';
import './styles.css';
import { TrackBinModal } from '../TrackBin';
import { TruckDetailsProps } from './types';
import { convertMinsToHHMM, getDurationAgo } from '../../utils/helpers';
import { getLastLocation } from '../../utils/helpers';
import { capitaliseFirstLetter } from '../../utils/helper';
import { TruckIconRed } from '../../assets/images/TruckRed';

const TruckDetails: React.FC<TruckDetailsProps> = ({
  trucks,
  truckData,
  setActionModal,
}) => {
  const formatHours = (delayInMinutes: number | undefined) => {
    if (typeof delayInMinutes !== 'number') return 'N/A';
    const minutesRounded: number = Math.round(delayInMinutes);

    const hours = Math.floor(minutesRounded / 60);
    const minutes = minutesRounded % 60;

    return `${hours.toString().padStart(2, '0')}:${minutes
      .toString()
      .padStart(2, '0')} h`;
  };

  const [truckCategory, setTruckStatusCategory] = useState<string | null>(null);

  const handleOpenTrackBinModal = () => {
    setActionModal(
      <TrackBinModal
        onClose={() => setActionModal(<></>)}
        isOpen={true}
        binName={truckData.bin}
        truck={truckData}
      />,
    );
  };

  useEffect(() => {
    const findTruckStatusCategory = () => {
      if (trucks && truckData) {
        (Object.keys(trucks) as Array<keyof GetAllTrucksData>).forEach(
          (category) => {
            const truckList = trucks[category];
            if (
              truckList?.some(
                (truck: TruckData) => truck.name === truckData.name,
              )
            ) {
              setTruckStatusCategory(category);
            }
          },
        );
      } else {
        setTruckStatusCategory(null);
      }
    };
    findTruckStatusCategory();
  }, [trucks, truckData]);

  const { status = '' } = truckData ?? {};
  const lastLoad = getDurationAgo(truckData?.lastLoad);
  const lastLocation = getLastLocation(truckData?.lastLocation);

  return (
    <Paper elevation={0} className="truckDetailsContainer">
      <Grid container spacing={2}>
        <Grid item xs={1} className="bin-details-icon">
          {/* Conditional rendering based on the truck's status */}
          {status === 'Parked' ? (
            <TruckIconGrey height={26} width={40} />
          ) : (
            <TruckIconGreen height={26} width={40} />
          )}
        </Grid>
        <Grid item xs={5}>
          <div>
            {' '}
            Truck Status:<span className="variableText">{status}</span>
          </div>
        </Grid>
        <Grid item xs={1} className="bin-details-icon">
          <Operator height={36} width={28} />
        </Grid>
        <Grid item xs={5}>
          <div>
            {' '}
            Operator:{' '}
            <span className="variableText">{truckData?.operator ?? 'N/A'}</span>
          </div>
        </Grid>
        <Grid item xs={1} className="bin-details-icon">
          <DefaultMarker height={32} width={22} />
        </Grid>
        <Grid item xs={5}>
          <div>
            Last Seen Location:{' '}
            <span className="variableText">{lastLocation}</span>
          </div>
        </Grid>
        <Grid item xs={1} className="bin-details-icon">
          <BinIcon height={34} width={35} />
        </Grid>
        <Grid item xs={5}>
          <div>
            Bin ID:{' '}
            <span className="variableText">Bin {truckData?.bin ?? 'N/A'}</span>
          </div>
        </Grid>
        <Grid item xs={1} className="bin-details-icon">
          <HourglassIcon height={30} width={19} />
        </Grid>
        <Grid item xs={11}>
          <div>
            Last Load (LL):{' '}
            <span className="variableText">{lastLoad ?? 'N/A'}</span>
          </div>
        </Grid>
      </Grid>

      <Grid container spacing={2} className="shift-summary">
        <Grid item xs={12} className="detail-section">
          <h6 className="summaryHeaders">Shift Summary:</h6>
          <div
            style={{
              background:
                'linear-gradient(to right, rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0))',
              margin: '-15px 0px 0px 0px',
              height: '1px',
            }}
          ></div>
        </Grid>
        <Grid item xs={1} className="bin-details-icon">
          <TruckIconGreen height={19} width={30} />
        </Grid>
        <Grid item xs={3}>
          <div>
            Active:{' '}
            <span className="variableText">
              {`${convertMinsToHHMM(truckData?.shiftSummary?.active ?? 0)} h`}
            </span>
          </div>
        </Grid>
        <Grid item xs={1} className="bin-details-icon">
          <TruckIconRed height={19} width={30} />
        </Grid>
        <Grid item xs={3}>
          <div>
            Idle:{' '}
            <span className="variableText">
              {`${convertMinsToHHMM(truckData?.shiftSummary?.idle ?? 0)} h`}
            </span>
          </div>
        </Grid>
        <Grid item xs={1} className="bin-details-icon">
          <TruckIconGrey height={19} width={30} />
        </Grid>
        <Grid item xs={3}>
          <div>
            Parked:{' '}
            <span className="variableText">
              {`${convertMinsToHHMM(truckData?.shiftSummary?.parked ?? 0)} h`}
            </span>
          </div>
        </Grid>
        <Grid item xs={1} className="bin-details-icon">
          <BinIcon height={24} width={25} />
        </Grid>
        <Grid item xs={3}>
          <div>
            Total Bin Loads:{' '}
            <span className="variableText">
              {truckData?.shiftSummary?.totalBinLoad ?? 'N/A'}
            </span>
          </div>
        </Grid>

        <Grid item xs={1} className="bin-details-icon">
          <BinIcon height={24} width={25} />
        </Grid>
        <Grid item xs={3}>
          <div>
            Pre-Start Checks:{' '}
            <span className="variableText">
              {capitaliseFirstLetter(truckData?.shiftSummary?.prestartChecks) ??
                'N/A'}
            </span>
          </div>
        </Grid>

        <Grid item xs={1} className="bin-details-icon">
          <HistoryBlack height={22} width={22} />
        </Grid>
        <Grid item xs={3}>
          <div>
            Delays:{' '}
            <span className="variableText">
              {formatHours(truckData?.shiftSummary?.delays)}
            </span>
          </div>
        </Grid>
      </Grid>

      <Grid container spacing={2}>
        <Grid item xs={12}>
          <h6 className="summaryHeaders">Other Details:</h6>
          <div
            style={{
              background:
                'linear-gradient(to right, rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0))',
              margin: '-15px 0px 0px 0px',
              height: '1px',
            }}
          ></div>
        </Grid>
        <Grid item xs={1} className="bin-details-icon">
          <HistoryBlack height={22} width={22} />
        </Grid>
        <Grid item xs={5}>
          <div>
            Engine Hours (Daily):{' '}
            <span className="variableText">
              {formatHours(truckData?.engineHourSummary?.dailyEngineHour)}
            </span>
          </div>
        </Grid>
        <Grid item xs={1} className="bin-details-icon">
          <HistoryBlack height={22} width={22} />
        </Grid>
        <Grid item xs={5}>
          <div>
            Engine Hours (Lifetime):{' '}
            <span className="variableText">
              {formatHours(truckData?.engineHourSummary?.lifetimeEngineHour)}
            </span>
          </div>
        </Grid>
        {/* Add more detail items as needed */}
      </Grid>

      <Grid container spacing={2} className="detail-actions">
        {truckCategory === 'inProgress' ? (
          <React.Fragment>
            <Grid item xs={6}>
              <Button
                onClick={handleOpenTrackBinModal}
                variant="contained"
                className="details-action-button"
              >
                <span className="buttonText">Track Current Job</span>
              </Button>
            </Grid>
          </React.Fragment>
        ) : (
          <React.Fragment>
            <Grid item xs={6}>
              <Button
                onClick={handleOpenTrackBinModal}
                variant="contained"
                className="buttonText details-action-button"
              >
                <span className="buttonText">Last Job's Summary</span>
              </Button>
            </Grid>
          </React.Fragment>
        )}
      </Grid>
    </Paper>
  );
};

export default TruckDetails;
