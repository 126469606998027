import React from 'react';

const TrafficLightIndicator = ({ stop = false, shadow = true, size = 67 }) => {
  return (
    <div
      style={{
        backgroundColor: stop ? '#EB5F5F' : '#04D149',
        height: size,
        width: size,
        borderRadius: '50%',
        boxShadow: shadow
          ? '0px 0px 20px ' + (stop ? '#EB5F5F80' : '#04D149')
          : 'none',
      }}
    />
  );
};

export default TrafficLightIndicator;
