import React from 'react';
import { DetailRow } from './DetailRow';
import { UniqueAssetRow } from './UniqueAssetRow';
import { formatDateToDDMMYYYY } from '../utils/helper';

export const GeofencePopupDetails = ({ asset, materials, assets, loads, lastActivity, getMaterialColor = () => { } }) => {

    return <>
        <div style={{ borderTop: "1px solid #EBEBEB" }} className=" py-2 px-1 w-100 d-flex flex-column">
            <DetailRow label="Total Loads" value={loads?.length} />
            {asset === "Excavators" || loads.length ?
                <>
                    {
                        materials.length === 0 || materials.length === 1 ?
                            <DetailRow label="Material" value={materials && materials.length && materials[0].material ? materials[0].material : '-'} />
                            :
                            <div className='p-0 m-0 mt-2 ' >
                                <ul className='pe-2 ps-0 py-0 m-0'>
                                    {materials?.map((e, i) =>
                                        <li className='d-flex justify-content-start align-items-center' style={{ fontSize: "0.8rem" }} key={i}>
                                            <span className='mm-dot-1' style={{ backgroundColor: getMaterialColor(e.material) }}></span> {e.loadsCount}  {e.material}
                                        </li>)}
                                </ul>
                                <hr className='p-0 m-0 mt-1 ' />
                            </div>
                    }
                </>
                : ""
            }

            {
                assets.length === 0 || assets.length === 1 ?
                    <DetailRow label={asset} value={assets && assets.length && assets[0].name ? assets[0].name : '-'} />
                    :
                    <>
                        <DetailRow label={asset} value={assets.length} underlined={true} />
                        {
                            materials.length === 1 ?
                                <>
                                    <div className='p-0 m-0 mb-2 ' ></div>
                                    <hr className='p-0 m-0 mt-1 ' />
                                    <div className='p-0 m-0 mb-2 ' ></div>
                                </>
                                :
                                <div className='p-0 m-0 mt-2 ' ></div>
                        }
                        <UniqueAssetRow
                            label={asset}
                            value={assets} />
                    </>
            }
            {loads?.length === 0 ?
                <DetailRow label="Last Activity" value={lastActivity ? formatDateToDDMMYYYY(lastActivity * 1000) : 'No records yet'} />
                :
                ""
            }
        </div>
    </>


};
