import { FC } from 'react';
import { DashboardProps } from '../dustControl/pages/Dashboard/types';
import { Container } from 'react-bootstrap';
import Workshop from './pages/workshop';
import EngineHour from './pages/engineHour';
import { withAccessControl } from '../pages/service/with-access-control';

const DEFAULT_SELECTED_TAB = 1; // While workshop page doesn't exist, open engine hours page first

const Maintenance: FC<DashboardProps> = (props) => {
  const { selectedTab } = props;

  return (
    <Container className="container-fluid" fluid>
      {selectedTab === 0 ? (
        <Workshop />
      ) : selectedTab === 1 ? (
        <EngineHour />
      ) : null}
    </Container>
  );
};

export default withAccessControl(Maintenance, DEFAULT_SELECTED_TAB);
