export const Logo = ({ textColor }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="72"
    height="62"
    viewBox="0 0 64 62"
  >
    <g id="Group_47248" data-name="Group 47248" transform="translate(-79 -28)">
      <g id="Group_47113" data-name="Group 47113" transform="translate(86 28)">
        <g id="Group_21724" data-name="Group 21724" transform="translate(0)">
          <path
            id="Path_10224"
            data-name="Path 10224"
            d="M10338.516,16429.182l1.823,2.383,9.1-11.373h-3.977Z"
            transform="translate(-10338.516 -16418.158)"
            fill="#26704d"
          />
          <path
            id="Path_10222"
            data-name="Path 10222"
            d="M10338.516,16432.547l2.369,3.275,11.824-15.619h-5.17Z"
            transform="translate(-10336.034 -16418.021)"
            fill="#26704d"
          />
          <path
            id="Path_10220"
            data-name="Path 10220"
            d="M10338.516,16436.4l3.107,4.3,15.518-20.5h-6.783Z"
            transform="translate(-10332.904 -16417.492)"
            fill="#26704d"
          />
          <path
            id="Path_10212"
            data-name="Path 10212"
            d="M10338.516,16441.889l4.231,5.75,21.146-27.436h-9.244Z"
            transform="translate(-10328.661 -16417.123)"
            fill="#26704d"
          />
          <path
            id="Path_10211"
            data-name="Path 10211"
            d="M10338.516,16449.191l5.7,7.691,28.5-36.68h-12.463Z"
            transform="translate(-10323.358 -16417.125)"
            fill="#26704d"
          />
          <path
            id="Path_10209"
            data-name="Path 10209"
            d="M10338.516,16449.373l5.738,7.74,28.684-36.91H10360.4Z"
            transform="translate(-10323.573 -16420.203)"
            fill="#e0332c"
          />
          <path
            id="Path_10210"
            data-name="Path 10210"
            d="M10338.516,16441.891l4.16,5.754,20.773-27.441h-9.082Z"
            transform="translate(-10329.042 -16420.203)"
            fill="#e0332c"
          />
          <path
            id="Path_10219"
            data-name="Path 10219"
            d="M10338.516,16436.4l3.107,4.3,15.518-20.5h-6.783Z"
            transform="translate(-10332.904 -16420.203)"
            fill="#e0332c"
          />
          <path
            id="Path_10221"
            data-name="Path 10221"
            d="M10338.516,16432.066l2.276,3.146,11.362-15.01h-4.968Z"
            transform="translate(-10335.812 -16420.203)"
            fill="#e0332c"
          />
          <path
            id="Path_10223"
            data-name="Path 10223"
            d="M10338.516,16428.816l1.653,2.285,8.249-10.9h-3.605Z"
            transform="translate(-10338.179 -16420.203)"
            fill="#e0332c"
          />
        </g>
      </g>
      <text
        id="SteelServ"
        transform="translate(79 87)"
        fill={textColor ?? 'white'}
        fontSize="15"
        fontFamily="Arial, Helvetica, sans-serif"
        fontWeight="700"
      >
        <tspan x="0" y="0">
          SteelServ
        </tspan>
      </text>
    </g>
  </svg>
);
