import { useEffect } from 'react';
import { Table } from 'react-bootstrap';
import { convertTruckStatusToColor, formatAMPM } from '../../utils/helper';
import { formatTime } from '../reports/job-daily-report';
import StatusDot from '../StatusDot';
import { TableColumn, TruckInfoProps } from './types';
import { Truck } from '../../utils/types';

const tableHeader: TableColumn[] = [
  { header: 'Truck', functionalValue: ({ name }) => name },
  {
    header: 'Status',
    functionalValue: ({ status, jobType }) => {
      const className = convertTruckStatusToColor(status);
      let statusWithJobType: string = status;
      if (status === 'Pumping') {
        statusWithJobType += ` - ${jobType}`;
      }
      return <StatusDot className={className}>{statusWithJobType}</StatusDot>;
    },
  },
  {
    header: 'Location',
    functionalValue: ({ location }) =>
      ` ${
        !location ? '-' : location === 7 ? 'Water Tower' : `Zone ${location}`
      }`,
  },
  {
    header: 'Job pump time',
    functionalValue: ({ jobPumpTime }) => formatTime(jobPumpTime, true),
  },

  {
    superheader: 'Water consumption',
    header: 'Current job',
    functionalValue: ({ jobWaterConsumption }) =>
      jobWaterConsumption ? `${jobWaterConsumption} L` : null,
    header2: 'Total',
    functionalValue2: ({ totalWaterConsumption }) =>
      totalWaterConsumption ? `${totalWaterConsumption} L` : null,
  },
  {
    superheader: 'Engine hours',
    header: 'Daily',
    functionalValue: ({ dailyEngineHour }) =>
      formatTime(dailyEngineHour, false),
    header2: 'Total',
    functionalValue2: ({ totalEngineHour }) => `${totalEngineHour} h`,
  },
  {
    header: 'Operator',
    functionalValue: ({ operator }) =>
      operator ? `${operator?.first_name} ${operator?.last_name}` : '',
  },
  {
    header: 'Shift start',
    functionalValue: ({ shiftStart }) =>
      shiftStart ? `${formatAMPM(new Date(shiftStart))}` : null,
  },
];

const TruckInfo: React.FunctionComponent<TruckInfoProps> = (props) => {
  const refreshData: TruckInfoProps['refreshData'] = props.refreshData;

  useEffect(() => {
    const handleVisibilityChange = () => {
      if (!document.hidden) {
        refreshData(undefined, false);
      }
    };

    document.addEventListener('visibilitychange', handleVisibilityChange);

    return () => {
      document.removeEventListener('visibilitychange', handleVisibilityChange);
    };
  }, [refreshData]);

  return (
    <div className="truck-view px-4">
      <div className="py-4 truck-info-text">
        <span>Water Trucks -</span>{' '}
        <span className="light-grey-text">Shift and Jobs information</span>
      </div>

      <Table responsive>
        <thead>
          <tr>
            {tableHeader.map(({ header, superheader }) => (
              <th
                className={`table-text pt-3 px-3 bg-white border-top border-bottom-0  ${
                  superheader ? 'text-center' : 'text-start'
                }`}
                key={header}
              >
                {superheader ?? header}
              </th>
            ))}
          </tr>
          <tr>
            {tableHeader.map((column: TableColumn) => (
              <th
                className="table-text pt-1 pb-3 px-3 bg-white"
                key={column.header}
              >
                {column.superheader ? (
                  <div className="d-flex flex-row justify-content-between align-items-center">
                    <span>{column.header}</span>
                    <span>{column.header2}</span>
                  </div>
                ) : (
                  ''
                )}
              </th>
            ))}
          </tr>
        </thead>
        <tbody>
          {props.trucks.map((truck: Truck) => (
            <tr key={truck.name}>
              {tableHeader.map((row: TableColumn) => (
                <td
                  className="py-2 px-3 table-text no-word-break"
                  key={row.header}
                >
                  {row.superheader ? (
                    <div className="d-flex flex-row justify-content-between align-items-center gap-2">
                      <span>{row.functionalValue(truck) || '-'}</span>
                      <span>{row.functionalValue2(truck) || '-'}</span>
                    </div>
                  ) : (
                    row.functionalValue(truck) || '-'
                  )}
                </td>
              ))}
            </tr>
          ))}
        </tbody>
      </Table>
    </div>
  );
};

export default TruckInfo;
