import React from 'react';
import moment from 'moment';
import ReactDOM from 'react-dom';
import info from "../assets/images/info.svg";
import close from "../assets/icons/close-icon.svg";
import { Image } from 'react-bootstrap';
import { DetailRow } from './DetailRow';
import { formatDateDDMMYYYY } from '../utils/helper';

export const DetailPopup = (coordinate, { title, data,  onClose = () => {} }) => {
    const div = document.createElement('div');
    const type = data?.type === 'source' ? 'Source Point' : 'Dump Point';

    const popup = (
        <div className="extra-detail-popup d-flex flex-column p-1 px-2 d-flex" >
            <div className="d-flex p-0 m-0 justify-content-end">
                <Image onClick={onClose} src={close} style={{ height: "0.8rem" }} />
            </div>
            <div className="d-flex py-2 pt-1 px-1 justify-content-between">
                <div className="d-flex justify-content-start  ">
                    <div className="">
                        <Image src={info} style={{ height: "1rem" }} />
                    </div>
                    <div
                        className="p-0 m-0 pb-1 px-2 text-black color-black"
                        style={{ font: " normal normal 600 0.8rem Open Sans" }}
                    >
                        {data?.name?.toUpperCase()} DETAILS
                    </div>
                </div>
            </div>
            <div style={{ borderTop: "1px solid #EBEBEB" }} className=" py-2 px-1 w-100 d-flex flex-column">
                <DetailRow label="Name" value={data?.name} />
                <DetailRow label="Type" value={type} />
                <DetailRow label="Materials" value={data?.materials.toString() ?? '-'} />
                <DetailRow label="Description" value={data?.description} />
                <DetailRow label="Created On" value={moment(data?.createdAt).format('DD/MM/YYYY')} />
                <DetailRow label="Created By" value={data?.created_by_name} />
                <DetailRow label="First Activity" value={data?.firstActivity ? formatDateDDMMYYYY(data?.firstActivity * 1000) : '-'} />
                <DetailRow label="Last Activity" value={data?.lastActivity ? formatDateDDMMYYYY(data?.lastActivity * 1000) : '-'} />
            </div>
        </div>
    );

    ReactDOM.render(popup, div);
    return div;
};
