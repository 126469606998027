import { Truck } from '../../utils/types';

export enum SocketEvent {
  TRUCK_STATUS = 'truckStatus',
}

export type TruckSocketData = {
  [truckName: string]: Partial<Truck>;
};

export type SocketResponse = {
  socketEvent: SocketEvent.TRUCK_STATUS;
  truckName: string;
} & Partial<Truck>;

export type DashboardProps = {
  selectedTab: number;
};
