import React from 'react';
import { Spinner } from 'react-bootstrap';
import DownloadIcon from '../../assets/icons/download.svg';
import CloseIcon from '../../assets/icons/cross.svg';

const LoadingScreen = () => {
  return (
    <div className="text-center">
      <Spinner animation="border " role="status">
        <span className="visually-hidden">Loading...</span>
      </Spinner>
    </div>
  );
};

function PdfModal(props) {
  return (
    <div className="modal-view-report">
      <div className="pdf-modal-content">{props.children}</div>
      <div className="download-icon-report gap-3">
        {props.onDownloadPress && (
          <div
            className="cursor-pointer text-white"
            onClick={() => props?.onDownloadPress()}
          >
            {props?.loading ? (
              <LoadingScreen />
            ) : (
              <img src={DownloadIcon} alt="download" />
            )}
          </div>
        )}
        <div
          className="cursor-pointer text-white"
          onClick={() => props?.onClose()}
        >
          <img src={CloseIcon} alt="close" />
        </div>
      </div>
    </div>
  );
}

export default PdfModal;
