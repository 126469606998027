import BasicButton from '../BasicButton';
import Dropdown from '../../assets/icons/dropdown.png';
import { useCallback, useEffect, useState } from 'react';

const DownloadModal = ({ type, onCreate, onCancel, downloading }) => {
  const [downloadFields, setDownloadFields] = useState([
    { name: '', field: '', type: '', value: '', options: [] },
  ]);
  const [displayedDropdown, setDisplayedDropdown] = useState();

  const zoneOptions = useCallback(
    () => [
      {
        name: 'Select date range',
        field: 'dateRange',
        type: 'dateRange',
        value: {
          startDate: '',
          endDate: '',
        },
      },
      {
        name: 'Zones',
        field: 'zones',
        type: 'select',
        options: [
          {
            value: 1,
            displayedValue: 'Zone 1',
            name: 'zone',
          },
          {
            value: 2,
            displayedValue: 'Zone 2',
            name: 'zone',
          },
          {
            value: 3,
            displayedValue: 'Zone 3',
            name: 'zone',
          },
          {
            value: 4,
            displayedValue: 'Zone 4',
            name: 'zone',
          },
          {
            value: 5,
            displayedValue: 'Zone 5',
            name: 'zone',
          },
          {
            value: 6,
            displayedValue: 'Zone 6',
            name: 'zone',
          },
        ],
        value: [],
      },
      {
        name: 'Trucks',
        field: 'trucks',
        type: 'select',
        options: [
          {
            value: '627',
            name: 'truck',
          },
          {
            value: '721',
            name: 'truck',
          },
          {
            value: '819',
            name: 'truck',
          },
        ],
        value: [],
      },
    ],
    [],
  );

  const jobHistoryOptions = useCallback(
    () => [
      {
        name: 'Select date range',
        field: 'dateRange',
        type: 'dateRange',
        value: {
          startDate: '',
          endDate: '',
        },
      },
      {
        name: 'Zones',
        field: 'zones',
        type: 'select',
        options: [
          {
            value: 1,
            displayedValue: 'Zone 1',
            name: 'zone',
          },
          {
            value: 2,
            displayedValue: 'Zone 2',
            name: 'zone',
          },
          {
            value: 3,
            displayedValue: 'Zone 3',
            name: 'zone',
          },
          {
            value: 4,
            displayedValue: 'Zone 4',
            name: 'zone',
          },
          {
            value: 5,
            displayedValue: 'Zone 5',
            name: 'zone',
          },
          {
            value: 6,
            displayedValue: 'Zone 6',
            name: 'zone',
          },
        ],
        value: [],
      },
      {
        name: 'Trucks',
        field: 'trucks',
        type: 'select',
        options: [
          {
            value: '627',
            name: 'truck',
          },
          {
            value: '721',
            name: 'truck',
          },
          {
            value: '819',
            name: 'truck',
          },
        ],
        value: [],
      },
      {
        name: 'Status',
        field: 'status',
        type: 'select',
        options: [
          {
            value: 'complete',
            displayedValue: 'Complete',
            name: 'status',
          },
          {
            value: 'incomplete',
            displayedValue: 'Incomplete',
            name: 'status',
          },
        ],
        value: [],
      },
    ],
    [],
  );

  const waterTowerOptions = useCallback(
    () => [
      {
        name: 'Select date range',
        field: 'dateRange',
        type: 'dateRange',
        value: {
          startDate: '',
          endDate: '',
        },
      },
      {
        name: 'Job Ref No.',
        field: 'number',
        type: 'text',
        value: '',
      },
      {
        name: 'Trucks',
        field: 'trucks',
        type: 'select',
        options: [
          {
            value: '627',
            name: 'truck',
          },
          {
            value: '721',
            name: 'truck',
          },
          {
            value: '819',
            name: 'truck',
          },
        ],
        value: [],
      },
      {
        name: 'Tower',
        field: 'tower',
        type: 'select',
        options: [
          {
            value: 1,
            name: 'tower',
          },
          {
            value: 2,
            name: 'tower',
          },
        ],
        value: [],
      },
    ],
    [],
  );

  useEffect(() => {
    if (type === 'zone') {
      setDownloadFields(zoneOptions());
    }
    if (type === 'job') {
      setDownloadFields(jobHistoryOptions());
    }
    if (type === 'waterTower') {
      setDownloadFields(waterTowerOptions());
    }
  }, [type, zoneOptions, jobHistoryOptions, waterTowerOptions]);

  const handleDateBtns = (value) => {
    const obj = [...downloadFields];
    obj[0].value.endDate = new Date(Date.now()).toISOString().slice(0, 10);
    if (value === 'today') {
      obj[0].value.startDate = new Date(Date.now()).toISOString().slice(0, 10);
    } else if (value === 'yesterday') {
      obj[0].value.startDate = new Date(Date.now() - 1 * 24 * 60 * 60 * 1000)
        .toISOString()
        .slice(0, 10);
      obj[0].value.endDate = new Date(Date.now() - 1 * 24 * 60 * 60 * 1000)
        .toISOString()
        .slice(0, 10);
    } else {
      obj[0].value.startDate = new Date(
        Date.now() - value * 24 * 60 * 60 * 1000,
      )
        .toISOString()
        .slice(0, 10);
    }
    setDownloadFields(obj);
  };

  const handleDate = (event, type) => {
    const obj = [...downloadFields];
    if (type === 'start') {
      if (
        new Date(event.target.value) <= new Date(Date.now()) &&
        new Date(event.target.value) <=
          new Date(obj[0].value.endDate || Date.now())
      )
        obj[0].value.startDate = event.target.value;
      else obj[0].value.startDate = '';
    } else {
      if (
        new Date(event.target.value) <= new Date(Date.now()) &&
        (obj[0].value.startDate
          ? new Date(event.target.value) >= new Date(obj[0].value.startDate)
          : true)
      )
        obj[0].value.endDate = event.target.value;
      else obj[0].value.endDate = '';
    }
    setDownloadFields(obj);
  };

  const handleSelect = (value, index) => {
    const obj = [...downloadFields];
    if (!obj[index].value.includes(value)) {
      obj[index].value.push(value);
    } else {
      obj[index].value = obj[index].value.filter((val) => val !== value);
    }
    setDownloadFields(obj);
  };

  const handleSearch = (value, index) => {
    let tempDownloadObj = [];
    if (type === 'zone') {
      tempDownloadObj = zoneOptions();
    } else if (type === 'job') {
      tempDownloadObj = jobHistoryOptions();
    } else if (type === 'waterTower') {
      tempDownloadObj = waterTowerOptions();
    }
    if (!value) {
      setDownloadFields([...tempDownloadObj]);
    } else {
      let options = tempDownloadObj[index].options.filter((opt) =>
        opt.value.toString().toLowerCase().includes(value.toLowerCase()),
      );
      tempDownloadObj[index].options = options;
      setDownloadFields([...tempDownloadObj]);
    }
  };

  const handleTextSearch = (value, index) => {
    const newFields = downloadFields.map((field, fieldIndex) => {
      if (index === fieldIndex) return { ...field, value };
      else return field;
    });
    setDownloadFields(newFields);
  };

  const handleShowSelectOptions = (name) => {
    if (displayedDropdown === name) setDisplayedDropdown(undefined);
    else setDisplayedDropdown(name);
  };

  const onCreateFunc = () => {
    const payload = {};
    downloadFields.forEach((field, index) => {
      if (field.type === 'dateRange') {
        payload.startDate = downloadFields[index].value.startDate
          ? new Date(downloadFields[index].value.startDate).toISOString()
          : new Date(Date.now()).toISOString();
        payload.endDate = downloadFields[index].value.endDate
          ? new Date(downloadFields[index].value.endDate).toISOString()
          : new Date(Date.now()).toISOString();
      } else {
        payload[field.field] = field.value;
      }
    });
    onCreate(payload);
  };

  return (
    <div className="download-modal-container">
      <div className="download-modal-header mt-4 pb-2">DOWNLOAD REPORT</div>
      <div className="download-modal-body">
        {downloadFields.map((field, index) => {
          return field.type === 'dateRange' ? (
            <div key={index} className="date-picker mb-2">
              <div className="py-1 bold-md" style={{ fontSize: '12px' }}>
                Select date range
              </div>
              <div>
                <div className="py-1 bold-md" style={{ fontSize: '10px' }}>
                  From
                </div>
                <div>
                  <input
                    className="date-picker-input"
                    type="date"
                    name="from"
                    value={field.value.startDate}
                    onChange={(e) => handleDate(e, 'start')}
                  />
                </div>
              </div>
              <div>
                <div className="py-1 bold-md" style={{ fontSize: '10px' }}>
                  To
                </div>
                <div>
                  <input
                    className="date-picker-input"
                    type="date"
                    name="to"
                    value={field.value.endDate}
                    onChange={(e) => handleDate(e, 'end')}
                  />
                </div>
              </div>
              <div className="date-btns my-1">
                <div onClick={() => handleDateBtns('today')}>Today</div>
                <div onClick={() => handleDateBtns('yesterday')}>Yesterday</div>
                <div onClick={() => handleDateBtns(7)}>Last 7 days</div>
                <div onClick={() => handleDateBtns(30)}>Last 30 days</div>
              </div>
            </div>
          ) : field.type === 'select' ? (
            <div key={index} className="mb-2">
              <div className="py-1 bold-md" style={{ fontSize: '10px' }}>
                {field.name}
              </div>
              <div className="select-dropdown">
                <div className="value-box">
                  <div
                    className="value text-capitalize"
                    onClick={() => handleShowSelectOptions(field.name)}
                    style={{ width: '100%', height: '100%', cursor: 'pointer' }}
                  >
                    {field.value.join(', ')}
                  </div>
                  <img
                    src={Dropdown}
                    alt=""
                    className="drop-down-image"
                    onClick={(e) => handleShowSelectOptions(field.name)}
                  />
                </div>
                <div
                  className={`option-container ${
                    displayedDropdown === field.name ? '' : 'hide'
                  }`}
                >
                  <div className="search">
                    <input
                      type="text"
                      name="search"
                      placeholder="Search"
                      onChange={(e) => handleSearch(e.target.value, index)}
                    />
                  </div>
                  {field.options.map((item) => (
                    <div className="option" key={item.value}>
                      <input
                        type="checkbox"
                        multiple
                        name={item.name}
                        value={item.value}
                        onClick={(e) => handleSelect(item.value, index)}
                      />
                      {item.displayedValue ?? item.value}
                    </div>
                  ))}
                  <div className="done option">
                    <BasicButton
                      onClick={() => handleShowSelectOptions(field.name)}
                    >
                      Done
                    </BasicButton>
                  </div>
                </div>
              </div>
            </div>
          ) : field.type === 'text' ? (
            <div key={index} className="search">
              <div className="py-1 bold-md" style={{ fontSize: '10px' }}>
                {field.name}
              </div>
              <input
                type="text"
                name="search"
                value={field.value}
                onChange={(e) => handleTextSearch(e.target.value, index)}
              />
            </div>
          ) : (
            ''
          );
        })}
        <div className="mt-3 btn-container gap-2">
          <BasicButton outlined={true} onClick={onCancel}>
            Cancel
          </BasicButton>
          <BasicButton disabled={downloading} onClick={onCreateFunc}>
            {downloading ? `Downloading...` : `Create`}
          </BasicButton>
        </div>
      </div>
    </div>
  );
};

export default DownloadModal;
