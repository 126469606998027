import { Link } from 'react-router-dom';
import { getBinIconBasedOnLevel } from '../../scrapBins/components/Details/helper';
import './styles.scss';
import { Image } from 'react-bootstrap';
import ComputerIcon from '../assets/computer.png';

export const BIN_OWNER_URL: string =
  process.env.REACT_APP_BIN_OWNER_URL ??
  'https://steelserv-bin-owner.optitrac.com';

export const LoginToBinsButton: React.FC<{ style: React.CSSProperties }> = (
  props,
) => (
  <Link
    className="custom-button login-bins-button mt-3"
    to={BIN_OWNER_URL}
    style={props.style}
  >
    {getBinIconBasedOnLevel(50, 20)}
    <p className="btn-text text-black fw-bold">Log In to Bins Mobile App</p>
  </Link>
);

export const LoginToWebTextAndIcon: React.FC = () => (
  <div className="d-flex">
    <Image src={ComputerIcon} className="me-3" />
    <p className="btn-text text-white fw-bold">Log In to Web</p>
  </div>
);
