import ReactDOM from 'react-dom';
import { Image } from 'react-bootstrap';
import closeIcon from '../assets/icons/close-icon.svg';
import { DetailRow } from './DetailRow';
import { FlyoutParams } from './types';
import './styles.scss';
import { getGeneralBinStatus } from '../utils/helpers';
import { GeneralBinStatus, MODULES, TruckStatusForList } from '../utils/types';
import { getBinFlyoutFields, getTruckFlyoutFields } from './helper';
import {
  getBinIconBasedOnLevel,
  getTruckIconBasedOnStatus,
} from '../components/Details/helper';
import {
  renderBinStatusLabelForBinDetails,
  renderTruckStatusLabel,
} from '../components/Details/renders';

export const BinFlyout = (
  _coordinate: unknown,
  { data, onClose = () => {} }: FlyoutParams,
) => {
  // Destructure the necessary properties from `data`
  const { handleItemSelect, setShowTrackBinModal, setShowSection } = data;

  const div = document.createElement('div');

  const status =
    data.module === MODULES.BINS
      ? getGeneralBinStatus(data.marker)
      : data.marker.availability; // Determine the bin's general status

  const shouldShowTrackBin =
    status === GeneralBinStatus.IN_PROGRESS ||
    status === TruckStatusForList.inProgress;

  const fields =
    data.module === MODULES.BINS
      ? getBinFlyoutFields(data.marker)
      : getTruckFlyoutFields(data.marker);

  const openTrackBinModal = (e: { preventDefault: () => void }) => {
    e.preventDefault(); // Prevent the default action for the anchor tag
    setShowTrackBinModal(true); // Notify parent to show the modal and pass current bin data
  };

  const onViewDetailsClick = (e: { preventDefault: () => void }) => {
    e.preventDefault();
    setShowSection(true);
    handleItemSelect(data.marker);
  };

  const popup = (
    <div className="scrapbins-detail-popup">
      <div className="content">
        <div className="header">
          {data.module === MODULES.BINS
            ? getBinIconBasedOnLevel(data.marker.binLevel, 35)
            : getTruckIconBasedOnStatus(data.marker.status, 35)}
          <div className="bin-title">
            {data.module === MODULES.BINS && 'Bin '} {data.marker.name}
          </div>
        </div>

        {/* Status Label */}
        <div className="my-3">
          {data.module === MODULES.BINS
            ? renderBinStatusLabelForBinDetails(data.marker, true)
            : renderTruckStatusLabel(data.marker.availability)}
        </div>

        {/* All fields */}
        {fields.map(({ label, value }) => (
          <DetailRow
            key={label}
            label={label}
            value={value}
            underlined={false}
          />
        ))}
      </div>

      {/* Links */}
      <div className="action-buttons">
        {shouldShowTrackBin && (
          <a
            href="#track-bin"
            className="action-button track-bin"
            onClick={openTrackBinModal}
          >
            {data.module === MODULES.BINS ? 'Track Bin' : 'Track Current Job'}
          </a>
        )}
        <a
          href="#view-details"
          className="action-button view-details"
          onClick={onViewDetailsClick}
        >
          View Details
        </a>
      </div>
      <div className="popup-tip-container_start_popup" />
    </div>
  );

  ReactDOM.render(popup, div);
  return div;
};
