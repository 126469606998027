import React, { useState } from 'react';
import { Download } from '../../../../../dustControl/components/SubHeader';
import { SubHeaderProps } from './types';
import TimeSelect from '../../../../components/TimeSelect/TimeSelect';
import { DateRangeSelector } from '../../../../../manageUser/UserFilterModal/DateRangeSelector';
import moment from 'moment';
import { DateTimeFormats } from '../../constants';
interface DateRange {
  from: Date;
  to: Date | undefined;
}

const getDefaultDateRangeInString = () => ({
  from: moment().format(DateTimeFormats.DATE),
  to: '',
});

const SubHeader: React.FunctionComponent<SubHeaderProps> = ({
  filterValues,
  loading,
  setFilterValues,
  onDownloadClick,
}) => {
  const [dateRangeObj, setDateRangeObj] = useState<DateRange | undefined>({
    from: new Date(filterValues.dateRange?.from),
    to: undefined,
  });

  const onApplyDateRange = () => {
    const isDateRangeChanged = (newRange: { from: string; to: string }) => {
      return (
        filterValues.dateRange?.from != newRange.from ||
        filterValues.dateRange?.to != newRange.to
      );
    };

    const resetDateRange = dateRangeObj
      ? {
          from: moment(dateRangeObj?.from).format(DateTimeFormats.DATE),
          to:
            dateRangeObj?.to &&
            moment(dateRangeObj.from).format(DateTimeFormats.DATE) !=
              moment(dateRangeObj.to).format(DateTimeFormats.DATE)
              ? moment(dateRangeObj.to).format(DateTimeFormats.DATE)
              : '',
        }
      : getDefaultDateRangeInString();

    console.log(resetDateRange);

    if (isDateRangeChanged(resetDateRange))
      setFilterValues({ ...filterValues, dateRange: resetDateRange });
  };

  const onClear = () => {
    const resetDateRange = getDefaultDateRangeInString();

    const isClearRequired = () => {
      return (
        filterValues.from ||
        filterValues.to ||
        filterValues.dateRange?.from != resetDateRange.from ||
        filterValues.dateRange?.to != resetDateRange.to
      );
    };

    if (isClearRequired()) {
      setFilterValues({ from: '', to: '', dateRange: resetDateRange });
    }
    setDateRangeObj({
      from: new Date(resetDateRange?.from),
      to: undefined,
    });
  };

  return (
    <div className="d-flex align-items-center justify-content-between px-4 py-2">
      <div className="d-flex align-items-center">
        <DateRangeSelector
          dateRange={dateRangeObj}
          setDateRange={setDateRangeObj}
          onClickApply={onApplyDateRange}
          labelText={''}
          leftSafe={true}
          onClear={onClear}
          onCancel={() => {
            console.log({
              from: new Date(filterValues.dateRange?.from),
              to: filterValues.dateRange?.to
                ? new Date(filterValues.dateRange?.to)
                : undefined,
            });
            setDateRangeObj({
              from: new Date(filterValues.dateRange?.from),
              to: filterValues.dateRange?.to
                ? new Date(filterValues.dateRange?.to)
                : undefined,
            });
          }}
        />
        <div className="mx-2">
          <TimeSelect
            disabled={loading}
            value={filterValues.from}
            onChange={(value: any) => setFilterValues({ from: value?.value })}
          />
        </div>
        <div className="mx-2">
          <TimeSelect
            placeholder="11:59 PM"
            disabled={loading}
            value={filterValues.to}
            onChange={(value: any) => setFilterValues({ to: value?.value })}
          />
        </div>
      </div>
      <Download onDownloadClick={onDownloadClick} />
    </div>
  );
};

export default SubHeader;
