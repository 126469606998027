import { Button } from 'react-bootstrap';

const BasicButton = ({
  variant,
  children,
  onClick,
  size,
  name,
  disabled,
  id,
  style,
}) => {
  return (
    <Button
      style={style}
      disabled={disabled}
      name={name}
      size={size}
      onClick={onClick}
      className="rounded-1"
      variant={variant}
      id={id}
    >
      <div
        className=" d-flex align-items-center justify-content-center"
        style={{}}
        name={name}
        id={id}
      >
        {children}
      </div>
    </Button>
  );
};

export default BasicButton;
