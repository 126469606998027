import React from 'react';
import styles from './Tag.module.css';

interface TagProps {
  text: string;
  textColor: string;
  bgColor: string;
  style?: React.CSSProperties;
  className?: string;
}

const Tag: React.FC<TagProps> = ({
  text,
  textColor,
  bgColor,
  style,
  className = '',
}) => {
  return (
    <div
      className={`${styles.tag} ${className}`}
      style={{
        color: textColor,
        backgroundColor: bgColor,
        ...style,
      }}
    >
      {text}
    </div>
  );
};

export default Tag;
