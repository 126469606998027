import { FC, useState, useEffect } from 'react';
import { BinWithPickup, GetAllTrucksData, MODULES } from '../../utils/types';
import TopButton from '../Buttons/TopButton';
import BinFilter from './binFilter';
import TruckFilter from './truckFilter';
import { getFilterData } from '../../utils/helpers';
import { isEmpty } from 'lodash';
import './styles.scss';

type FilterProps = {
  module: string;
  setFilter: any;
  filter: URLSearchParams;
  setModule: (prevState: MODULES) => void;
  truckData: GetAllTrucksData;
  binData: BinWithPickup[];
};

const Filter: FC<FilterProps> = (props) => {
  const [showDropdownField, setShowDropdownField] = useState(false);
  const handleFilterClick = () => setShowDropdownField((prev) => !prev);
  const { binFilterQueries, truckFilterQueries } = getFilterData(props.filter);

  const handleClearFilter = () => {
    setShowDropdownField(false);
    if (props.module === MODULES.BINS) {
      props.setFilter((prev: URLSearchParams) => {
        prev.delete('b');
        return new URLSearchParams(prev);
      });
    } else
      props.setFilter((prev: URLSearchParams) => {
        prev.delete('t');
        return new URLSearchParams(prev);
      });
  };

  //minimize filter dropdown on change pages
  useEffect(() => {
    setShowDropdownField(false);
  }, [props.module]);

  const areFiltersAppliedForThisSection: boolean =
    (props.module === MODULES.BINS && !isEmpty(binFilterQueries)) ||
    (props.module === MODULES.TRUCKS && !isEmpty(truckFilterQueries));

  return (
    <div
      className="ms-auto position-relative"
      style={{
        zIndex: 9,
      }}
    >
      {areFiltersAppliedForThisSection ? (
        <div
          onClick={handleClearFilter}
          className="position-absolute bg-danger rounded-circle"
          style={{
            height: '0.9rem',
            width: '0.9rem',
            top: '-7px',
            right: '-3px',
            cursor: 'pointer',
          }}
        >
          <p className="text-center text-light" style={{ fontSize: '0.6rem' }}>
            x
          </p>
        </div>
      ) : null}
      {/* @ts-ignore */}
      <TopButton
        disabled={false}
        title={MODULES.FILTER}
        img={'filter.png'}
        onClick={() => {
          handleFilterClick();
        }}
        active={showDropdownField}
      />
      {showDropdownField && (
        <div
          className="border-gray-100 rounded shadow filter-menu"
          style={{
            fontSize: '12px',
            marginTop: '0.2rem',
          }}
        >
          <ul
            className="py-1 text-sm text-dark pl-1"
            style={{
              listStyle: 'none',
              margin: '0px',
              paddingLeft: '1rem',
              paddingRight: '0.5rem',
            }}
          >
            {props.module === MODULES.BINS ? (
              <BinFilter
                data={props.binData}
                queries={binFilterQueries}
                setFilter={props.setFilter}
              />
            ) : (
              <TruckFilter
                data={props.truckData}
                queries={truckFilterQueries}
                setFilter={props.setFilter}
              />
            )}
          </ul>
        </div>
      )}
    </div>
  );
};

export default Filter;
