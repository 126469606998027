import React from 'react';
import ReactDOM from 'react-dom';
import source from "../assets/images/source-tooltip-icon.svg";
import close from "../assets/icons/close-icon.svg";
import { Image } from 'react-bootstrap';
import dumpSvg from "../assets/images/point.svg";
import { GeofencePopupDetails } from './GeofencePopupDetails';

export const GeofencePopup = (coordinate, { title, data, onClose = () => { }, getMaterialColor = () => { } }) => {
    const div = document.createElement('div');

    const getExcavators = (loads) => {
        let uniqueExcavatorsMap = {};
        let uniqueExcavators = []

        loads?.map(load => {
            if (load?.contactTitle) {
                if (!uniqueExcavatorsMap[load?.contactTitle]) {
                    uniqueExcavatorsMap[load?.contactTitle] = 0;
                }
                uniqueExcavatorsMap[load?.contactTitle] = uniqueExcavatorsMap[load?.contactTitle] + 1;
            }
        });

        const keys = Object.keys(uniqueExcavatorsMap);
        for (let i = 0; i < keys.length; i++) {
            uniqueExcavators.push({
                name: keys[i],
                loads: uniqueExcavatorsMap[keys[i]]
            });
        }

        return uniqueExcavators
    }

    const getTrucks = (loads) => {
        let uniqueTrucksMap = {};
        let uniqueTrucks = []

        loads?.map(load => {
            if (load?.objectTitle) {
                if (!uniqueTrucksMap[load?.objectTitle]) {
                    uniqueTrucksMap[load?.objectTitle] = 0;
                }
                uniqueTrucksMap[load?.objectTitle] = uniqueTrucksMap[load?.objectTitle] + 1;
            }
        });

        const keys = Object.keys(uniqueTrucksMap);
        for (let i = 0; i < keys.length; i++) {
            uniqueTrucks.push({
                name: keys[i],
                loads: uniqueTrucksMap[keys[i]]
            });
        }

        return uniqueTrucks
    }

    const popup = (
        <div className="detail-popup d-flex flex-column p-1 px-2 d-flex" >
            <div className="d-flex p-0 m-0 justify-content-end">
                <Image onClick={onClose} src={close} style={{ height: "0.8rem" }} />
            </div>
            <div className="d-flex py-2 pt-1 px-1 justify-content-between">
                <div className="d-flex justify-content-start  ">
                    <div className="">
                        <Image src={data?.type === 'source' ? source : dumpSvg} style={{ height: "1rem" }} />
                    </div>
                    <div
                        className="p-0 m-0 pb-1 px-2 text-black color-black"
                        style={{ font: " normal normal 600 0.8rem Open Sans" }}
                    >
                        {data?.name?.toUpperCase()}
                    </div>
                </div>
            </div>
            {
                data.type === "source" ?
                    <GeofencePopupDetails
                        asset={'Excavators'}
                        materials={data.loadMaterials}
                        assets={getExcavators(data.loads)}
                        loads={data.loads}
                        lastActivity={data.lastActivity}
                        getMaterialColor={getMaterialColor}
                    />
                    :
                    <GeofencePopupDetails
                        asset={'Trucks'}
                        materials={data.loadMaterials}
                        assets={getTrucks(data.loads)}
                        loads={data.loads}
                        lastActivity={data.lastActivity}
                        getMaterialColor={getMaterialColor}
                    />
            }
        </div>
    );

    ReactDOM.render(popup, div);
    return div;
};
