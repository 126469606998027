import {
  useCallback,
  useEffect,
  useState,
  useContext,
  ChangeEvent,
  useMemo,
  Fragment,
} from 'react';
import { API_METHODS, ENDPOINTS } from '../../dustControl/utils/constants';
import { FilterQuery, ModalType, engineHourHeader } from '../utils/constant';
import LoadingScreen from '../../dustControl/components/loading';
import { UserContext } from '../../pages/service/userContext';
import * as toast from '../../dustControl/components/toast';
import { SelectedTruck, TruckWithEngineHours } from '../utils/types';
import ActionDropdown from '../components/actionDropdown';
import EditEngineHourModal from '../components/editEngineHourModal';
import { entries, groupBy, keys } from 'lodash';

// styles
import styles from '../maintenance.module.css';
import {
  Category,
  Download,
  Search,
} from '../../dustControl/components/SubHeader';
import { useSearchParams } from 'react-router-dom';
import {
  convertStringToNumArray,
  isCategoryMatch,
  isNameOrDepartmentMatch,
} from '../utils/helper';
import DownLoadModal from '../components/downloadModal';
import moment from 'moment';
import AttentionModal from '../components/attentionModal';

const EngineHour = () => {
  const { ApiHandler } = useContext(UserContext);
  const [modalType, setModalType] = useState<ModalType | null>();
  const [trucks, setTrucks] = useState<TruckWithEngineHours>({});
  const [loading, setLoading] = useState<boolean>(false);
  const [filter, setFilter] = useSearchParams();
  const [selectedTruckName, setSelectedTruckName] = useState<{
    name?: string;
    engineHour?: number;
  }>({});

  const getTruckData = async (controller?: AbortSignal) => {
    try {
      setLoading(true);
      const trucks = await ApiHandler({
        method: API_METHODS.GET,
        endPoint: ENDPOINTS.getSteelServTrucks,
        signal: controller,
      });
      if (trucks.data) {
        const result = groupBy(trucks.data, ({ category }) => category);
        setTrucks(result);
      }
      return '';
    } catch (error: any) {
      toast.error(error?.message);
      return error;
    } finally {
      setLoading(false);
    }
  };

  const refreshData = useCallback(
    async (controller?: AbortSignal, showLoader = true) => {
      const trucksError = await getTruckData(controller);
      if (trucksError) toast.error('Something went wrong');
      showLoader && setLoading(false);
    },
    [],
  );

  useEffect(() => {
    const abortController = new AbortController();
    refreshData(abortController.signal);
    return () => {
      abortController.abort();
    };
  }, [refreshData]);

  const handleOKClick = () => {
    setModalType(ModalType.EDIT);
  };

  const handleDropdownOptions = (selectedTruckData: SelectedTruck) => {
    setSelectedTruckName(selectedTruckData);
    setModalType(ModalType.ATTENTION);
  };

  const closeUpdateEngineHoursModal = (
    updatedTruckData?: Required<SelectedTruck>,
  ) => {
    setSelectedTruckName({});
    setModalType(null);

    // Add updated engine hours to table
    if (updatedTruckData) {
      setTrucks((previousTrucks) => {
        const updatedTrucks = { ...previousTrucks };

        // Iterate over each category of trucks
        for (const category in updatedTrucks) {
          // Update the engine hours for the selected truck if it is in this category
          updatedTrucks[category] = updatedTrucks[category].map((truck) => {
            if (truck.name === updatedTruckData.name) {
              return {
                ...truck,
                currentEngineHour: updatedTruckData.engineHour,
                lastUpdatedTime: new Date().toISOString(),
              };
            }
            return truck;
          });
        }

        return updatedTrucks;
      });
    }
  };
  const getAlignmentBasedOnIndex = (headerIndex: number) => {
    const isLastIndex = engineHourHeader.length - 1 === headerIndex;
    return isLastIndex ? 'right' : 'left';
  };

  const onSearchChange = (event: ChangeEvent<HTMLInputElement>) => {
    // search by asset id.
    const { value } = event.target;
    filter.set(FilterQuery.SEARCH, value);
    setFilter(filter);
  };

  const updatedTrucks = useMemo(() => {
    const searchQuery = filter.get(FilterQuery.SEARCH)?.toLowerCase();
    const categoryQuery = filter.get(FilterQuery.CATEGORY);

    if (!(searchQuery || categoryQuery)) return trucks;

    const categories = convertStringToNumArray(categoryQuery);

    const filteredTrucksByCategory = entries(trucks).reduce(
      (filteredTrucks: TruckWithEngineHours, [category, trucksData]) => {
        const filteredTrucksInCategory = trucksData.filter(
          (truck) =>
            isNameOrDepartmentMatch(truck, searchQuery) &&
            isCategoryMatch(truck, categories),
        );

        if (filteredTrucksInCategory.length) {
          filteredTrucks[category] = filteredTrucksInCategory;
        }

        return filteredTrucks;
      },
      {},
    );

    return filteredTrucksByCategory;
  }, [filter, trucks]);

  const truckCount: number = Object.values(updatedTrucks).reduce(
    (total, truckDataArray) => {
      return total + truckDataArray.length;
    },
    0,
  );

  const onCategoryChange = (selectedCategories: string[]) => {
    filter.set(FilterQuery.CATEGORY, selectedCategories.join(','));
    setFilter(filter);
  };

  const onDownloadClick = () => {
    setModalType(ModalType.DOWNLOAD);
  };

  const handleAttentionModalClose = () => {
    setSelectedTruckName({});
    setModalType(null);
  };

  return (
    <>
      {modalType === ModalType.EDIT && (
        <EditEngineHourModal
          isOpen={modalType === ModalType.EDIT}
          onClose={closeUpdateEngineHoursModal}
          selectedTruck={selectedTruckName}
        />
      )}
      {modalType === ModalType.DOWNLOAD && (
        <DownLoadModal
          isOpen={modalType === ModalType.DOWNLOAD}
          onClose={() => setModalType(null)}
        />
      )}
      {modalType === ModalType.ATTENTION && (
        <AttentionModal
          isOpen={modalType === ModalType.ATTENTION}
          onClose={handleAttentionModalClose}
          handleOKClick={handleOKClick}
        />
      )}

      <div className={styles.subheader_container}>
        <div className="d-flex">
          <Search onChange={onSearchChange} />
          <Category categoryText="categories" onChange={onCategoryChange} />
        </div>
        <Download onDownloadClick={onDownloadClick} />
      </div>

      <div className={styles.engine_hour_table}>
        <table
          cellPadding="0"
          cellSpacing="0"
          width="100%"
          border={0}
          className={styles.sticky_header}
        >
          <thead>
            <tr>
              {engineHourHeader.map((header, index) => (
                <th
                  align={getAlignmentBasedOnIndex(index)}
                  key={header}
                  style={
                    engineHourHeader.length - 1 === index
                      ? { width: '12%' }
                      : { width: '22%' }
                  }
                >
                  {header}
                </th>
              ))}
            </tr>
          </thead>
        </table>
        <table cellPadding="0" cellSpacing="0" width="100%" border={0}>
          <tbody>
            {loading ? (
              <tr>
                <td colSpan={engineHourHeader.length}>
                  <LoadingScreen />
                </td>
              </tr>
            ) : !keys(updatedTrucks).length ? (
              <tr>
                <td>No Data found</td>
              </tr>
            ) : (
              entries(updatedTrucks).map(([category, trucksData], index) => (
                <Fragment key={category}>
                  <tr>
                    <td colSpan={5}>
                      <div className={index === 0 ? '' : 'pt-3'}>
                        <i className="text-muted">{category}</i>
                      </div>
                    </td>
                  </tr>
                  {trucksData.map((truck) => (
                    <tr key={truck.name}>
                      <td align="left" valign="top" style={{ width: '22%' }}>
                        {truck.name}
                      </td>
                      <td align="left" valign="top" style={{ width: '22%' }}>
                        {truck.departmentName}
                      </td>
                      <td align="left" valign="top" style={{ width: '22%' }}>
                        {truck.lastUpdatedTime
                          ? moment(truck.lastUpdatedTime).format(
                              'hh:mm a - DD/MM/YYYY',
                            )
                          : '-'}
                      </td>
                      <td align="left" valign="top" style={{ width: '22%' }}>
                        {truck.currentEngineHour
                          ? `${truck.currentEngineHour} hrs`
                          : '-'}
                      </td>
                      <td align="right" valign="top" style={{ width: '12%' }}>
                        <div className={styles.actionButton}>
                          <ActionDropdown
                            handleDropdownOptions={() =>
                              handleDropdownOptions({
                                name: truck.name,
                                engineHour: truck.currentEngineHour,
                              })
                            }
                          />
                        </div>
                      </td>
                    </tr>
                  ))}
                </Fragment>
              ))
            )}
          </tbody>
        </table>
        {loading ? null : (
          <div className={`${styles.table_footer} text-muted p-2`}>
            Displaying {truckCount} piece{truckCount === 1 ? '' : 's'} of
            equipment
          </div>
        )}
      </div>
    </>
  );
};

export default EngineHour;
