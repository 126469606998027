import {
  FunctionComponent,
  useEffect,
  useContext,
  useState,
  useMemo,
} from 'react';
import {
  DateRange,
  HistoryProps,
  HistoryCategory,
  SelectedFilters,
} from './types';
import { Grid } from '@mui/material';
import { HistoryFlyer } from './flyer';
import { BinHistory } from '../../utils/types';
import { UserContext } from '../../../pages/service/userContext';
import './styles.css';
import FilterSubheader from './FilterSubheader';
import { Routes } from '../../utils/routes';
import { formatDate } from '../../utils/helper';
import { API_METHODS, defaultCustomButtonStyles } from '../../utils/constants';
import {
  appendBinHistoriesToState,
  emptyFilters,
  filterAndSearchHistories,
  getInitialDateRangeForFilter,
} from './helper';
import * as toast from '../../../dustControl/components/toast';
import { getErrorMessage } from '../../utils/helpers';
import PopupModal from '../popUp';
import CustomButton from '../../../dustControl/components/CustomButton';
import HistoryTable from './HistoryTable';
import HistoryFooter from './historyFooter';

export const History: FunctionComponent<HistoryProps> = (
  props: HistoryProps,
) => {
  const { ApiHandler } = useContext(UserContext);
  const [historyCategory, setHistoryCategory] = useState<HistoryCategory>(
    HistoryCategory.BINS,
  );
  const [isHistoryFlyerOpen, setIsHistoryFlyerOpen] = useState<boolean>(true);
  const [isDownloadModalOpen, setIsDownloadModalOpen] =
    useState<boolean>(false);
  const [searchText, setSearchText] = useState<string>('');
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [binHistoriesFilterTimePeriod, setBinHistoriesFilterTimePeriod] =
    useState<DateRange>(getInitialDateRangeForFilter);
  const [binHistories, setBinHistories] = useState<BinHistory[]>([]);
  const [selectedFilters, setSelectedFilters] =
    useState<SelectedFilters>(emptyFilters);

  useEffect(() => {
    const getBinHistory = async (): Promise<void> => {
      try {
        setIsLoading(true);
        setBinHistories([]);
        const formattedStartDate: string = formatDate(
          binHistoriesFilterTimePeriod.from,
        );
        const formattedEndDate: string = formatDate(
          binHistoriesFilterTimePeriod.to ?? binHistoriesFilterTimePeriod.from,
        );
        const getBinHistoryResponse = await ApiHandler({
          endPoint: `${Routes.GET_BINS_HISTORY}?startDate=${formattedStartDate}&endDate=${formattedEndDate}`,
          method: API_METHODS.GET,
        });
        appendBinHistoriesToState(setBinHistories, getBinHistoryResponse.data);
        setIsLoading(false);
      } catch (error: unknown) {
        toast.error(getErrorMessage(error));

        setIsLoading(false);
      }
    };
    getBinHistory();
  }, [binHistoriesFilterTimePeriod, ApiHandler]);

  const handleHistoryFlyerToggle = (showHistoryFlyer: boolean): void => {
    setIsHistoryFlyerOpen(showHistoryFlyer);
  };

  const handleDownloadClick = (): void => {
    setIsDownloadModalOpen(true);
  };

  const filteredHistories: BinHistory[] = useMemo(
    () => filterAndSearchHistories(binHistories, searchText, selectedFilters),
    [binHistories, searchText, selectedFilters],
  );

  return (
    <Grid
      container
      spacing={isHistoryFlyerOpen ? 2 : 1}
      direction={'row'}
      className="history-grid-container px-3"
    >
      <Grid item xs={isHistoryFlyerOpen ? 3 : 0.5}>
        <HistoryFlyer
          date={binHistoriesFilterTimePeriod}
          historyCategory={historyCategory}
          showHistoryFlyer={isHistoryFlyerOpen}
          setShowHistoryFlyer={handleHistoryFlyerToggle}
          binHistories={binHistories}
        />
      </Grid>
      <Grid
        item
        className={'history-section'}
        xs={isHistoryFlyerOpen ? 9 : 11.5}
      >
        <FilterSubheader
          selectedSection={historyCategory}
          setSelectedSection={setHistoryCategory}
          dateRange={binHistoriesFilterTimePeriod}
          setSelectedDateRange={setBinHistoriesFilterTimePeriod}
          onSearchChange={setSearchText}
          onDownloadClick={() => setIsDownloadModalOpen(true)}
          selectedFilters={selectedFilters}
          setSelectedFilters={setSelectedFilters}
          binHistories={binHistories}
        />
        <hr />
        <HistoryTable
          loading={isLoading}
          data={filteredHistories}
          historyCategory={historyCategory}
          trucks={props.trucks}
          onDownloadClick={handleDownloadClick}
        />
      </Grid>

      <HistoryFooter
        binHistories={binHistories}
        date={binHistoriesFilterTimePeriod}
      />

      <PopupModal
        isOpen={isDownloadModalOpen}
        onClose={() => setIsDownloadModalOpen(false)}
      >
        <div className="m-5">Download functionality coming soon</div>
        <CustomButton
          id="downloadButton"
          style={defaultCustomButtonStyles}
          loading={false}
          title={'OK'}
          onClick={() => setIsDownloadModalOpen(false)}
        />
      </PopupModal>
    </Grid>
  );
};

export default History;
