import {
  BinWithPickup,
  DetailedBinStatus,
  GeneralBinStatus,
  TruckData,
  TruckStatusForList,
  ZeroPercent,
} from './types';

import { TrackBinModal } from '../components/TrackBin';
import CancelPickUp from '../components/popUp/cancelPickup';
import RequestPickup, { Modal } from '../components/popUp/requestPickup';
import { getGeneralBinStatus } from './helpers';
import { UpdateHazardsModal } from '../components/AddHazards/AddHazardModal';
import { UpdateLevelModal } from '../components/UpdateLevel/UpdateLevel';
import { HTMLAttributes } from 'react';

export const API_METHODS = {
  GET: 'get',
  POST: 'post',
  PUT: 'put',
  PATCH: 'patch',
  DELETE: 'delete',
};

export const BIN = 'bin';
export const BINS = 'bins';
export const NA = 'N/A';

export const TRUCK = 'truck';
export const TRUCKS = 'trucks';

export const detailedBinStatusColors: {
  [status in DetailedBinStatus]: string;
} = {
  'pick-up-scheduled': '#C3F3F5',
  'picked-up': '#E4FAA7',
  dumped: '#D3D5FC',
  'job-complete': '#C7F5C3',
  'back-at-source': '#E2E2E2',
  'job-incomplete': '#FFDAD3',
  'bin-missing': '#FED3DF',
  'pick-up-requested': '#FFDAD3',
  'status-unavailable': '#E2E2E2',
};

export const generalBinStatusColors: {
  [status in GeneralBinStatus]: string;
} = {
  [GeneralBinStatus.EMPTY]: '#7B7B7B',
  [GeneralBinStatus.TWENTY_FIVE]: '#1D988E',
  [GeneralBinStatus.FIFTY]: '#7A9A49',
  [GeneralBinStatus.SEVENTY_FIVE]: '#C6770D',
  [GeneralBinStatus.MISSING]: '#BF1B80',
  [GeneralBinStatus.HUNDRED]: '#BF3636',
  [GeneralBinStatus.REQUESTED]: '#952B2B',
  [GeneralBinStatus.IN_PROGRESS]: '#1947C4',
  [GeneralBinStatus.RUBBISH]: '#BF1B80',
};

export const generalBinStatusColorsTitles: {
  [status in GeneralBinStatus]: string;
} = {
  [GeneralBinStatus.EMPTY]: '#7B7B7B',
  [GeneralBinStatus.TWENTY_FIVE]: '#1D988E',
  [GeneralBinStatus.FIFTY]: '#7A9A49',
  [GeneralBinStatus.SEVENTY_FIVE]: '#C6770D',
  [GeneralBinStatus.MISSING]: '#BF1B80',
  [GeneralBinStatus.HUNDRED]: '#BF3636',
  [GeneralBinStatus.REQUESTED]: '#FFF7B9',
  [GeneralBinStatus.IN_PROGRESS]: '#1947C4',
  [GeneralBinStatus.RUBBISH]: '#BF1B80',
};

export const generalBinStatusBackgroundColors: {
  [status in GeneralBinStatus]: string;
} = {
  [GeneralBinStatus.EMPTY]: '#EDEDED',
  [GeneralBinStatus.TWENTY_FIVE]: '#F5FFFD',
  [GeneralBinStatus.FIFTY]: '#FBFFF2',
  [GeneralBinStatus.SEVENTY_FIVE]: '#FFFAF2',
  [GeneralBinStatus.MISSING]: '#FFEBF4',
  [GeneralBinStatus.HUNDRED]: '#FFF5F5',
  [GeneralBinStatus.REQUESTED]: '#952B2B',
  [GeneralBinStatus.IN_PROGRESS]: '#F4F9FF',
  [GeneralBinStatus.RUBBISH]: '#F8F4FF',
};

export const truckStatusColors: {
  [status in TruckStatusForList]: string;
} = {
  [TruckStatusForList.Workshop]: '#BF3636',
  [TruckStatusForList.standby]: '#C6770D',
  [TruckStatusForList.inProgress]: '#3D63C9',
};

export const truckBackgrounsColors: {
  [status in TruckStatusForList]: string;
} = {
  [TruckStatusForList.Workshop]: '#FFEBF4',
  [TruckStatusForList.standby]: '#FFFAF2',
  [TruckStatusForList.inProgress]: '#F4F9FF',
};

export enum BinDetailsLabel {
  BIN_LEVEL = 'Bin Level:',
  LAST_SEEN_LOCATION = 'Last Seen Location:',
  SCRAP_TYPE = 'Scrap Type:',
  TRUCK = 'Truck:',
  LAST_EMPTIED = 'Last Emptied (LE):',
  LAST_UPDATED = 'Last Updated (LU):',
  HAZARDS = 'Hazards:',
  PICKUP_POINT = 'Pickup Point:',
  DUMP_POINT = 'Dump Point:',
  DROP_OFF_POINT = 'Drop-Off Point:',
}

export enum BinHistoryFlyerSection {
  BIN_SCRAP_LOADS = 'Bin/Scrap Loads',
  BIN_JOBS = 'Bin Jobs',
  JOB_STATUS = 'Job Status',
}

export enum TruckHistoryFlyerSection {
  TRUCK_JOBS = 'Truck Jobs',
  COMPLETED_JOBS = 'Completed Jobs',
  INCOMPLETE_JOBS = 'Incomplete Jobs',
  SCHEDULED_JOBS = 'Scheduled Jobs',
  UNSCHEDULED_JOBS = 'Unscheduled Jobs',
  DELAYS = 'Delays (h)',
}

export const detailedBinStatusLabels: {
  [status in DetailedBinStatus]: string;
} = {
  'pick-up-scheduled': 'Pick-up Scheduled',
  'picked-up': 'Picked-Up',
  dumped: 'Dumped',
  'job-complete': 'Job Complete',
  'back-at-source': 'Back at Source',
  'job-incomplete': 'Job Incomplete',
  'bin-missing': 'Bin Missing',
  'pick-up-requested': 'Pick-up Requested',
  'status-unavailable': 'Status unavailable',
};

/*
  TODO: The components for different bin actions need to be added. 
  Making the components accept the same type of props would make it easier
  to dynamically load this into the Ellipsis options.
*/
export const BUTTON_ACTION = {
  UPDATE_LEVEL: {
    action: 'Update Level',
    component: (
      bin: BinWithPickup,
      showComponent: boolean,
      onClose: () => void,
    ) => (
      <UpdateLevelModal bin={bin} isOpen={showComponent} onClose={onClose} />
    ),
  },
  TRACK_BIN: {
    action: 'Track Bin',
    component: (
      bin: BinWithPickup,
      showComponent: boolean,
      onClose: () => void,
    ) => (
      <TrackBinModal
        binName={bin.name}
        isOpen={showComponent}
        onClose={() => onClose()}
      />
    ),
  },
  UPDATE_HAZARDS: {
    action: 'Update Hazards',
    component: (
      bin: BinWithPickup,
      showComponent: boolean,
      onClose: () => void,
    ) => (
      <UpdateHazardsModal bin={bin} isOpen={showComponent} onClose={onClose} />
    ),
  },
  REQUEST_PICKUP: {
    action: 'Request Pickup',
    component: (
      bin: BinWithPickup,
      showComponent: boolean,
      onClose: () => void,
    ) => (
      <RequestPickup
        initialModal={
          getGeneralBinStatus(bin) === GeneralBinStatus.MISSING
            ? Modal.MISSING_OR_EDIT_REQUEST
            : Modal.CONFIRM
        }
        bin={bin}
        isOpen={showComponent}
        onClose={() => onClose()}
      />
    ),
  },
  CANCEL_PICKUP: {
    action: 'Cancel Pickup Request',
    component: (
      bin: BinWithPickup,
      showComponent: boolean,
      onClose: () => void,
    ) => <CancelPickUp bin={bin} isOpen={showComponent} onClose={onClose} />,
  },
  VIEW_PICKUP_REQUEST: {
    action: 'View Pickup Request',
    component: (
      bin: BinWithPickup,
      showComponent: boolean,
      onClose: () => void,
    ) => (
      <RequestPickup
        initialModal={Modal.VIEW_REQUEST}
        bin={bin}
        isOpen={showComponent}
        onClose={onClose}
      />
    ),
  },
  LAST_JOBS_SUMMARY: {
    action: "Last Job's Summary",
    component: (
      bin: BinWithPickup,
      showComponent: boolean,
      onClose: () => void,
    ) => (
      <TrackBinModal
        binName={bin.name}
        isOpen={showComponent}
        onClose={() => onClose()}
      />
    ),
  },
};

/*
 The action that can be performed on a bin depends on its current status.
 The below function returns the available actions for a bin based on its current status
*/
export const getBinActionsByStatus = (
  binStatus: DetailedBinStatus,
  isBinMissing: boolean,
) => {
  switch (binStatus) {
    case DetailedBinStatus.PICK_UP_SCHEDULED:
      return [
        BUTTON_ACTION.UPDATE_LEVEL,
        BUTTON_ACTION.UPDATE_HAZARDS,
        BUTTON_ACTION.TRACK_BIN,
      ];
    case DetailedBinStatus.PICKED_UP:
      return [BUTTON_ACTION.UPDATE_HAZARDS, BUTTON_ACTION.TRACK_BIN];
    case DetailedBinStatus.DUMPED:
      return [BUTTON_ACTION.UPDATE_HAZARDS, BUTTON_ACTION.TRACK_BIN];
    case DetailedBinStatus.JOB_COMPLETE:
      return [
        BUTTON_ACTION.REQUEST_PICKUP,
        BUTTON_ACTION.UPDATE_LEVEL,
        BUTTON_ACTION.UPDATE_HAZARDS,
        BUTTON_ACTION.LAST_JOBS_SUMMARY,
      ];
    case DetailedBinStatus.BACK_AT_SOURCE:
      return [
        BUTTON_ACTION.REQUEST_PICKUP,
        BUTTON_ACTION.UPDATE_LEVEL,
        BUTTON_ACTION.UPDATE_HAZARDS,
        BUTTON_ACTION.LAST_JOBS_SUMMARY,
      ];
    case DetailedBinStatus.JOB_INCOMPLETE:
      return [
        BUTTON_ACTION.REQUEST_PICKUP,
        BUTTON_ACTION.UPDATE_LEVEL,
        BUTTON_ACTION.UPDATE_HAZARDS,
        BUTTON_ACTION.TRACK_BIN,
      ];
    case DetailedBinStatus.BIN_MISSING:
      return [
        BUTTON_ACTION.REQUEST_PICKUP,
        BUTTON_ACTION.UPDATE_LEVEL,
        BUTTON_ACTION.UPDATE_HAZARDS,
        BUTTON_ACTION.LAST_JOBS_SUMMARY,
      ];
    case DetailedBinStatus.PICK_UP_REQUESTED:
      return [
        BUTTON_ACTION.CANCEL_PICKUP,
        ...(isBinMissing
          ? [BUTTON_ACTION.VIEW_PICKUP_REQUEST, BUTTON_ACTION.UPDATE_LEVEL]
          : [BUTTON_ACTION.UPDATE_LEVEL, BUTTON_ACTION.VIEW_PICKUP_REQUEST]),
        BUTTON_ACTION.UPDATE_HAZARDS,
        BUTTON_ACTION.LAST_JOBS_SUMMARY,
      ];
    case DetailedBinStatus.STATUS_UNAVAILABLE:
      return [];
  }
};

export const truckColorMapping: { [key: string]: string } = {
  Active: generalBinStatusColors['25%'],
  Idle: generalBinStatusColors.Missing,
  Parked: generalBinStatusColors.Empty,
  'Job In Progress': generalBinStatusColors['In Progress'],
  Standby: generalBinStatusColors['75%'],
  Workshop: generalBinStatusColors['100%'],
};

export const truckStatusLightColorMap: {
  [key in TruckData['status']]: string;
} = {
  Active: '#6CEAC3',
  Idle: '#FF9A9A',
  Parked: '#F2F2F2',
};

export const BIN_FILTERS = [
  { id: 0, name: 'Show All', value: 'Show All' },
  {
    id: 1,
    name: GeneralBinStatus.IN_PROGRESS,
    color: generalBinStatusColors['In Progress'],
  },
  {
    id: 2,
    name: GeneralBinStatus.REQUESTED,
    color: generalBinStatusColors.Requested,
  },
  {
    id: 3,
    name: GeneralBinStatus.HUNDRED,
    color: generalBinStatusColors['100%'],
  },
  {
    id: 4,
    name: GeneralBinStatus.SEVENTY_FIVE,
    color: generalBinStatusColors['75%'],
  },
  {
    id: 5,
    name: GeneralBinStatus.FIFTY,
    color: generalBinStatusColors['50%'],
  },
  {
    id: 6,
    name: GeneralBinStatus.TWENTY_FIVE,
    color: generalBinStatusColors['25%'],
  },
  {
    id: 7,
    name: GeneralBinStatus.EMPTY,
    color: generalBinStatusColors.Empty,
    value: GeneralBinStatus.EMPTY,
  },
  {
    id: 8,
    name: GeneralBinStatus.MISSING,
    color: generalBinStatusColors.Missing,
    value: GeneralBinStatus.MISSING,
  },
];
export const TRUCK_FILTERS = [
  { id: 0, name: 'Truck Status', value: 'Truck Status' },
  {
    id: 1,
    name: 'Active',
    color: truckColorMapping.Active,
    value: 'Active',
  },
  { id: 2, name: 'Idle', color: truckColorMapping.Idle, value: 'Idle' },
  {
    id: 3,
    name: 'Parked',
    color: truckColorMapping.Parked,
    value: 'Parked',
  },
  { id: 4, name: 'Truck Availability', value: 'Truck Availability' },
  {
    id: 5,
    name: 'Job In Progress',
    color: truckColorMapping['Job In Progress'],
    value: 'inProgress',
  },
  {
    id: 6,
    name: 'Standby',
    color: truckColorMapping.Standby,
    value: 'standby',
  },
  {
    id: 7,
    name: 'Workshop',
    color: truckColorMapping.Workshop,
    value: 'Workshop',
  },
];

export const TRUCK_FILTER_VALUES: { [key: string]: string[] } = {
  'Truck Status': ['Active', 'Idle', 'Parked', 'Truck Status'],
  'Truck Availability': [
    'inProgress',
    'standby',
    'Workshop',
    'Truck Availability',
  ],
};

export const MAP_AREA_LATITUDE_MIN: number = -37.218007;
export const MAP_AREA_LATITUDE_MAX: number = -37.201606;
export const MAP_AREA_LONGITUDE_MIN: number = 174.714542;
export const MAP_AREA_LONGITUDE_MAX: number = 174.747048;

export const SCRAP_TYPES = [];
export const DUMP_POINTS = [];
export const DROPOFF_POINT = [];
export const BIN_LEVEL = [
  { value: 100, name: GeneralBinStatus.HUNDRED },
  { value: 75, name: GeneralBinStatus.SEVENTY_FIVE },
  { value: 50, name: GeneralBinStatus.FIFTY },
  { value: 25, name: GeneralBinStatus.TWENTY_FIVE },
  { value: 0, name: ZeroPercent.ZERO },
];

export const PERCENTAGE_TO_NUMBER_MAP = {
  [GeneralBinStatus.HUNDRED]: 100,
  [GeneralBinStatus.SEVENTY_FIVE]: 75,
  [GeneralBinStatus.FIFTY]: 50,
  [GeneralBinStatus.TWENTY_FIVE]: 25,
  [ZeroPercent.ZERO]: 0,
};

export const SOCKETS = {
  bins: 'updatedBinList',
  trucks: 'updateTruckList',
  updateOperator: 'updateOperator',
  pickupUpdate: 'pickupStatusChange',
};

export const defaultCustomButtonStyles: HTMLAttributes<HTMLDivElement>['style'] =
  {
    background: 'black',
    paddingLeft: '2rem',
    paddingRight: '2rem',
    width: 'fit-content',
    alignSelf: 'center',
  };
