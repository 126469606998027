import { useEffect, useState } from 'react';
import moment from 'moment';
import DownLoadModal from '../../../maintenance/components/downloadModal';
import { ModalType } from '../../../maintenance/utils/constant';
import SubHeader from './subComponents/subHeader/subHeader';
import ShiftLoaderProgressBar from './subComponents/shiftLoaderProgress/shiftLoaderProgressBar';
import ShiftLoaderGraph from './subComponents/shiftLoaderGraph';
import ShiftLoaderTable from './subComponents/shiftLoadertTable/shiftLoaderTable';
import LoadCountdownTimer from './subComponents/loadCountdownTimer/loadCountdownTimer';
import { API_METHODS } from '../../../dustControl/utils/constants';
import * as toast from '../../../dustControl/components/toast';
import {
  ShiftLoaderData,
  FilterValues,
  Load,
  UnprocessedShiftLoaderData,
  DateRangeDetails,
} from './types';
import {
  getDateRangeDetails,
  getDateRangeType,
  getUnixTimestampsFromFilter,
  isShiftLoadWithinTimeRangeOfFilters,
  typeCorrectedLoadsAfterFetching,
} from './utils';
import { DateTimeFormats } from './constants';
import { Endpoint, getUrl } from './helper';
import { fetch } from '../../../utils/api';
import { useNavigate } from 'react-router-dom';
import TrafficLight from './subComponents/TrafficLight';
import { ShiftLoaderDateRangeType } from '../../utils/constants';
import { WebSocketURL } from '../../../utils/constants';

const ShiftLoader = () => {
  const [dateRangeDetails, setDateRangeDetails] = useState<DateRangeDetails>({
    startHourUnix: 0,
    startTimeUnix: 0,
    endTimeUnix: 0,
    startDayUnix: 0,
    endDayUnix: 0,
    startMonthUnix: 0,
    endMonthUnix: 0,
    days: 0,
    hours: 0,
    months: 0,
  });
  const [dateRangeType, setDateRangeType] = useState(
    ShiftLoaderDateRangeType.CURRENT_DAY,
  );
  const [modalType, setModalType] = useState<ModalType | null>();
  const [isLoading, setIsLoading] = useState(false);
  const [isGraphHidden, setIsGraphHidden] = useState(false);
  const [newLoad, setNewLoad] = useState<Load>();
  const [filterValues, setFilterValues] = useState<FilterValues>({
    dateRange: {
      to: '',
      from: moment().format(DateTimeFormats.DATE),
    },
    from: '',
    to: '',
  });
  const [shiftLoaderPresentDayData, setShiftLoaderPresentDayData] =
    useState<ShiftLoaderData>({
      loads: [],
      totalLoadCount: 0,
      totalTonnes: 0,
    });
  const [shiftLoaderfilteredData, setShiftLoaderfilteredData] =
    useState<ShiftLoaderData>({
      loads: [],
      totalLoadCount: 0,
      totalTonnes: 0,
    });
  const navigate = useNavigate();

  // Handler for download button click
  const onDownloadClick = () => setModalType(ModalType.DOWNLOAD);

  // Function to fetch shift loader data
  const getShiftLoaderData = async (
    updatePresentDayData = false, // only true on first load, when useEffect calls the API
    filters: FilterValues,
    controller?: AbortSignal,
  ) => {
    try {
      const params = getUnixTimestampsFromFilter(filters);

      // Validate date range
      if (params.fromDate >= params.toDate) {
        setShiftLoaderfilteredData({
          loads: [],
          totalLoadCount: 0,
          totalTonnes: 0,
        });
        toast.error(
          'Invalid date range: from date must be before to date',
          true,
        );
        return;
      }
      setIsLoading(true);

      const response = await fetch<UnprocessedShiftLoaderData>({
        method: API_METHODS.GET,
        endPoint: getUrl(Endpoint.GetLoads),
        signal: controller,
        reqParams: params,
        navigateToLoginPage: () => navigate('/'),
      });

      // Process and set fetched data
      const processedData: ShiftLoaderData = typeCorrectedLoadsAfterFetching(
        response.data,
      );

      setShiftLoaderfilteredData(processedData);
      if (updatePresentDayData) {
        setShiftLoaderPresentDayData(processedData);
      }
    } catch (error: any) {
      toast.error(error?.message);
    } finally {
      setIsLoading(false);
    }
  };

  // Handler to apply filters
  const handleApplyFilters = (updatedFilter: Partial<FilterValues>) => {
    setDateRangeType(getDateRangeType({ ...filterValues, ...updatedFilter }));
    setDateRangeDetails(
      getDateRangeDetails({ ...filterValues, ...updatedFilter }),
    );
    setFilterValues((prev) => ({ ...prev, ...updatedFilter }));
    getShiftLoaderData(false, { ...filterValues, ...updatedFilter });
  };

  // fetch initial data on component mount
  useEffect(() => {
    const abortController = new AbortController();

    const PRESENT_DAY_FILTER = {
      dateRange: { from: moment().format(DateTimeFormats.DATE), to: '' },
      from: '',
      to: '',
    };
    setDateRangeType(getDateRangeType(filterValues));
    setDateRangeDetails(getDateRangeDetails(filterValues));

    getShiftLoaderData(true, PRESENT_DAY_FILTER, abortController.signal);

    return () => {
      abortController.abort();
    };
  }, []);

  //  handle WebSocket connections and Loads update
  useEffect(() => {
    const socketUrl = WebSocketURL + 'shift_loader_loads';
    const socket = new WebSocket(socketUrl);
    let heartbeatInterval: NodeJS.Timeout;

    socket.onopen = () => {
      console.log('WebSocket connection opened for shift loader loads');
      heartbeatInterval = setInterval(() => {
        if (socket.readyState === WebSocket.OPEN) {
          socket.send('heartbeat');
          console.log('Heartbeat sent');
        }
      }, 25000);
    };

    socket.onmessage = (event: MessageEvent) => {
      const message = event?.data;
      console.log('WebSocket message received:', message);
      const load: Load = JSON.parse(message);

      //for Timer
      setShiftLoaderPresentDayData((prevData: any) => ({
        loads: [
          {
            startTime: new Date(load.startTime),
            endTime: new Date(load.endTime),
            material: load.material,
            tonnes: load.tonnes,
            loadNumber: prevData.loads.length + 1,
          },
          ...prevData.loads,
        ],
        totalLoadCount: prevData.totalLoadCount + 1,
        totalTonnes: prevData.totalTonnes + load.tonnes,
      }));

      //for Table and Graph
      if (isShiftLoadWithinTimeRangeOfFilters(filterValues, load)) {
        setShiftLoaderfilteredData((prevData) => ({
          loads: [
            {
              startTime: new Date(load.startTime),
              endTime: new Date(load.endTime),
              material: load.material,
              tonnes: load.tonnes,
              loadNumber: prevData.loads.length + 1,
            },
            ...prevData.loads,
          ],
          totalLoadCount: prevData.totalLoadCount + 1,
          totalTonnes: prevData.totalTonnes + load.tonnes,
        }));
        setNewLoad({
          startTime: new Date(load.startTime),
          endTime: new Date(load.endTime),
          material: load.material,
          tonnes: load.tonnes,
          loadNumber: 0,
        });
      }
    };

    socket.onerror = (error: Event) => {
      console.error('WebSocket error:', error);
    };

    socket.onclose = () => {
      console.log('WebSocket connection closed for pushes');
      clearInterval(heartbeatInterval);
    };

    return () => {
      if (heartbeatInterval) {
        clearInterval(heartbeatInterval);
      }
      socket.close();
    };
  }, [filterValues]);

  return (
    <div className="d-flex flex-column bg-light h-100 overflow-auto">
      {modalType === ModalType.DOWNLOAD && (
        <DownLoadModal
          isOpen={modalType === ModalType.DOWNLOAD}
          onClose={() => setModalType(null)}
        />
      )}
      <SubHeader
        filterValues={filterValues}
        setFilterValues={handleApplyFilters}
        onDownloadClick={onDownloadClick}
        loading={isLoading}
      />
      <div
        className={isGraphHidden ? 'overflow-hidden' : 'px-4 mb-3'}
        style={{
          transition: 'all 0.5s ease',
          height: isGraphHidden ? 0 : 'max-content',
        }}
      >
        <div className={'d-flex flex-lg-row flex-sm-column mb-3'}>
          <div style={{ maxWidth: 460 }} className="col-lg-4 col-sm-12 pe-lg-3">
            <ShiftLoaderProgressBar
              loading={isLoading}
              totalLoadCount={shiftLoaderfilteredData.totalLoadCount}
              totalTonnes={shiftLoaderfilteredData.totalTonnes}
              takeFullHeight={
                dateRangeType > ShiftLoaderDateRangeType.CURRENT_DAY_WITH_RANGE
              }
            />
            {dateRangeType <=
              ShiftLoaderDateRangeType.CURRENT_DAY_WITH_RANGE && (
              <LoadCountdownTimer data={shiftLoaderPresentDayData} />
            )}
          </div>
          <div className="flex-grow-1 bg-white">
            <ShiftLoaderGraph
              newLoad={newLoad}
              dateRangeDetails={dateRangeDetails}
              dateRangeType={dateRangeType}
              date={filterValues.dateRange.from}
              loading={isLoading}
              filterValues={filterValues}
            />
          </div>
        </div>
        <TrafficLight
          dateRangeDetails={dateRangeDetails}
          dateRangeType={dateRangeType}
          filterValues={filterValues}
        />
      </div>

      <ShiftLoaderTable
        expand={() => setIsGraphHidden(!isGraphHidden)}
        isFullSize={isGraphHidden}
        data={shiftLoaderfilteredData.loads}
        loading={isLoading}
        filterValues={filterValues}
      />
    </div>
  );
};

export default ShiftLoader;
