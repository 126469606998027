import { PieChartLegendData, PieChartSubscriber } from './types';
import { GoDotFill } from 'react-icons/go';
import { Grid } from '@mui/material';

export const renderLegendDataForPieChart = (
  piechartLegendData: PieChartLegendData[],
  colours: string[],
  subscriber?: PieChartSubscriber,
): JSX.Element => {
  return (
    <div
      className={
        piechartLegendData.length > 4 ? 'legend-div-scrollable' : 'legend-div'
      }
    >
      <div className="vertically-centered-div">
        {piechartLegendData.map(
          (legendRow: PieChartLegendData, index: number) => (
            <Grid container item xs={12} rowSpacing={2}>
              <Grid item xs={11} textAlign={'left'}>
                <GoDotFill size={16} className="mr-2" color={colours[index]} />
                <span className="legend-key mr-4">{legendRow.key}</span>
              </Grid>

              <Grid xs={1} item textAlign={'right'}>
                <span className="legend-value hoffset">{legendRow.value}</span>
              </Grid>
            </Grid>
          ),
        )}
      </div>
    </div>
  );
};
