import { FC } from 'react';
import { BinWithPickup } from '../../../utils/types';
import PopupModal from '..';
import CustomButton from '../../../../dustControl/components/CustomButton';
type RequestPickupProps = {
  isOpen: boolean;
  onClose: () => void;
  bin: BinWithPickup;
  handleEditClick: () => void;
};
const ViewRequestpickup: FC<RequestPickupProps> = (props) => {
  const requestPickupRequest = [
    {
      title: 'Last Seen Location',
      value: props.bin.lastPickup?.events.at(-1)?.zone ?? '-',
    },
    {
      title: 'Pickup Point',
      value: props.bin.pickupPoint,
    },
    {
      title: 'Dump Required?',
      value: props.bin.pickupRequest?.dropoffOnly ? 'No' : 'Yes',
    },
    {
      title: 'Scrap Type',
      value: props.bin.scrapType,
    },
    {
      title: 'Dump Point',
      value: props.bin.dumpPoint,
    },
    {
      title: 'Drop-Off Point',
      value: props.bin.dropoffPoint,
    },
  ];

  return (
    <PopupModal
      isOpen={props.isOpen}
      onClose={props.onClose}
      header={`Pickup Request - Bin ${props.bin.name}`}
    >
      <div
        className=" d-flex flex-column  gap-3 justify-content-evenly align-content-center"
        style={{ width: '500px' }}
      >
        <div className="d-flex flex-column gap-3">
          {requestPickupRequest.map(({ title, value }) => (
            <div
              className="d-flex flex-row justify-content-between"
              style={{ textAlign: 'left' }}
            >
              <p style={{ flex: '0.5' }}>{title}</p>
              <p className="fw-bold" style={{ flex: 1 }}>
                {value}
              </p>
            </div>
          ))}
        </div>
      </div>

      <CustomButton
        style={{
          background: 'black',
          paddingLeft: '2rem',
          paddingRight: '2rem',
          marginTop: '2rem',
          width: 'fit-content',
        }}
        loading={false}
        id="EditButton"
        title={'Edit'}
        onClick={props.handleEditClick}
      />
    </PopupModal>
  );
};
export default ViewRequestpickup;
