import React from 'react';
import './styles.scss';

const LabelWithLine = ({ title }) => {
  return (
    <div className="d-flex align-items-end mt-4">
      <div className="bin_label_with_line-title">{title}</div>
      <div className="flex-grow-1 bin_label_with_line-border "></div>
    </div>
  );
};

export default LabelWithLine;
