import {
  Context,
  FunctionComponent,
  PropsWithChildren,
  createContext,
} from 'react';
import io, { Socket } from 'socket.io-client';
import { SocketContextType } from './types';

const connection: string = '/';

const socket: Socket = io(connection, {
  query: {
    room: 'admin',
  },
});
export const SocketContext: Context<SocketContextType> = createContext({
  socket,
});
export const SocketProvider: FunctionComponent<PropsWithChildren> = (props) => {
  return (
    <SocketContext.Provider value={{ socket }}>
      {props.children}
    </SocketContext.Provider>
  );
};
