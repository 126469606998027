import { BinWithPickup } from '../../../utils/types';
import PopupModal from '..';
import CustomButton from '../../../../dustControl/components/CustomButton';
import { FC } from 'react';

type ConfirmRequestProps = {
  handleConfirmClick: () => Promise<void>;
  isOpen: boolean;
  onClose: () => void;
  bin: BinWithPickup;
  loading: boolean;
};
const ConfirmRequest: FC<ConfirmRequestProps> = (props) => {
  return (
    <PopupModal isOpen={props.isOpen} onClose={props.onClose} width={'450px'}>
      <div
        className=" d-flex flex-column  gap-4 justify-content-evenly align-content-center my-3"
        style={{
          maxWidth: '240px',
          marginLeft: 'auto',
          marginRight: 'auto',
          cursor: '',
        }}
      >
        <h6 className="fw-bolder ">Request Pickup - Bin {props.bin.name}</h6>
        <p>Are you sure you want to proceed with the pickup request?</p>
        <CustomButton
          style={{
            background: 'black',
            paddingLeft: '2rem',
            paddingRight: '2rem',
            width: 'fit-content',
            alignSelf: 'center',
          }}
          loading={props.loading}
          id="confirmButton"
          title={'Confirm'}
          onClick={props.handleConfirmClick}
        />
      </div>
    </PopupModal>
  );
};
export default ConfirmRequest;
