import { Socket } from 'socket.io-client';
import { User } from '../../manageUser/utils/types';

export enum TruckStatus {
  PUMPING = 'Pumping',
  ACTIVE = 'Active',
  INACTIVE = 'Inactive',
}

export enum JobType {
  SCHEDULED = 'Scheduled',
  UNSCHEDULED = 'Unscheduled',
}

export type Truck = {
  name: string;
  status: TruckStatus;
  jobType: JobType.UNSCHEDULED;
  location?: number;
  jobPumpTime?: number; // minutes
  jobWaterConsumption?: number; // litres
  totalWaterConsumption: number;
  operator?: User | null;
  totalEngineHour: number;
  dailyEngineHour: number;
  shiftStart?: string;
};
export type UpdateOperatorNameByTruckName = (
  truckName: string,
  newOperatorName: string,
) => void;
export type SocketContextType = {
  socket: Socket;
};
