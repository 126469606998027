import React from 'react';
import LoadingScreen from '../../../dustControl/components/loading';
import { secondsToHhMm } from '../../utils/helper';

const HistoryTable = ({ loading, data = {} }) => {
  const formatDate = (timestamp, defaultValue = '00:00 AM') => {
    if (isNaN(Date.parse(timestamp))) return defaultValue;
    const date = new Date(timestamp);
    return date.toLocaleTimeString('en-US', {
      hour: '2-digit',
      minute: '2-digit',
      hour12: true,
    });
  };

  const getLocalTime = (time) => {
    const timeString = formatDate(time);
    var lastTwoCharacters = timeString.slice(-2); // Get the last two characters
    var modifiedTime =
      timeString.slice(0, -2) + lastTwoCharacters.toLowerCase(); // Convert the last two characters to lowercase and replace them
    return modifiedTime;
  };

  return (
    <div>
      <div className={`table_mm_history table_mm`}>
        <table cellPadding="0" cellSpacing="0">
          <thead className="fixed-header">
            <tr align="left" valign="top" className=" ">
              <th></th>
              <th>Truck</th>
              <th>Load Time</th>
              <th>Material</th>
              <th>Source Point</th>
              <th>Excavator</th>
              <th>Exc Operator</th>
              <th>Dump Point</th>
              <th>Truck Operator</th>
              <th>Delays</th>
              <th>Dump Time</th>
            </tr>
          </thead>
        </table>
      </div>
      {loading ? (
        <LoadingScreen />
      ) : (
        <div className={`table_mm_history table_mm  mm-height-calculate  `}>
          {Object.entries(data)?.length ? (
            <table cellPadding="0" cellSpacing="0">
              <tbody>
                {Object.entries(data)?.map(([key, value], index) => {
                  return (
                    <React.Fragment key={key + index}>
                      <tr>
                        <td colSpan="11">{key}</td>
                      </tr>
                      {value?.length &&
                        value?.map((entry, index) => (
                          <tr key={entry.assetID + index}>
                            <td></td>
                            <td></td>
                            <td>
                              {entry.loadtime
                                ? getLocalTime(entry.loadtime)
                                : '--'}
                            </td>
                            <td>{entry?.material ? entry?.material : '-'} </td>
                            <td>{entry?.sourcePoint ?? '-'}</td>
                            <td>{entry?.contactTitle ?? '-'}</td>
                            <td>{entry?.ex_operator ?? '-'}</td>
                            <td>{entry.dumpPoint ?? '-'}</td>
                            <td>{entry?.tr_operator ?? '-'}</td>
                            <td>
                              {entry?.delay_time
                                ? entry?.delay_reason
                                  ? entry?.delay_reason + ''
                                  : ''
                                : '-'}
                              {entry?.delay_time
                                ? ' (' + secondsToHhMm(entry?.delay_time) + ')'
                                : ''}
                            </td>
                            <td> {getLocalTime(entry.dumpTime)} </td>
                          </tr>
                        ))}
                    </React.Fragment>
                  );
                })}
              </tbody>
            </table>
          ) : (
            <div
              style={{ fontSize: '1rem', color: '#707070' }}
              className="mt-2 d-flex justify-content-center align-items-center"
            >
              No Records Found
            </div>
          )}
        </div>
      )}
    </div>
  );
};

export default HistoryTable;
