import React, { useEffect, useState } from 'react';
import { Image } from 'react-bootstrap';
import ArrowDown from '../../assets/icons/arrowDown.png';
import PieChartGraph from '../PieChart';

const CategoryWidget = ({ closeWidgets = () => {}, data }) => {
  const [categoryData, setCategoryData] = useState([]);

  const mapCategoryData = (data) => {
    const categoryMap = {};
    data.forEach((item) => {
      categoryMap[item.name] = item.data;
    });
    setCategoryData(categoryMap);
  };

  useEffect(() => {
    mapCategoryData(data);
  }, [data]);

  const renderPieChart = (chartData, title) => {
    const total = chartData.reduce((sum, value) => sum + value, 0);
    return (
      total > 0 && (
        <div
          id={title}
          key={title}
          style={{ minWidth: '33%' }}
          className="d-flex flex-column justify-content-center align-items-center"
        >
          <div style={{ height: '100px' }} className="pie-container">
            <PieChartGraph
              key={Math.floor(Math.random() * 10)}
              data={chartData}
              outerRadius={58}
              chartData={[
                { name: 'passed', value: chartData[0] },
                { name: 'failed', value: chartData[1] },
                { name: 'incomplete', value: chartData[2] },
              ]}
            />
          </div>
          <div style={{ fontSize: '13px', color: '#707070' }}> {title}</div>
        </div>
      )
    );
  };

  return (
    <div className="widget-container2 ">
      <div className="header d-flex justify-content-between align-items-start py-2 px-3">
        <div>By Category</div>
        <div className="total">
          <div
            className="px-1"
            style={{
              paddingTop: 0,
              paddingBottom: 0,
              backgroundColor: '',
              cursor: 'pointer',
            }}
            onClick={() => closeWidgets(false)}
          >
            <div
              className="d-flex align-items-center justify-content-center"
              style={{
                width: '22px',
                height: '16px',
                backgroundColor: '#F7F7F7',
                border: '1px solid #E1E8F4',
              }}
            >
              <Image src={ArrowDown} alt="down" />
            </div>
          </div>
        </div>
      </div>
      <div
        style={{
          width: '100%',
          overflowX: 'scroll',
        }}
        className="body "
      >
        {Object.entries(categoryData).map(([categoryName, categoryData]) =>
          renderPieChart(categoryData, categoryName),
        )}
      </div>
    </div>
  );
};

export default CategoryWidget;
