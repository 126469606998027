import React from 'react';
import { Spinner } from 'react-bootstrap';
import { processLoads, formatTimeForInterval } from './utils';
import Tag from '../../../../components/Tag/Tag';
import { IoIosArrowUp } from 'react-icons/io';
import styles from './ShiftLoaderTable.module.css';
import { ShiftLoaderTableProps, Interval } from './types';

const ShiftLoaderTable: React.FC<ShiftLoaderTableProps> = ({
  data,
  loading,
  isFullSize,
  expand,
  filterValues,
}) => {
  return (
    <div className=" bg-white p-4 flex-grow-1 ">
      <table className="table">
        <thead>
          <tr style={{ fontSize: '12px' }}>
            <th className="text-muted col-2">Load</th>
            <th className="text-muted col-3">Timestamp & Duration</th>
            <th className="text-muted col-2">Material</th>
            <th className="text-muted ">Tonnes</th>
            <th className="col-1" onClick={() => expand()}>
              {!isFullSize ? (
                <div className="cursor-pointer fw-bold d-flex ">
                  Hide Graph <IoIosArrowUp size={15} className="ms-2" />
                </div>
              ) : (
                <div style={{ position: 'relative' }}>
                  <Tag
                    className="mx-2 pw-bold cursor-pointer position-absolute"
                    textColor="black"
                    style={{ bottom: 0 }}
                    bgColor={'#bfe8cd'}
                    text={`Show Graph`}
                  />
                </div>
              )}
            </th>
          </tr>
        </thead>
        {data.length > 0 && !loading && (
          <tbody>
            {processLoads(
              data,
              filterValues.dateRange.from != '' &&
                filterValues.dateRange.to != '' &&
                filterValues.dateRange.from != filterValues.dateRange.to,
            ).map((intervalData: Interval) => (
              <React.Fragment key={intervalData.interval}>
                {intervalData?.loads.length > 0 && (
                  <tr>
                    <td style={{ border: 'none' }} colSpan={4}>
                      <div className="d-flex py-2 w-100">
                        <Tag
                          textColor="white"
                          bgColor="#00214D"
                          text={intervalData.interval}
                        />
                        <Tag
                          className="mx-2"
                          textColor="black"
                          bgColor={
                            intervalData.loadsCompleted <= 3
                              ? '#ACE5C0'
                              : '#F2B5B5'
                          }
                          text={`${intervalData.loadsCompleted}/3`}
                        />
                        <Tag
                          className="mx-2"
                          textColor="black"
                          bgColor={
                            intervalData.loadsCompleted <= 3
                              ? '#ACE5C0'
                              : '#F2B5B5'
                          }
                          text={`${intervalData.totalTonnes} t`}
                        />
                      </div>
                    </td>
                  </tr>
                )}
                {intervalData?.loads?.map((load, index) => (
                  <tr key={index} className={styles.dataRow}>
                    <td className={`py-3 ${styles.boldCell}`}>
                      Load {load.loadNumber}
                    </td>
                    <td className="py-3">
                      {formatTimeForInterval(load.startTime, load.endTime)}
                    </td>
                    <td className="py-3">{load.material}</td>
                    <td className="py-3">{load.tonnes}</td>
                  </tr>
                ))}
              </React.Fragment>
            ))}
          </tbody>
        )}
      </table>
      {loading && (
        <div
          style={{ height: 'calc(100% - 50px)' }}
          className="w-100 d-flex justify-content-center align-items-center"
        >
          <Spinner />
        </div>
      )}
      {data.length == 0 && !loading && (
        <div className="w-100 d-flex justify-content-center align-items-center">
          <div>No Data Found</div>
        </div>
      )}
    </div>
  );
};
export default ShiftLoaderTable;
