import { CSSProperties, FC, ChangeEventHandler } from 'react';

type RadioButtonProps = {
  name: string;
  id: string;
  value: string;
  onChange: ChangeEventHandler<HTMLInputElement>;
  checked: boolean;
  text: string;
  style?: CSSProperties;
};
const RadioButton: FC<RadioButtonProps> = ({
  name,
  id,
  value,
  onChange,
  checked,
  text,
  style,
}) => {
  return (
    <div style={style}>
      <input
        className="radio-input"
        type="radio"
        name={name}
        id={id}
        value={value}
        onChange={onChange}
        checked={checked}
      />
      <span className="custom-radio" />
      {text}
    </div>
  );
};

export default RadioButton;
