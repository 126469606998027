import { useState, useContext } from 'react';
import PopupModal from '../popUp';
import { UpdateLevelModalProps } from './types';
import BinLevelTile from './LevelTiles';
import CustomButton from '../../../dustControl/components/CustomButton';
import * as toast from '../../../dustControl/components/toast';
import { UserContext } from '../../../pages/service/userContext';
import { API_METHODS } from '../../utils/constants';
import { Routes } from '../../utils/routes';
import './styles.scss';

const binLevels = [100, 75, 50, 25, 0];

export const UpdateLevelModal: React.FC<UpdateLevelModalProps> = ({
  isOpen,
  onClose,
  bin,
}) => {
  const [selectedLevel, setSelectedLevel] = useState<number>(bin.binLevel);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const { ApiHandler } = useContext(UserContext);

  const updateLevel = async () => {
    try {
      setIsLoading(true);
      await ApiHandler({
        reqParam: {
          level: selectedLevel,
          binName: bin.name,
        },
        method: API_METHODS.PUT,
        endPoint: Routes.UPDATE_BIN,
      });
      onClose();
      toast.success(`Bin level successfully updated`);
    } catch (err) {
      toast.error((err as Error)?.message);
    } finally {
      setIsLoading(false);
    }
  };
  if (!bin) return null;
  else
    return (
      <PopupModal
        isOpen={isOpen}
        onClose={onClose}
        header={`Update Bin Level - Bin ${bin.name}`}
      >
        <div className="bin-level-modal">
          {binLevels?.map((level) => {
            return (
              <BinLevelTile
                level={level}
                key={level}
                isSelected={selectedLevel === level}
                onClick={() => {
                  setSelectedLevel(level);
                }}
              />
            );
          })}

          <div className="mt-3">
            <CustomButton
              style={{
                background: 'black',
                paddingLeft: '2rem',
                paddingRight: '2rem',
                width: '100%',
                alignSelf: 'center',
              }}
              loading={isLoading}
              id="updateLevel"
              title={'Update'}
              onClick={updateLevel}
            />
          </div>
        </div>
      </PopupModal>
    );
};
