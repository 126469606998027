import React, { useEffect } from 'react';
import Greentick from './../../assets/images/green-tick.png';

export default function MessagePopup({
  popupMessage = { message: '', footer: '' },
  setPopupMessage = () => {},
}) {
  useEffect(() => {
    let x = setTimeout(() => {
      setPopupMessage({ message: '', footer: '' });
    }, 2500);
    return () => {
      clearTimeout(x);
    };
  }, [popupMessage?.message, popupMessage?.footer, setPopupMessage]);

  return (
    <div
      style={{
        width: '100vw',
        height: '100vh',
        background:
          '0% 0% no-repeat padding-box padding-box rgb(110 110 110 / 30%)',
        position: 'absolute',
        top: 0,
        left: 0,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        zIndex: 10000,
      }}
    >
      <div
        style={{
          background: ' #FFFFFF 0% 0% no-repeat padding-box',
          boxShadow: '0px 2px 10px #00000010',
          borderRadius: 31,
          font: ' normal normal bold 16px/18px Open Sans',
          color: '#1A1A1A',
          width: 485,
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          height: 230,
          marginBottom: '60px',
        }}
      >
        <div className="">
          <div className=" d-flex justify-content-center">
            <img src={Greentick} style={{ width: 65, height: 66 }} alt="" />
          </div>

          <div
            style={{ font: 'normal normal 600 16px/22px Open Sans' }}
            className=" d-flex justify-content-center mt-5"
          >
            {popupMessage?.message}
          </div>
          <div
            style={{ font: ' normal normal normal 14px/19px Open Sans' }}
            className="px-2 d-flex justify-content-center mt-3"
          >
            {popupMessage?.footer}
          </div>
        </div>
      </div>
    </div>
  );
}
