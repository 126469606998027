export enum ShiftLoadsGraphOption {
  HOURS = 'Hours',
  DAYS = 'Days',
  MONTHS = 'Months',
}
export enum ShiftLoaderDateRangeType {
  CURRENT_DAY = 1,
  CURRENT_DAY_WITH_RANGE = 2,
  PAST_DAY = 3,
  PAST_DAY_WITH_RANGE = 4,
  RANGE_48 = 5,
  RANGE_48_PLUS = 6,
}
export const ShiftLoadTimeFrameMapping: { [key in string]: string } = {
  [ShiftLoadsGraphOption.DAYS]: 'Daily',
  [ShiftLoadsGraphOption.HOURS]: 'Hourly',
  [ShiftLoadsGraphOption.MONTHS]: 'Monthly',
};

export enum Query {
  GRAPH_TAB = 'graph_tab',
}

export const dayShiftData = [
  { quarter: '6 AM', earnings: 0 },
  { quarter: '7 AM', earnings: 0 },
  { quarter: '8 AM', earnings: 0 },
  { quarter: '9 AM', earnings: 0 },
  { quarter: '10 AM', earnings: 0 },
  { quarter: '11 AM', earnings: 0 },
  { quarter: '12 PM', earnings: 0 },
  { quarter: '1 PM', earnings: 0 },
  { quarter: '2 PM', earnings: 0 },
  { quarter: '3 PM', earnings: 0 },
  { quarter: '4 PM', earnings: 0 },
];

export const nightShiftData = [
  { quarter: '5 PM', earnings: 0 },
  { quarter: '6 PM', earnings: 0 },
  { quarter: '7 PM', earnings: 0 },
  { quarter: '8 PM', earnings: 0 },
  { quarter: '9 PM', earnings: 0 },
  { quarter: '10 PM', earnings: 0 },
  { quarter: '11 PM', earnings: 0 },
  { quarter: '12 AM', earnings: 0 },
  { quarter: '1 AM', earnings: 0 },
  { quarter: '2 AM', earnings: 0 },
  { quarter: '3 AM', earnings: 0 },
];
