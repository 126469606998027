import React, { useState, useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import logo from '../../dustControl/assets/icons/logo.png';
import { AuthContext } from '../service/auth';
import {
  emailValidation,
  passwordValidation,
} from '../../dustControl/utils/helper';
import FloatingInput from '../../dustControl/components/FloatingInput';
import CustomButton from '../../dustControl/components/CustomButton';
import Tnc from '../../dustControl/components/Tnc';
import { UserContext } from '../service/userContext';
import { setAccessToken } from '../service/accessToken';
import { LoginToBinsButton, LoginToWebTextAndIcon } from './LoginToBinsButton';

const Login = () => {
  const navigate = useNavigate();
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [errorMsg, setErrorMsg] = useState({
    emailErrorMsg: '',
    passwordErrorMsg: '',
    general: '',
  });
  const [loading, setLoading] = useState(false);

  const { login } = useContext(AuthContext);
  const { setDataWhenLogging } = useContext(UserContext);

  const forgotPassword = () => {
    navigate('/forgot-password');
  };

  const handleSubmitClick = async (e) => {
    e.preventDefault();
    setLoading(true);
    const emailErrorMsg = emailValidation(email);
    let passwordErrorMsg = passwordValidation(password);
    setErrorMsg({ emailErrorMsg, passwordErrorMsg });
    if (emailErrorMsg || passwordErrorMsg) {
      setLoading(false);
      return;
    }
    try {
      const res = await login({ email, password });
      if (res.data) {
        let userInfo = {
          first_name: res.data.first_name,
          last_name: res.data.last_name,
          email: res.data.email,
          accessToken: res.data.accessToken,
          added_by: res.data.added_by,
          pin_number: res.data.pin_number,
          type: res.data.type,
        };
        if (userInfo.status === 'Invited')
          return navigate('/register', { state: { userInfo } });

        const [token, departments] = userInfo.accessToken?.split('||');
        userInfo.departments = departments;
        setAccessToken(token);
        localStorage.setItem('accessToken', token);
        localStorage.setItem('refreshToken', userInfo.refreshToken);
        await setDataWhenLogging(userInfo);
        navigate('/landing-page');
      }
    } catch (error) {
      setErrorMsg({ general: error.message });
    }
    setLoading(false);
  };

  return (
    <div className="login-view">
      <div className="login-main-view">
        <img src={logo} alt="SteelServ" />
        <h4 className="text-dark fw-bold login-header" style={{ margin: 20 }}>
          Log in to your account
        </h4>

        <FloatingInput
          value={email}
          setValue={setEmail}
          label="Email"
          type="email"
          isError={errorMsg.emailErrorMsg ? true : false}
          id="username"
        />
        <p className="error-msg">{errorMsg.emailErrorMsg}</p>
        <FloatingInput
          value={password}
          setValue={setPassword}
          label={'Password'}
          type="password"
          id="password"
          isError={errorMsg.passwordErrorMsg ? true : false}
        />
        <p className="error-msg">{errorMsg.passwordErrorMsg}</p>
        {errorMsg.general && <p className="error-msg">{errorMsg.general}</p>}
        <div className="d-flex justify-content-between align-items-center w-75 pb-3 mt-3 mb-2">
          <div className=" d-flex align-items-center">
            <input type="checkbox" id="remember" name="remember" value="yes" />
            <p className="remember-text m-0 ps-1">Remember me</p>
          </div>
          <div className="forgot-text" onClick={forgotPassword}>
            Forgot password?
          </div>
        </div>
        <LoginToBinsButton />
        <CustomButton
          style={{ boxShadow: '0px 3px 6px #00000029', marginTop: '15px' }}
          loading={loading}
          id="loginButton"
          title={<LoginToWebTextAndIcon />}
          onClick={(e) => handleSubmitClick(e)}
        />
      </div>
      <Tnc />
    </div>
  );
};

export default Login;
