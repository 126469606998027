import { Modal } from 'react-bootstrap';
import React, { useState } from 'react';
import BasicButton from '../../components/BasicButton';
import moment from 'moment';
import { formatDateToISO } from '../../utils/helper';
const trucks = ['627', '721', '819'];
const towers = [
  { tower: 'Tower 1', value: '1' },
  { tower: 'Tower 2', value: '2' },
];
const SelectItem = (props) => {
  const { onCheckboxClick, selected, item, value, col } = props;
  return (
    <div
      className={`checkbox-container d-flex align-items-center pb-2 col-${col}`}
    >
      <input
        type="checkbox"
        checked={selected}
        onChange={onCheckboxClick}
        className="custom-control-input"
        value={value}
      />
      <p className="custom-control-label m-0 ps-2">{item}</p>
    </div>
  );
};

const WaterTowerFilterModel = (props) => {
  const { modalOpen, modalToggle, onSaveClick, initialFilteredData } = props;
  const [filteredData, setFilteredData] = useState(initialFilteredData);

  const onCheckboxClick = (e, type) => {
    let tempFilter = filteredData;

    let value = type !== 'zones' ? e.target.value : parseInt(e.target.value);

    if (e.target.checked && !tempFilter[type]?.includes(value)) {
      tempFilter[type].push(value);
    } else {
      tempFilter[type].splice(tempFilter[type].indexOf(value), 1);
    }

    setFilteredData({ ...tempFilter });
  };

  const clearFilter = () => {
    setFilteredData(() => ({
      trucks: [],
      startDate: '',
      endDate: '',
      towers: [],
    }));
    onSaveClick({
      trucks: [],
      startDate: '',
      endDate: '',
      towers: [],
    });
  };

  const fromDateHandler = (v) => {
    const { value } = v.target;
    if (moment(value) > moment()) return;
    if (moment(value) > moment(filteredData.endDate)) return;

    setFilteredData({
      ...filteredData,
      startDate: formatDateToISO(v.target.value),
    });
  };

  const toDateHandler = (v) => {
    const { value } = v.target;
    if (moment(filteredData.startDate) > moment(value)) return;
    if (moment(value) > moment()) return;

    setFilteredData({
      ...filteredData,
      endDate: formatDateToISO(v.target.value),
    });
  };

  const saveHandler = () => {
    const copyFilteredData = {
      ...filteredData,
      startDate: filteredData.startDate || undefined,
      endDate: filteredData.endDate || undefined,
    };
    onSaveClick(copyFilteredData);
  };

  return (
    <div>
      {modalOpen && (
        <Modal
          show={modalOpen}
          onHide={modalToggle}
          backdrop="static"
          keyboard={false}
          size="lg"
          dialogClassName="modal-position"
        >
          <Modal.Header>
            <div className="justify-content-end modal-header-container ">
              <div className="mt-2">
                <div className=" d-flex justify-content-end mb-3 align-self-end">
                  <button
                    type="button"
                    className="btn-close"
                    aria-label="Close"
                    onClick={() => modalToggle(false)}
                  ></button>
                </div>
                <div className="d-flex gap-2 button-container me-4">
                  <BasicButton
                    outlined
                    onClick={clearFilter} //clear filter and close modal
                    variant="outline-dark"
                  >
                    Clear All
                  </BasicButton>
                  <BasicButton onClick={saveHandler} variant="dark">
                    Save
                  </BasicButton>
                </div>
              </div>
            </div>
          </Modal.Header>
          <Modal.Body className="pt-0 mx-4">
            <div className="filter-header">Filter by</div>
            <div>
              <div className="filter-main-label">Date</div>
              <div className="d-flex my-4 ms-3">
                <div className="filter-container d-flex align-items-center w-50">
                  <div className="filter-label me-3">From</div>
                  <input
                    type="date"
                    value={filteredData.startDate}
                    onChange={fromDateHandler}
                  />
                </div>
                <div className="filter-container d-flex align-items-center w-50">
                  <div className="filter-label me-3">To</div>
                  <input
                    type="date"
                    value={filteredData.endDate}
                    onChange={toDateHandler}
                  />
                </div>
              </div>

              <div className="my-4 border-bottom">
                <div className="filter-main-label mt-2 mb-2">Truck</div>
                <div className="filter-container d-flex align-items-center ms-3">
                  {trucks?.map((truck, i) => (
                    <SelectItem
                      key={i}
                      item={truck}
                      value={truck}
                      col={4}
                      onCheckboxClick={(e) => onCheckboxClick(e, 'trucks')}
                      selected={filteredData.trucks.includes(truck)}
                    />
                  ))}
                </div>
              </div>

              <div className="my-4 border-bottom">
                <div className="filter-main-label mt-2 mb-2">Tower</div>
                <div className="filter-container d-flex align-items-center ms-3">
                  {towers?.map(({ tower, value }) => (
                    <SelectItem
                      key={tower}
                      item={tower}
                      value={value}
                      col={4}
                      onCheckboxClick={(e) => onCheckboxClick(e, 'towers')}
                      selected={filteredData.towers.includes(value)}
                    />
                  ))}
                </div>
              </div>
            </div>
          </Modal.Body>
        </Modal>
      )}
    </div>
  );
};

export default WaterTowerFilterModel;
