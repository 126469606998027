export const customStyles = {
  control: (provided: any, { isFocused }: { isFocused: boolean }) => ({
    ...provided,
    fontFamily: 'Open Sans',
    fontWeight: 600,
    fontSize: '12px',
    borderRadius: '4px',
    width: 160,
    outline: isFocused ? '2px solid #21B291' : '1px solid #E2E2E2',
    border: 'none',
    paddingTop: '0px',
    paddingBottom: '0px',
    minHeight: 30,
  }),
  menu: (provided: any) => ({
    ...provided,
    maxWidth: '160px',
    marginTop: '2px',
    color: 'black',
    borderRadius: '4px',
    boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.1)',
  }),
  placeholder: (provided: any) => ({
    ...provided,
    fontFamily: 'Open Sans',
    fontWeight: 600,
    fontSize: '12px',
    paddingTop: '0px',
    paddingBottom: '0px',
    marginTop: '0px',
    marginBottom: '0px',
  }),
  option: (
    provided: any,
    { isFocused, isSelected }: { isFocused: boolean; isSelected: boolean },
  ) => ({
    ...provided,
    fontFamily: 'Open Sans',
    fontWeight: 600,
    fontSize: '14px',
    paddingLeft: 25,
    color: 'black',
    backgroundColor: isSelected
      ? '#b5cab7'
      : isFocused
        ? '#E6F0E7'
        : 'transparent',
    '&:hover': {
      backgroundColor: isSelected ? '#b5cab7' : '#E6F0E7',
    },
  }),
  clearIndicator: (provided: any) => ({
    ...provided,
    cursor: 'pointer',
    paddingTop: '0px',
    paddingBottom: '0px',
    marginTop: '0px',
    marginBottom: '0px',
  }),
  indicatorsContainer: (
    provided: any,
    { hasValue }: { hasValue: boolean },
  ) => ({
    ...provided,
    flexDirection: 'row-reverse',
    paddingTop: '0px',
    paddingBottom: '0px',
    marginTop: '0px',
    marginBottom: '0px',
  }),
  indicatorSeparator: (provided: any, { hasValue }: { hasValue: boolean }) => ({
    ...provided,
    display: !hasValue ? 'none' : 'flex',
    paddingTop: '0px',
    paddingBottom: '0px',
    marginTop: '4.5px',
    marginBottom: '4.5px',
  }),
  dropdownIndicator: (provided: any, { hasValue }: { hasValue: boolean }) => ({
    ...provided,
    display: !hasValue ? 'flex' : 'none',
    paddingTop: '0px',
    paddingBottom: '0px',
    marginTop: '0px',
    marginBottom: '0px',
  }),
};
