// @ts-nocheck
import React, { useEffect, useRef, useState } from 'react';
import { BinRowProps } from './types';
import './styles.scss';
import { GeneralBinStatus, SortStatus } from '../../utils/types';
import { convertBinLastActionTime } from '../Details/helper';
import { getDetailedBinStatus, getGeneralBinStatus } from '../../utils/helpers';
import { detailedBinStatusLabels } from '../../utils/constants';
import { AlertCircle } from '../../assets/images/alert_circle';
import { AlertPink } from '../../assets/images/alertPink';

export const BinRow: React.FunctionComponent<BinRowProps> = (
  props: BinRowProps,
) => {
  const [tColour, setTColour] = useState(props.titleColor ?? 'black');

  const handleSelectRow = () => {
    props.onSelectRow(props.bin);
  };

  // Style for the bin row to dynamically set the --title-color CSS variable
  const rowStyle = {
    '--group-title-color': tColour, // Set the title color as a CSS variable
    borderTop: props.isFirstItem ? '0px solid #E2E2E2' : '1px solid #E2E2E2',
  };

  const getCurrentBinStatus = (): string => {
    if (getGeneralBinStatus(props.bin) === GeneralBinStatus.IN_PROGRESS) {
      return detailedBinStatusLabels[getDetailedBinStatus(props.bin)];
    } else if (props.sortType && props.sortType === SortStatus.LAST_UPDATED) {
      return `LU - ${convertBinLastActionTime(props.bin.lastUpdatedTime)}`;
    } else if (props.bin.lastEmptiedTime !== undefined) {
      return `LE - ${convertBinLastActionTime(props.bin?.lastEmptiedTime)}`;
    }
    return '-';
  };
  return (
    <div className="rowWrapper">
      <div
        className={`item-row ${props.isFirstItem ? 'item-row-first' : ''} ${
          props.isSelected ? 'selected' : ''
        }`}
        style={rowStyle}
        onClick={handleSelectRow}
      >
        <div className="item-row-top">
          <div className="alert-circle-row">
            {getGeneralBinStatus(props.bin) === GeneralBinStatus.REQUESTED ? (
              <AlertCircle style={{ marginRight: 8 }} height={15} width={15} />
            ) : getGeneralBinStatus(props.bin) === GeneralBinStatus.MISSING ? (
              <AlertPink style={{ marginRight: 8 }} height={15} width={15} />
            ) : null}
            <b
              style={
                getGeneralBinStatus(props.bin) === GeneralBinStatus.REQUESTED
                  ? { color: 'white' }
                  : {}
              }
            >
              {props.bin.name}
            </b>
          </div>
          <b
            style={
              getGeneralBinStatus(props.bin) === GeneralBinStatus.REQUESTED
                ? { color: 'white' }
                : {}
            }
          >
            {getGeneralBinStatus(props.bin) === GeneralBinStatus.IN_PROGRESS
              ? `${props.bin?.lastPickup?.truckName}`
              : `${props.bin?.binLevel}%`}
          </b>
        </div>

        <div
          className="item-row-bottom"
          style={
            getGeneralBinStatus(props.bin) === GeneralBinStatus.REQUESTED
              ? { color: 'white' }
              : {}
          }
        >
          <i>{props.bin.pickupPoint}</i>
          <i>{getCurrentBinStatus()}</i>
        </div>
      </div>
    </div>
  );
};
