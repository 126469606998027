import React, { useContext, useRef, useState } from 'react';
import UserFilterModal from '../../../manageUser/UserFilterModal/UserFilterModal';
import { UserContext } from '../../../pages/service/userContext';
import FilterIcon from '../../assets/icons/Filter.svg';
import SearchIcon from '../../assets/icons/Search.svg';
import DownloadIcon from '../../assets/icons/download-prestart.svg';
import WaterTowerFilterModel from '../../pages/waterTower/waterTowerFilterModel';
import FilterModal from '../FilterModal';
import DropdownField from '../FormDropdown';
import MaterialMovementsFilterModal from '../../../materialMovements/components/MaterialMovementsFilterModal/MaterialMovementsFilterModal';

export const Search = (props) => {
  const inputRef = useRef(null);
  const { onChange } = props;
  return (
    <div
      style={{ marginRight: '15px' }}
      className="d-flex justify-content-center align-items-center"
    >
      <input
        ref={inputRef}
        placeholder="Search"
        className="search-input align-self-center justify-self-center"
        onChange={onChange}
      />
      <img
        src={SearchIcon}
        className="search-icon"
        onClick={() => inputRef.current?.focus()}
        alt="search"
      />
    </div>
  );
};

export const Filter = (props) => {
  const { toShowFilter, openModal } = props;

  return (
    <div
      onClick={openModal}
      style={{ marginLeft: '15px', fontWeight: '600' }}
      className={`${
        toShowFilter ? 'd-flex' : 'd-none'
      } align-items-center ms-3 filter-div cursor-pointer`}
    >
      <img src={FilterIcon} alt="filters" />
      <span className="font-13 p-1">Filter</span>
    </div>
  );
};

export const Departments = (props) => {
  const { departments, licenses } = useContext(UserContext);

  var liscs = licenses;
  const allCodes = [];
  const deptatment = departments?.map((dept) => {
    const lIndex = liscs.findIndex(
      (license) => license?.departmentCode === dept?.code,
    );
    if (lIndex !== -1) {
      const l = liscs[lIndex];
      liscs = liscs.filter((_, index) => index !== lIndex);
      allCodes.push(dept?.code);
      return { name: dept?.name, value: dept?.code };
    }
  });

  const depts = deptatment?.filter((d) => d != undefined);
  const [data, setData] = useState(allCodes);
  const { onChange } = props;
  return (
    <>
      <div
        style={{ marginLeft: '15px' }}
        className="dropdown-input d-flex align-items-center justify-content-center "
      >
        <div className="text-1 ">Department:</div>
        <div className="dept-header-dropdown">
          <DropdownField
            selectedOptions={data}
            allOptions={depts}
            arrowOutside
            lowerLevelClassName="inner-date-input p-0"
            multiselect
            handleChange={(fieldName, value, multiselect, isAll) => {
              if (isAll) {
                setData(allCodes);
                onChange(allCodes);
              } else {
                const newData = [...data];
                if (newData.includes(value)) {
                  newData.splice(newData.indexOf(value), 1); // Remove the field from newData
                  setData(newData);
                  onChange(newData);
                } else {
                  setData([...newData, value]);
                  onChange([...newData, value]);
                }
              }
            }}
          />
        </div>
      </div>
    </>
  );
};

const Status = (props) => {
  const [data, setData] = useState(['passed', 'failed', 'incomplete']);
  const { onChange } = props;
  return (
    <>
      <div
        style={{ marginLeft: '15px' }}
        className="dropdown-input d-flex align-items-center justify-content-center  "
      >
        <div className="text-1 me-2">Status:</div>
        <div className="header-dropdown">
          <DropdownField
            arrowOutside
            selectedOptions={data}
            allOptions={[
              { name: 'Passed', value: 'passed' },
              { name: 'Failed', value: 'failed' },
              { name: 'Incomplete', value: 'incomplete' },
            ]}
            lowerLevelClassName="inner-date-input p-0"
            multiselect
            fieldKey={'status'}
            handleChange={(fieldName, value, multiselect, isAll) => {
              if (isAll) {
                setData(['passed', 'failed', 'incomplete']);
                onChange(['passed', 'failed', 'incomplete']);
              } else {
                const newData = [...data];
                if (newData.includes(value)) {
                  newData.splice(newData.indexOf(value), 1); // Remove the field from newData
                  setData(newData);
                  onChange(newData);
                } else {
                  setData([...newData, value]);
                  onChange([...newData, value]);
                }
              }
            }}
          />
        </div>
      </div>
    </>
  );
};

export const Category = (props) => {
  const { licenses } = useContext(UserContext);
  const licensesOptions = licenses
    ?.map(({ name, code }) => ({ name, value: code }))
    ?.filter((d) => d != undefined);

  const allCodes = licenses?.map((l) => {
    return l.code;
  });

  const [data, setData] = useState(allCodes);
  const { onChange } = props;

  return (
    <>
      <div
        style={{ marginLeft: '15px' }}
        className="dropdown-input d-flex align-items-center justify-content-center "
      >
        <div className="text-1 ">Category:</div>
        <div className="dept-header-dropdown">
          <DropdownField
            arrowOutside
            selectedOptions={data}
            allOptions={licensesOptions}
            lowerLevelClassName="inner-date-input p-0"
            multiselect
            categoryText={props.categoryText}
            handleChange={(fieldName, value, multiselect, isAll) => {
              if (isAll) {
                setData(allCodes);
                onChange(allCodes);
              } else {
                const newData = [...data];
                if (newData.includes(value)) {
                  newData.splice(newData.indexOf(value), 1); // Remove the field from newData
                  setData(newData);
                  onChange(newData);
                } else {
                  setData([...newData, value]);
                  onChange([...newData, value]);
                }
              }
            }}
          />
        </div>
      </div>
    </>
  );
};

export const Date = (props) => {
  const { setSelectedDate, selectedDate } = props;
  return (
    <>
      <div
        style={{ marginLeft: '15px' }}
        className="date-input d-flex align-items-center justify-content-center "
      >
        <div className="text-1 me-2">Date:</div>
        <input
          style={{ fontWeight: '600' }}
          type="date"
          className="text-2 inner-date-input "
          value={selectedDate}
          onChange={(e) => {
            setSelectedDate(e.target.value);
          }}
        />
      </div>
    </>
  );
};

export const Download = ({ onDownloadClick }) => {
  return (
    <div
      onClick={onDownloadClick}
      className="d-flex align-items-center cursor-pointer"
    >
      <img
        src={DownloadIcon}
        alt="download"
        className="subheader-download ms-2 cursor-pointer"
      />
      <div style={{ fontSize: '12px' }}>Download</div>
    </div>
  );
};

const SubHeader = (props) => {
  const {
    waterTowerFilter,
    userFilter,
    toShowFilter,
    SubHeaderVisible,
    onSearchChange,
    onDateChange,
    onDeptChange,
    onStatusChange,
    setFilteredData,
    filteredData,
    materialMovementFilterModal,
    onSaveClick = () => {},
    showDownload,
    onDownloadClick,
    hideSearch,
    date,
    department,
    status,
    selectedDate,
    setSelectedDate,
    outerClassName,
    category,
    onCategoryChange,
  } = props;

  const [openFilterModal, setOpenFilterModal] = useState(false);

  const saveAndClose = (filterData, hasFiltered) => {
    setOpenFilterModal(false);
    onSaveClick(filterData, hasFiltered);
  };

  return (
    <div
      className={`${
        SubHeaderVisible ? 'd-flex' : 'd-none'
      } align-items-center subheader me-3 ${outerClassName}`}
    >
      {!hideSearch && <Search onChange={onSearchChange} />}
      {date && (
        <Date selectedDate={selectedDate} setSelectedDate={setSelectedDate} />
      )}
      {department && <Departments onChange={onDeptChange} />}
      {category && <Category onChange={onCategoryChange} />}
      {status && <Status onChange={onStatusChange} />}
      <Filter
        userFilter={props.userFilter}
        filteredData={filteredData}
        toShowFilter={toShowFilter}
        openModal={() => setOpenFilterModal(true)}
      />
      {showDownload && <Download onDownloadClick={onDownloadClick} />}

      {waterTowerFilter && openFilterModal ? (
        <WaterTowerFilterModel
          modalOpen={openFilterModal}
          modalToggle={() => setOpenFilterModal(false)}
          initialFilteredData={filteredData}
          setFilteredData={setFilteredData}
          onSaveClick={saveAndClose}
        />
      ) : userFilter && openFilterModal ? (
        <UserFilterModal
          modalOpen={openFilterModal}
          modalToggle={() => setOpenFilterModal(false)}
          initialFilteredData={filteredData}
          setFilteredData={setFilteredData}
          onSaveClick={saveAndClose}
        />
      ) : openFilterModal && materialMovementFilterModal ? (
        <MaterialMovementsFilterModal
          modalOpen={openFilterModal}
          modalToggle={() => setOpenFilterModal(false)}
          filteredData={filteredData}
          setFilteredData={setFilteredData}
          saveHandler={saveAndClose}
        />
      ) : openFilterModal ? (
        <FilterModal
          modalOpen={openFilterModal}
          modalToggle={() => setOpenFilterModal(false)}
          initialFilteredData={filteredData}
          onSaveClick={saveAndClose}
        />
      ) : null}
    </div>
  );
};

export default SubHeader;
