import { useCallback, useContext, useEffect, useState } from 'react';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import TruckInfo from '../../components/TruckInfo';
import { API_METHODS, ENDPOINTS, SOCKETS } from '../../utils/constants';
import WaterWidget from '../../components/WaterWidget';
import * as toast from '../../components/toast';
import { SocketContext } from '../../utils/socket';
import ZoneTable from '../../components/ZoneTable';
import JobsHistory from '../../components/JobsHistory';
import ZoneReport from '../zoneReports';
import WaterTowerInteraction from '../waterTower';
import { UserContext } from '../../../pages/service/userContext';
import { withAccessControl } from '../../../pages/service/with-access-control';
import { SocketContextType, Truck, TruckStatus } from '../../utils/types';
import {
  DashboardProps,
  SocketEvent,
  SocketResponse,
  TruckSocketData,
} from './types';
import { isNumber } from 'lodash';
import { useSocket } from '../../../hooks/useSocket';
import {
  EngineEvent,
  EngineHoursSocketData,
  SocketClient,
  SocketRoom,
} from '../../../utils/constants';

const DustControl: React.FunctionComponent<DashboardProps> = (props) => {
  const { ApiHandler, setLoading } = useContext(UserContext);
  const { selectedTab } = props;
  const [trucks, setTrucks] = useState<Truck[]>([]);
  const { socket } = useContext<SocketContextType>(SocketContext);

  const refreshData = useCallback(
    async (controller?: AbortSignal, showLoader = true) => {
      try {
        const trucks = await ApiHandler({
          method: API_METHODS.GET,
          endPoint: ENDPOINTS.getTrucks,
          signal: controller,
        });
        setTrucks(trucks.data);
        return '';
      } catch (error: any) {
        toast.error(error?.message);
        return error;
      } finally {
        showLoader && setLoading(false);
      }
    },
    [setLoading, ApiHandler],
  );

  const updateTrucks = useCallback(
    (socketData: TruckSocketData) => {
      setTrucks((previousTrucks: Truck[]) => {
        return previousTrucks.map((truck: Truck) => {
          const newDataForTruck: Partial<Truck> | undefined =
            socketData[truck.name];

          if (!newDataForTruck) return truck;

          if (newDataForTruck.jobWaterConsumption) {
            if (!truck.jobWaterConsumption) {
              // likely a new job was started, so jobWaterConsumption is 0
              // total waterConsumption cannot be reliably calculated using the difference because new job may have carried over previous litres pumped
              // so refresh the data
              refreshData();
            } else if (isNumber(truck.totalWaterConsumption)) {
              // increase the totalWaterConsumption by the difference between old jobWaterConsumption and new jobWaterConsumption
              truck.totalWaterConsumption +=
                newDataForTruck.jobWaterConsumption - truck.jobWaterConsumption;
            } else {
              truck.totalWaterConsumption = newDataForTruck.jobWaterConsumption;
            }
          }

          return { ...truck, ...newDataForTruck };
        });
      });
    },
    [refreshData],
  );

  useEffect(() => {
    socket.on(SOCKETS.trucks, (socketData: TruckSocketData) => {
      updateTrucks(socketData);
    });

    return () => {
      socket.off(SOCKETS.trucks);
    };

    // per Socket docs cannot include socket in dependency array
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleSocketResponse = useCallback(
    (socketResponse: SocketResponse) => {
      if (socketResponse.socketEvent === SocketEvent.TRUCK_STATUS) {
        updateTrucks({ [socketResponse.truckName]: socketResponse });
      }
    },
    [updateTrucks],
  );

  useSocket<SocketResponse>(
    SocketRoom.WATER_CARTS,
    handleSocketResponse,
    SocketClient.ADMIN,
  );

  const updateEngineHours = useCallback((socketData: EngineHoursSocketData) => {
    setTrucks((prev) =>
      prev.map((truck: Truck) => {
        if (truck.name !== socketData.truckName) {
          return truck;
        }
        const updatedTruck: Truck = { ...truck };
        updatedTruck.status =
          socketData.engineStatus === EngineEvent.ON
            ? TruckStatus.ACTIVE
            : TruckStatus.INACTIVE;
        if (socketData.engineHours) {
          updatedTruck.totalEngineHour = Math.round(
            socketData.engineHours.totalEngineHoursInMinutes / 60,
          );
          updatedTruck.dailyEngineHour =
            socketData.engineHours.calculatedEngineMinutes;
        }
        return updatedTruck;
      }),
    );
  }, []);
  useSocket<EngineHoursSocketData>(SocketRoom.ENGINE_HOURS, updateEngineHours);

  useEffect(() => {
    const abortController = new AbortController();
    refreshData(abortController.signal);
    return () => {
      abortController.abort();
    };
  }, [refreshData]);

  return (
    <Container className="container-fluid" fluid>
      {selectedTab === 0 && (
        <>
          <Row className="fluid-div align-items-center">
            <Col lg={12} xl={8}>
              <ZoneTable refreshData={refreshData} />
            </Col>
            <Col xl={4}>
              <WaterWidget />
            </Col>
          </Row>
          <Row className="fluid-div align-items-start">
            <TruckInfo refreshData={refreshData} trucks={trucks} />
          </Row>
        </>
      )}
      {/* @ts-ignore */}
      {selectedTab === 1 && <ZoneReport trucks={trucks} />}
      {selectedTab === 2 && <JobsHistory />}
      {selectedTab === 3 && <WaterTowerInteraction />}
    </Container>
  );
};

export default withAccessControl(DustControl);
