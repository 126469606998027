import React from 'react';
import KeyValueLabels from './KeyValueLabels';
import './styles.scss';
import { BinDetailProps } from './types.js';
import { getFormattedDateAndTime } from '../../utils/helpers';

const BinDetails: React.FC<BinDetailProps> = ({ bin }) => {
  return (
    <div className="pt-3">
      <KeyValueLabels
        value={(bin?.binLevel ?? '0') + '%'}
        title={'Bin Level:'}
      />
      <KeyValueLabels
        value={`${bin.pickupPoint}, ${bin.dumpPoint}, ${bin?.scrapType}`}
        title={'Defaults:'}
      />
      <KeyValueLabels
        value={getFormattedDateAndTime(bin.lastEmptiedTime)}
        title={'Last Emptied (LE):'}
      />
      <KeyValueLabels
        value={getFormattedDateAndTime(bin.lastUpdatedTime)}
        title={'Last Updated (LU):'}
      />
      <KeyValueLabels value={bin?.hazards ?? '-'} title={'Hazards'} />
    </div>
  );
};

export default BinDetails;
