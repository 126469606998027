import { useEffect, useState } from 'react';
import { Image } from 'react-bootstrap';
import ArrowDown from './../../assets/icons/arrowDown.png';
import SearchIcon from './../../assets/icons/Search.svg';
import { convertCodeListToNames } from '../../../dustControl/utils/helper';
import './styles.css';
import { GoDotFill } from 'react-icons/go';

const DropdownField = ({
  name, // name displayed on form
  fieldKey, // accessor key for values/filter object
  selectedOptions, // will be an array if multiselect is true, else will be the value
  allOptions,
  multiselect, // boolean
  handleChange,
  upperLevelClassName = 'd-flex flex-row justify-content-between',
  errorMessage,
  style,
  noSearch,
  textColor = '',
  roundedBordersAndPadding = 'rounded-borders-and-padding',
  customDropdownlayout = '',
  onCheckboxSelectAll = undefined,
}) => {
  const [isDropdownVisible, setDropdown] = useState(false);
  const [dropdownSearch, setDropdownSearch] = useState('');

  const nameWithoutWhiteSpace = name ? name.split(' ').join('') : '';
  useEffect(() => {
    const clickListener = (e) => {
      if (e.target.id !== `keepDropdownOpen${nameWithoutWhiteSpace}`) {
        setDropdown(false);
      }
    };
    document.addEventListener('mousedown', clickListener);

    return () => {
      document.removeEventListener('mousedown', clickListener);
    };
  }, [nameWithoutWhiteSpace]);

  return (
    <div className={upperLevelClassName}>
      {name ? <div className="flex-one">{name}</div> : null}

      <div
        className="d-flex flex-column "
        style={{
          flex: 2,
        }}
      >
        <div className="position-relative">
          {/* open dropdown field */}
          <button
            className={`${roundedBordersAndPadding} ${customDropdownlayout}`}
            id={`keepDropdownOpen${nameWithoutWhiteSpace}`}
            type="button"
            onClick={() => {
              setDropdown(!isDropdownVisible);
            }}
          >
            <div
              className="d-flex justify-content-between px-1"
              id={`keepDropdownOpen${nameWithoutWhiteSpace}`}
            >
              <div className={textColor}>
                {multiselect
                  ? convertCodeListToNames(allOptions, selectedOptions)
                  : allOptions.find(({ value }) => value === selectedOptions)
                      ?.name}
              </div>
              <div id={`keepDropdownOpen${nameWithoutWhiteSpace}`}>
                <Image
                  id={`keepDropdownOpen${nameWithoutWhiteSpace}`}
                  src={ArrowDown}
                  alt="down"
                />
              </div>
            </div>
          </button>
          {isDropdownVisible && (
            <div
              className="mm-hover-card dropdown-position"
              id={`keepDropdownOpen${nameWithoutWhiteSpace}`}
            >
              {noSearch ? null : (
                <div className="border-bottom p-2">
                  <img
                    src={SearchIcon}
                    alt="search"
                    id={`keepDropdownOpen${nameWithoutWhiteSpace}`}
                  />
                  <input
                    placeholder="Search"
                    className="no-focus-border border border-0 ps-1 w-75"
                    onChange={({ target }) => setDropdownSearch(target.value)}
                    id={`keepDropdownOpen${nameWithoutWhiteSpace}`}
                    value={dropdownSearch}
                  />
                </div>
              )}

              <div className="options-dropdown">
                {onCheckboxSelectAll && (
                  <div
                    className="option-row"
                    style={{ alignItems: 'center' }}
                    onClick={() => onCheckboxSelectAll(fieldKey)}
                    id={`keepDropdownOpen${nameWithoutWhiteSpace}`}
                  >
                    <input
                      type="checkbox"
                      checked={selectedOptions?.length === allOptions.length}
                      readOnly
                      id={`keepDropdownOpen${nameWithoutWhiteSpace}`}
                    />
                    <label id={`keepDropdownOpen${nameWithoutWhiteSpace}`}>
                      Select All
                    </label>
                  </div>
                )}
                {allOptions
                  ?.filter(({ name: n }) =>
                    n?.toLowerCase().includes(dropdownSearch?.toLowerCase()),
                  )
                  ?.map(
                    ({
                      name: optionName,
                      value: optionValue,
                      color: optionColor,
                    }) => (
                      <div
                        className="option-row"
                        style={{ alignItems: 'center' }}
                        key={optionValue}
                        onClick={() => {
                          handleChange({ fieldKey, optionValue, multiselect });
                          if (!multiselect) setDropdown(false);
                        }}
                        id={`keepDropdownOpen${nameWithoutWhiteSpace}`}
                      >
                        {multiselect && (
                          <input
                            type="checkbox"
                            checked={selectedOptions?.includes(optionValue)}
                            readOnly
                            id={`keepDropdownOpen${nameWithoutWhiteSpace}`}
                          />
                        )}
                        <label id={`keepDropdownOpen${nameWithoutWhiteSpace}`}>
                          {optionColor && (
                            <GoDotFill
                              size={14}
                              className="me-1"
                              color={optionColor}
                            />
                          )}

                          {optionName}
                        </label>
                      </div>
                    ),
                  )}
              </div>
              {/* dropdown options */}
            </div>
          )}
        </div>
        {errorMessage && (
          <p className="error-msg-manage-operator">{errorMessage}</p>
        )}
      </div>
    </div>
  );
};

export default DropdownField;
