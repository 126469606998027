import React, { useState, useEffect } from 'react';
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  ReferenceLine,
  ResponsiveContainer,
  ReferenceArea,
  Cell,
} from 'recharts';
import moment from 'moment';
import {
  GraphData,
  HourlyGraphData,
  ShiftLoadGraphProps,
} from '../../../../../utils/types';
import { DateTimeFormats } from '../../../constants';

//@ts-ignore
const RectangularBar = ({ fill, x, y, width, height, data, stroke }) => {
  const rectangles = [];

  for (let i = 0; i < data; i++) {
    rectangles.push(
      <rect
        key={i}
        x={x}
        stroke={stroke}
        y={y + (height / data) * i}
        width={width}
        height={height / data}
        fill={fill}
      />,
    );
  }

  return <svg>{rectangles}</svg>;
};

//@ts-ignore
const CustomizedAxisTick = (props) => {
  const { x, y, payload, filterValues, isFirstDay } = props;

  const isLastTick =
    moment().format(DateTimeFormats.DATE) == filterValues.dateRange.from &&
    payload?.value == moment().format('hh:00 A') &&
    isFirstDay;
  return (
    <g transform={`translate(${x},${y})`}>
      <text
        x={0}
        y={0}
        dy={5}
        dx={25}
        textAnchor="end"
        fill={isLastTick ? 'black' : '#666'}
        stroke="black"
        strokeWidth={isLastTick ? 0.75 : 0}
        fontWeight={isLastTick ? '900' : 'normal'}
      >
        {payload?.value}
      </text>
    </g>
  );
};
//@ts-ignore
const CustomizedLabel = (props) => {
  const { x, y, width } = props.viewBox;
  return (
    <text
      x={x + width}
      y={y + 3}
      dx={33}
      textAnchor="end"
      fill={'#666'}
      fontWeight={'bold'}
    >
      Time
    </text>
  );
};

const Hourly: React.FC<ShiftLoadGraphProps<HourlyGraphData>> = ({
  data,
  filterValues,
  children,
  isFirstDay,
}) => {
  return (
    <>
      <ResponsiveContainer width={'99%'} height={250}>
        {data.length > 0 ? (
          <BarChart
            data={data}
            margin={{ top: 5, right: 50, left: 10, bottom: 5 }}
          >
            <CartesianGrid vertical={false} strokeDasharray="3 3" />

            <ReferenceArea
              x1={data[0]?.time}
              alwaysShow={true}
              x2={data[data.length - 1]?.time}
              y1={3}
              fill="#FF00000D"
            />

            <XAxis
              tickMargin={20}
              tickLine={false}
              interval={'preserveStart'}
              label={<CustomizedLabel />}
              stroke="#3E5D90"
              dataKey="time"
              tick={
                <CustomizedAxisTick
                  isFirstDay={isFirstDay}
                  filterValues={filterValues}
                />
              }
              padding={{ left: 5, right: 5 }}
            />

            <YAxis
              tickLine={false}
              domain={[0, (dataMax: number) => Math.max(dataMax, 5)]}
              ticks={Array.from(
                {
                  length: Math.max(...data.map((d) => d.loads), 5) + 1,
                },
                (_, i) => i,
              )}
              padding={{ top: 15 }}
              label={{
                value: 'Loads',
                fontWeight: 'bold',
                angle: -90,
                position: 'insideLeft',
              }}
              tickMargin={10}
              axisLine={false}
            />
            <Bar
              //@ts-ignore
              shape={<RectangularBar />}
              barSize={30}
              dataKey="baseLoads"
              stackId="a"
              strokeWidth={1}
            >
              {data.map((entry, index) => (
                <Cell
                  key={`cell-${index}`}
                  //@ts-ignore
                  data={entry.baseLoads}
                  fill="#ACE5C0"
                  stroke="#EBEBEB"
                />
              ))}
            </Bar>

            <Bar
              //@ts-ignore
              shape={<RectangularBar />}
              barSize={30}
              dataKey="extra"
              stackId="a"
              strokeWidth={1}
            >
              {data?.map((entry, index) => (
                <Cell
                  key={`cell-${index}`}
                  //@ts-ignore
                  data={entry.extra}
                  fill="#F2B5B5"
                  stroke="#EBEBEB"
                />
              ))}
            </Bar>

            <ReferenceLine
              label={{
                value: 'Target',
                fontWeight: 'bold',
                stroke: '#07795F',
                position: 'right',
                strokeWidth: 0.5,
                fill: '#07795F',
              }}
              y={3}
              stroke="#07795F"
            />
          </BarChart>
        ) : (
          <p className="d-flex justify-content-center align-items-center">
            No Hourly data found
          </p>
        )}
      </ResponsiveContainer>
      {children}
    </>
  );
};

export default Hourly;
