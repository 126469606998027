import React, { useEffect, useRef, useState } from 'react';
import { secondsToHhMm } from '../../../../utils/helper';
import { LoadCountdownTimerProps, TimerData } from './types';
import { findLoadSecondsRemaining } from './utils';
import './style.css';

const LoadCountdownTimer: React.FC<LoadCountdownTimerProps> = ({ data }) => {
  const intervalRef = useRef<number | null>(null);
  const [timerData, setTimerData] = useState<TimerData>({
    loadsDue: 3,
    secondsRemaining: 3600,
  });

  useEffect(() => {
    const { secondsRemaining, loadsDue } = findLoadSecondsRemaining(data.loads);
    setTimerData({
      loadsDue: loadsDue,
      secondsRemaining: isNaN(secondsRemaining) ? 0 : secondsRemaining,
    });

    intervalRef.current = window.setInterval(() => {
      setTimerData((prev) => ({
        ...prev,
        secondsRemaining:
          prev.secondsRemaining > 0 ? prev.secondsRemaining - 1 : 0,
      }));
    }, 1000);

    return () => {
      if (intervalRef.current !== null) {
        clearInterval(intervalRef.current);
      }
    };
  }, [data]);

  return (
    <div className="container d-flex align-items-center justify-content-between rounded-2 mt-3 mb-sm-3 mb-lg-0 shadow-sm px-4 py-2">
      <div className="d-flex align-items-center fw-bold timer-loads-due">
        <span className="me-1">{timerData.loadsDue}</span>
        {'load' + (timerData.loadsDue > 1 ? 's' : '')} due in:
      </div>
      <div className="fw-bold timer-time">
        {secondsToHhMm(timerData.secondsRemaining).slice(0, -7)} h
      </div>
    </div>
  );
};

export default LoadCountdownTimer;
