import { FC, useContext, useState } from 'react';
import { getGeneralBinStatus } from '../../../utils/helpers';
import { BinWithPickup, GeneralBinStatus } from '../../../utils/types';
import * as toast from '../../../../dustControl/components/toast';
import { UserContext } from '../../../../pages/service/userContext';
import { API_METHODS } from '../../../utils/constants';
import { Routes } from '../../../utils/routes';
import ConfirmRequest from './confirmRequest';
import { isEmpty } from 'lodash';
import ViewRequestpickup from './viewRequestpickup';
import MissingOrEditRequestPickup from './missingOrEditRequestpickup';

type RequestPickupProps = {
  isOpen: boolean;
  onClose: () => void;
  bin: BinWithPickup;
  initialModal: Modal;
};

export enum Modal {
  MISSING_OR_EDIT_REQUEST = 'missingOrEditRequest',
  CONFIRM = 'confirm',
  VIEW_REQUEST = 'viewRequest',
}

const RequestPickup: FC<RequestPickupProps> = (props) => {
  const { ApiHandler } = useContext(UserContext);
  const [showModal, setShowModal] = useState<Modal>(props.initialModal);
  const [isBinEditable, setIsBinEditable] = useState<boolean>(false);
  const [requestData, setRequestData] = useState({});

  const [loading, setLoading] = useState(false);

  const handleConfirmClick = async () => {
    try {
      setLoading(true);
      await ApiHandler({
        endPoint: Routes.REQUEST_PICKUP,
        method: API_METHODS.POST,
        reqParam: isEmpty(requestData)
          ? {
              binName: props.bin.name,
              dropoffOnly:
                getGeneralBinStatus(props.bin) === GeneralBinStatus.MISSING,
            }
          : requestData,
      });
      props.onClose();
      isBinEditable
        ? toast.success(`Pickup successfully Updated`)
        : toast.success(`Pickup successfully requested`);
    } catch (err) {
      toast.error((err as Error)?.message);
    } finally {
      setLoading(false);
    }
  };

  const handleEditClick = () => {
    setIsBinEditable(true);
    setShowModal(Modal.MISSING_OR_EDIT_REQUEST);
  };

  return (
    <>
      {showModal === Modal.MISSING_OR_EDIT_REQUEST ? (
        <MissingOrEditRequestPickup
          bin={props.bin}
          loading={loading}
          handleConfirmClick={handleConfirmClick}
          isBinEditable={isBinEditable}
          setShowModal={setShowModal}
          isOpen={props.isOpen}
          onClose={props.onClose}
          setRequestData={setRequestData}
        />
      ) : showModal === Modal.VIEW_REQUEST ? (
        <ViewRequestpickup
          handleEditClick={handleEditClick}
          bin={props.bin}
          isOpen={props.isOpen}
          onClose={props.onClose}
        />
      ) : (
        <ConfirmRequest
          loading={loading}
          handleConfirmClick={handleConfirmClick}
          bin={props.bin}
          isOpen={props.isOpen}
          onClose={props.onClose}
        />
      )}
    </>
  );
};

export default RequestPickup;
