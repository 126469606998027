import { FunctionComponent, useEffect, useMemo, useState } from 'react';
import './styles.css';
import {
  BinLoad,
  HistoryFlyerProps,
  HistoryFlyerComponent,
  HistoryCategory,
  BinPickupJob,
  JobStatus,
} from './types';
import CloseIcon from '@mui/icons-material/Close';
import ArrowForward from '@mui/icons-material/ArrowForwardIos';
import {
  getBinJobs,
  getBinLoads,
  getCompleteJobs,
  getIncompleteJobs,
  getJobStatus,
  getScheduledJobs,
  getTruckJobs,
  getUnscheduledJobs,
} from './helper';
import {
  BinHistoryFlyerSection,
  TruckHistoryFlyerSection,
} from '../../utils/constants';
import { renderDisplayComponentsForFlyer } from './renders';
import { Grid } from '@mui/material';
import { map } from 'lodash';
import { PieChartLegendData } from '../PieChart/types';
import { formatDateDDMMYYYY } from '../../utils/helper';

export const HistoryFlyer: FunctionComponent<HistoryFlyerProps> = (
  props: HistoryFlyerProps,
) => {
  const flyerComponents: HistoryFlyerComponent[] = useMemo(() => {
    if (
      props.historyCategory === HistoryCategory.BINS &&
      props.binHistories!.length > 0
    ) {
      const binLoads: BinLoad[] = getBinLoads(props.binHistories!);
      const binJobs: BinPickupJob[] = getBinJobs(props.binHistories!);
      const binJobStatuses: JobStatus[] = getJobStatus(props.binHistories!);
      const binLoadsComponentForFlyer: HistoryFlyerComponent = {
        title: BinHistoryFlyerSection.BIN_SCRAP_LOADS,
        chartComponentProps: {
          data: binLoads.map((binLoad: BinLoad) => binLoad.binPickups.length),
          legend: {
            data: binLoads.map((binLoad: BinLoad) => ({
              key: `Bin ${binLoad.binName} (${binLoad.scrapType})`,
              value: `${binLoad.binPickups.length}`,
            })),
          },
        },
      };
      const binJobsComponentForFlyer: HistoryFlyerComponent = {
        title: BinHistoryFlyerSection.BIN_JOBS,
        chartComponentProps: {
          data: binJobs.map((binJob: BinPickupJob) => binJob.value),
          legend: {
            data: binJobs.map((binJob: BinPickupJob) => ({
              key: `${binJob.key}`,
              value: `${binJob.value}`,
              color: binJob.colors,
            })),
          },
        },
      };

      const binJobsStatusComponentForFlyer: HistoryFlyerComponent = {
        title: BinHistoryFlyerSection.JOB_STATUS,
        chartComponentProps: {
          data: binJobStatuses.map((jobStatus: JobStatus) => jobStatus.value),
          legend: {
            data: binJobStatuses.map((jobStatus: JobStatus) => ({
              key: `${jobStatus.key}`,
              value: `${jobStatus.value}`,
              color: jobStatus.color,
            })),
          },
        },
      };
      return [
        binLoadsComponentForFlyer,
        binJobsComponentForFlyer,
        binJobsStatusComponentForFlyer,
      ];
    } else {
      const numberOfJobsByTruck: PieChartLegendData[] = getTruckJobs(
        props.binHistories!,
      );
      const completedJobs: PieChartLegendData[] = getCompleteJobs(
        props.binHistories!,
      );
      const incompleteJobs: PieChartLegendData[] = getIncompleteJobs(
        props.binHistories!,
      );
      const scheduledJobs: PieChartLegendData[] = getScheduledJobs(
        props.binHistories!,
      );
      const unscheduledJobs: PieChartLegendData[] = getUnscheduledJobs(
        props.binHistories!,
      );

      const numberOfJobsByTruckComponentForFlyer: HistoryFlyerComponent = {
        title: TruckHistoryFlyerSection.TRUCK_JOBS,
        chartComponentProps: {
          data: map(numberOfJobsByTruck, (truckData) =>
            parseInt(truckData.value),
          ),
          legend: { data: numberOfJobsByTruck },
        },
      };

      const completedJobsComponentForFlyer: HistoryFlyerComponent = {
        title: TruckHistoryFlyerSection.COMPLETED_JOBS,
        chartComponentProps: {
          data: map(completedJobs, (truckData) => parseInt(truckData.value)),
          legend: { data: completedJobs },
        },
      };

      const incompleteJobsComponentForFlyer: HistoryFlyerComponent = {
        title: TruckHistoryFlyerSection.INCOMPLETE_JOBS,
        chartComponentProps: {
          data: map(incompleteJobs, (truckData) => parseInt(truckData.value)),
          legend: { data: incompleteJobs },
        },
      };

      const scheduledJobsComponentForFlyer: HistoryFlyerComponent = {
        title: TruckHistoryFlyerSection.SCHEDULED_JOBS,
        chartComponentProps: {
          data: map(scheduledJobs, (truckData) => parseInt(truckData.value)),
          legend: { data: scheduledJobs },
        },
      };

      const unscheduledJobsComponentForFlyer: HistoryFlyerComponent = {
        title: TruckHistoryFlyerSection.UNSCHEDULED_JOBS,
        chartComponentProps: {
          data: map(unscheduledJobs, (truckData) => parseInt(truckData.value)),
          legend: { data: unscheduledJobs },
        },
      };

      return [
        numberOfJobsByTruckComponentForFlyer,
        completedJobsComponentForFlyer,
        incompleteJobsComponentForFlyer,
        scheduledJobsComponentForFlyer,
        unscheduledJobsComponentForFlyer,
      ];
    }
  }, [props]);

  return (
    <Grid
      container
      className={'history-flyer-container position-relative'}
      direction={'row'}
    >
      <button
        className="flyer-toggle-button"
        onClick={() => {
          props.setShowHistoryFlyer(!props.showHistoryFlyer);
        }}
      >
        {props.showHistoryFlyer ? <CloseIcon /> : <ArrowForward />}
      </button>

      <Grid item xs={props.showHistoryFlyer ? 12 : 0}>
        {props.showHistoryFlyer ? (
          renderDisplayComponentsForFlyer(flyerComponents)
        ) : (
          <></>
        )}
      </Grid>

      {props.showHistoryFlyer && (
        <div style={{ fontSize: '14px' }}>
          <hr className="flyerBottomtextHr" />
          Displaying data for {formatDateDDMMYYYY(props.date.from)}{' '}
          {props.date.to ? `- ${formatDateDDMMYYYY(props.date.to)}` : ''}
        </div>
      )}
    </Grid>
  );
};
