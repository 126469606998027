import {
  BinPickup,
  BinWithPickup,
  PickupEventType,
  TruckData,
} from '../../utils/types';

export type TrackBinProps = {
  isOpen: boolean;
  onClose: () => void;
  binName: string;
  binPickup?: BinPickup;
  truck?: TruckData;
  fromHistoryTable?: boolean;
};

export type BinDetailProps = {
  bin: BinWithPickup;
};
export type JobCycleProps = {
  pickup: BinPickup;
};

export type JobDetailProps = {
  pickup?: BinPickup;
  isTruckSection: boolean;
};

export type TruckSubSectionProps = {
  pickup?: BinPickup;
  truck: TruckData;
};

export type KeyValueLabelProps = {
  title: string;
  value: string | React.ReactElement;
  subValue?: string;
};

export type PieChartDataStructure = {
  title: string;
  time: string;
  duration: number;
};

export type LegendProps = {
  status: string;
  label: string;
};

export type PickupChangeData = {
  type: PickupEventType;
  pickupReferenceNumber: string;
  location: string;
  startTime: Date;
  endTime?: Date;
  pickupDocument: BinPickup;
};

export enum TimelineStatus {
  CURRENT = 'current',
  COMPLETED = 'completed',
  DISCONNECTED = 'disconnected',
  UPCOMING = 'upcoming',
}

export type SubsectionField = {
  title: string;
  value: React.ReactElement | string;
  subvalue?: string;
};
