import './styles.scss';

const TextArea = ({ placeholder, value, onChange, numOfLines }) => {
  return (
    <div className="w-100 position-relative ">
      <div className={`w-100 bin_text-area`}>
        <textarea
          placeholder={placeholder ?? ''}
          value={value}
          onChange={(e) => onChange(e.target.value)}
          className={`bin_text-area-inside`}
          rows={numOfLines}
        />
      </div>
    </div>
  );
};

export default TextArea;
