import { TruckRowProps } from './types';
import './styles.scss';
import StatusDot from '../../../dustControl/components/StatusDot';
import { NA, truckColorMapping } from '../../utils/constants';
import { convertBinLastActionTime } from '../Details/helper';
import { BinWithPickup, TruckStatusForList } from '../../utils/types';
import {
  getBinDetailedStatusLabel,
  getDetailedBinStatus,
} from '../../utils/helpers';
import { useEffect, useState } from 'react';
import { useScrapBins } from '../../context/scranBinsContext';

export const TruckRow: React.FunctionComponent<
  TruckRowProps & { backgroundColor: string; titleColor: string; title: string }
> = ({
  truck,
  isFirstItem,
  onSelectRow,
  isSelected,
  backgroundColor,
  titleColor,
  title,
}) => {
  const truckColor = truckColorMapping[truck.status] || 'black';
  const { bins } = useScrapBins();

  const rowStyle = {
    '--group-title-color': titleColor,
    borderTop: isFirstItem ? '0px solid #E2E2E2' : '1px solid #E2E2E2',
    backgroundColor: isSelected ? '#2b2d2f' : backgroundColor,
  };

  const handleSelectRow = () => {
    onSelectRow(truck); // Call onSelectRow prop function with the bin data
  };
  const [associatedBin, setAssociatedBin] = useState<BinWithPickup | null>(
    null,
  );

  useEffect(() => {
    const findAssociatedBin = () => {
      if (truck) {
        const matchedBin = bins.find(
          (bin: BinWithPickup) => bin.name === truck.bin,
        );
        setAssociatedBin(matchedBin || null);
      } else {
        setAssociatedBin(null);
      }
    };
    findAssociatedBin();
  }, []);

  return (
    <div
      className={`item-row ${isFirstItem ? 'item-row-first' : ''} ${
        isSelected ? 'selected' : ''
      }`}
      style={rowStyle}
      onClick={handleSelectRow}
    >
      <div className="item-row-top">
        <b>{truck.name}</b>
        <StatusDot style={{ background: truckColor }} className="large">
          <span className="fw-bold">{truck?.status}</span>
        </StatusDot>
      </div>
      <div className="item-row-bottom">
        <i>
          {title === TruckStatusForList.inProgress
            ? associatedBin &&
              getBinDetailedStatusLabel(getDetailedBinStatus(associatedBin))
            : NA}
        </i>
        {truck.lastLoad !== undefined &&
          `${'LL'} - ${convertBinLastActionTime(truck.lastLoad)}`}
      </div>
    </div>
  );
};
