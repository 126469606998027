import moment from 'moment';
import { PDFDocument } from 'pdf-lib';
import React, { useContext, useEffect, useState } from 'react';
import { Image } from 'react-bootstrap';
import SubHeader from '../dustControl/components/SubHeader';
import * as toast from '../dustControl/components/toast';
import { API_METHODS, ENDPOINTS } from '../dustControl/utils/constants';
import { UserContext } from '../pages/service/userContext';
import { withAccessControl } from '../pages/service/with-access-control';
import CategoryWidget from './Components/CategoryWidget';
import PdfModal from './Components/PdfModal';
import SummaryWidget from './Components/SummaryWidget';
import Report1 from './Components/reports/report-1';
import Summary from './Summary';
import TableComponent from './TableComponent';
import ArrowDown from './assets/icons/arrow-white.svg';

const PreStartChecks = () => {
  const { ApiHandler, departments, licenses, getDepartmentsAndLicenses } =
    useContext(UserContext);

  const getCategoryName = (truckType) => {
    if (licenses?.length <= 0) {
      getDepartmentsAndLicenses();
    }
    const license = licenses.find((license) => license.code === truckType);

    if (license) {
      return license.name;
    } else {
      return 'cat_not_found';
    }
  };

  const getDeptname = (truckType) => {
    const license = licenses.find((license) => license.code === truckType);
    const dept = departments.find(
      (dept) => license.departmentCode === dept.code,
    );
    if (dept) {
      return { name: dept.name, code: dept.code };
    } else {
      return 'dept_not_found';
    }
  };

  const [sortBy, setSortBy] = useState('department');
  const [rowData, setRowData] = useState({ showModal: false });
  const [showCharts, setShowCharts] = useState(true);

  const deptAllCodes = [];

  departments?.forEach((dept) => {
    const lIndex = licenses.findIndex(
      (license) => license?.departmentCode === dept?.code,
    );
    if (lIndex !== -1) {
      deptAllCodes.push(dept.code);
    }
  });

  const [deptType, setDeptType] = useState(deptAllCodes);
  const [categoryType, setCategoryType] = useState(
    licenses?.map((l) => {
      return l.code;
    }),
  );

  const [statusType, setStatusType] = useState([
    'passed',
    'failed',
    'incomplete',
  ]);
  const [summaryModal, setSummayModal] = useState(false);
  const [searchQuery, setSearchQuery] = useState('');

  const [loading, setLoading] = useState(false);
  const [reApplyFilter, setReApplyFilter] = useState(1);
  const [selectedDate, setSelectedDate] = useState(
    moment().format('yyyy-MM-DD'),
  );
  const [preStartData, setPreStartData] = useState([]);
  const [data, setData] = useState([]);
  const [summary, setSummary] = useState({
    failed: 0,
    passed: 0,
    incomplete: 0,
  });
  const [categoryData, setCategoryData] = useState([]);
  const handleSortChange = (event) => {
    setSortBy(event.target.value);
  };

  const handleChange = async (dm, source) => {
    let t_summary = [0, 0, 0]; //[failed, passed, incomplete]
    let catData = [];
    const temp = dm.map((d, i) => {
      let fault = 0;
      function getStatusIndex(status) {
        return ['passed', 'failed', 'incomplete'].indexOf(status);
      }
      for (const status of ['failed', 'passed', 'incomplete']) {
        if (d.status === status) {
          t_summary[getStatusIndex(status)]++;

          // Check if the category exists in catData, otherwise add it
          const categoryName = getCategoryName(d?.truck?.type, licenses);
          const categoryIndex = catData.findIndex(
            (cat) => cat.name === categoryName,
          );

          if (categoryIndex === -1) {
            catData.push({ name: categoryName, data: [0, 0, 0] });
          }

          // Update data in catData
          const updatedCategoryIndex = catData.findIndex(
            (cat) => cat.name === categoryName,
          );
          catData[updatedCategoryIndex].data[getStatusIndex(status)]++;
        }
      }
      d?.checkpoints?.map((c) => {
        if (c.answer == 'faulty') fault++;
      });

      const dept = getDeptname(d?.truck?.type, departments, licenses);
      return {
        ...d,
        assetID: d?.truck?.name,
        failedChecks: `${fault}/${d?.checkpoints?.length}`,
        supervisorSignature: d?.supervisor?.signedName,
        department: dept.name,
        deptCode: dept.code,
        category: getCategoryName(d?.truck?.type, licenses),
      };
    });
    setSummary({
      failed: t_summary[0],
      passed: t_summary[1],
      incomplete: t_summary[2],
    });
    setCategoryData(catData);
    if (source != true) {
      setPreStartData(temp);
      setData(temp);
    }
  };

  const fetch = async (applyFilter) => {
    try {
      const response = await ApiHandler({
        endPoint: ENDPOINTS.getPrestartChecks,
        method: API_METHODS.PUT,
        reqParam: {
          date: selectedDate,
        },
      });

      await handleChange(response.data);
      if (applyFilter) {
        setReApplyFilter(reApplyFilter + 1);
      }
    } catch (err) {
      console.log(err);
      toast.error(err?.message);
    }
  };

  const filterStatusData = (data) => {
    if (statusType && statusType.length > 0) {
      const filteredData = data.filter((d) => statusType?.includes(d.status));
      return filteredData;
    } else {
      return data;
    }
  };

  const filterCategoryData = (data) => {
    if (categoryType && categoryType.length > 0) {
      const filteredData = data.filter((d) =>
        categoryType?.includes(d?.truck?.type),
      );
      return filteredData;
    } else {
      return data;
    }
  };

  const filterDeptData = (data) => {
    if (deptType && deptType.length > 0) {
      const filteredData = data.filter((d) => deptType?.includes(d?.deptCode));
      return filteredData;
    } else {
      return data;
    }
  };

  const filterData = (data, searchQuery) => {
    return data.filter(
      (p) =>
        p.assetID.includes(searchQuery) ||
        (p.operator?.first_name + ' ' + p.operator?.last_name)
          ?.toLowerCase()
          .includes(searchQuery?.toLowerCase()),
    );
  };

  useEffect(() => {
    const filteredData = filterData(
      filterCategoryData(filterDeptData(filterStatusData(data))),
      searchQuery,
    );

    const handleChangeAsync = async (data) => {
      await handleChange(data, true);
    };

    handleChangeAsync(filteredData);
  }, [deptType, statusType, categoryType, searchQuery, reApplyFilter]);

  useEffect(() => {
    const filteredData = filterData(
      filterCategoryData(filterDeptData(filterStatusData(data))),
      searchQuery,
    );

    setPreStartData(filteredData);
  }, [deptType, statusType, data, searchQuery, categoryType]);

  useEffect(() => {
    setData([]);
    setPreStartData([]);
    fetch(true);
    setCategoryData([]);
  }, [selectedDate]);

  const onCreate = async (id) => {
    try {
      setLoading(true);
      const response = await ApiHandler({
        endPoint: ENDPOINTS.getReportPdf,
        method: API_METHODS.PUT,
        reqParam: {
          date: selectedDate,
          id: id,
        },
        blobType: true,
      });

      const url = window.URL.createObjectURL(new Blob([response?.data]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', 'report.pdf');
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      setLoading(false);
    } catch (error) {
      console.log(error);
      toast.error(error?.message);
      setLoading(false);
    }
  };

  const onSummaryDownload = async (id) => {
    try {
      setLoading(true);
      const response = await ApiHandler({
        endPoint: ENDPOINTS.getsummarypdf,
        method: API_METHODS.PUT,
        reqParam: {
          date: selectedDate,
          dept: deptType,
          category: categoryType,
          status: statusType,
          search: searchQuery,
        },
        blobType: true,
      });
      const pdfBytes = response?.data;
      const pdfDoc = await PDFDocument.load(pdfBytes);
      const lastPageIndex = pdfDoc.getPageCount() - 1;
      pdfDoc.removePage(lastPageIndex);
      const modifiedPdfBytes = await pdfDoc.save();
      const url = window.URL.createObjectURL(new Blob([modifiedPdfBytes]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', 'summary.pdf');
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      setLoading(false);
    } catch (error) {
      setLoading(false);
      console.log(error);
      toast.error(error?.message);
    }
  };

  return (
    <div style={{ height: '92vh', overflow: 'hidden' }}>
      <SubHeader
        hideSearch
        SubHeaderVisible={true}
        selectedDate={selectedDate}
        setSelectedDate={setSelectedDate}
        onDeptChange={(v) => {
          setDeptType(v);
        }}
        onCategoryChange={(v) => {
          setCategoryType(v);
        }}
        showDownload
        onDownloadClick={() => {
          setSummayModal(true);
        }}
        status
        onStatusChange={(v) => {
          setStatusType(v);
        }}
        date
        department
        category
        outerClassName="mb-2"
      />
      {showCharts && (
        <div className="d-flex">
          <div className="col-6" style={{ paddingLeft: 0, paddingRight: 0 }}>
            <SummaryWidget data={summary} />
          </div>
          <div className="col-6" style={{ paddingLeft: 0, paddingRight: 0 }}>
            <CategoryWidget
              closeWidgets={() => {
                setShowCharts(false);
              }}
              data={categoryData}
            />
          </div>
        </div>
      )}
      {!showCharts && (
        <div
          className="w-100 widget-container px-3 d-flex justify-content-between align-items-center p-0"
          style={{
            height: '45px',
            background: '#F7F7F7 0% 0% no-repeat padding-box',
          }}
          onClick={() => setShowCharts(true)}
        >
          <p className="text-2 fw-bold">VIEW SUMMARY</p>
          <div
            className="d-flex me-1 align-items-center justify-content-center"
            style={{
              width: '22px',
              height: '16px',
              backgroundColor: '#2B2D2F',
            }}
          >
            <Image className="" src={ArrowDown} alt="down" />
          </div>
        </div>
      )}
      {rowData.showModal && (
        <PdfModal
          onDownload={() => {
            onCreate(rowData._id);
          }}
          closeModal={() =>
            setRowData({
              showModal: false,
            })
          }
          loading={loading}
        >
          <div className="report-outer-view">
            <Report1 id={rowData._id} date={selectedDate} fetchParent={fetch} />
          </div>
        </PdfModal>
      )}
      {summaryModal && (
        <PdfModal
          onDownload={() => {
            onSummaryDownload('print-summary');
          }}
          loading={loading}
          closeModal={() => setSummayModal(false)}
        >
          <div className="report-outer-view">
            <Summary
              selectedDate={selectedDate}
              searchq={searchQuery}
              dept={deptType}
              category={categoryType}
              status={statusType}
            ></Summary>
          </div>
        </PdfModal>
      )}

      <div className="align-padding">
        <div className="d-flex justify-content-between align-items-center ">
          <div className="d-flex align-items-center">
            <div className="d-flex align-items-center me-3 ">
              <input
                type="radio"
                value="department"
                className="radio-sort"
                checked={sortBy === 'department'}
                onChange={handleSortChange}
              />
              <div style={{ color: '#092344' }} className="ms-2 text-1">
                Sort by department
              </div>
            </div>
            <div className="d-flex align-items-center">
              <input
                type="radio"
                value="category"
                className="radio-sort"
                checked={sortBy === 'category'}
                onChange={handleSortChange}
              />
              <div style={{ color: '#092344' }} className="ms-2 text-1">
                Sort by category
              </div>
            </div>
          </div>
          <div className="mb-2">
            {' '}
            <SubHeader
              onSearchChange={(e) => {
                setSearchQuery(e.target.value);
              }}
              SubHeaderVisible={true}
            />
          </div>
        </div>

        <TableComponent
          onRowClick={(row) => {
            setRowData({ ...row, showModal: true });
          }}
          sortBy={sortBy}
          data={[...preStartData]}
          showCharts={showCharts}
        />
      </div>
    </div>
  );
};

export default withAccessControl(PreStartChecks);
