import moment from 'moment';
import { BinsGroupedItemList, GroupedItem } from '../components/List/types';
import {
  MAP_AREA_LATITUDE_MAX,
  MAP_AREA_LATITUDE_MIN,
  MAP_AREA_LONGITUDE_MAX,
  MAP_AREA_LONGITUDE_MIN,
  detailedBinStatusColors,
  detailedBinStatusLabels,
  generalBinStatusColors,
  generalBinStatusColorsTitles,
  generalBinStatusBackgroundColors,
  truckStatusColors,
  TRUCK_FILTER_VALUES,
  TRUCK_FILTERS,
  truckBackgrounsColors,
} from './constants';
import {
  BinWithPickup,
  CoordinatesAndItem,
  DetailedBinStatus,
  GeneralBinStatus,
  GetAllTrucksData,
  Location,
  MODULES,
  PickupEventType,
  PickupStatus,
  TruckData,
  TruckStatusForList,
} from './types';
import { clone, includes, isEmpty, keys } from 'lodash';

export const getListOfCoordinates = (
  binOrTruckData: GetAllTrucksData | BinWithPickup[],
  module: MODULES,
): CoordinatesAndItem[] | undefined => {
  let coordinatesList: CoordinatesAndItem[] = [];

  if (module === MODULES.TRUCKS) {
    const trucksData = binOrTruckData as GetAllTrucksData;
    Object.entries(trucksData).forEach(
      ([availability, truckArray]: [string, TruckData[] | null]) => {
        if (!truckArray) return;
        truckArray.forEach((truck: TruckData) => {
          const coordinates = truck.lastLocation;
          coordinatesList.push({
            coordinates: {
              latitude: coordinates.lat,
              longitude: coordinates.long,
            },
            item: {
              ...truck,
              availability:
                TruckStatusForList[
                  availability as keyof typeof TruckStatusForList
                ],
            },
          });
        });
      },
    );
  } else if (module === MODULES.BINS) {
    const binsWithPickup = binOrTruckData as BinWithPickup[];
    binsWithPickup.forEach((bin) => {
      // get the last event that has coordinates
      if (!bin.lastPickup) return;
      const reversedEvents = [...bin.lastPickup.events].reverse();
      const eventWithCoordinates = reversedEvents.find(
        ({ lat, long }) => lat && long,
      );
      if (eventWithCoordinates?.lat && eventWithCoordinates?.long) {
        coordinatesList.push({
          coordinates: {
            latitude: eventWithCoordinates.lat,
            longitude: eventWithCoordinates.long,
          },
          item: bin,
        });
      }
    });
  }

  return coordinatesList;
};

export const allCoordinatesInsideMapArea = (
  coordinates: CoordinatesAndItem[],
): boolean => {
  for (const {
    coordinates: { latitude, longitude },
  } of coordinates) {
    if (
      latitude > MAP_AREA_LATITUDE_MAX ||
      latitude < MAP_AREA_LATITUDE_MIN ||
      longitude > MAP_AREA_LONGITUDE_MAX ||
      longitude < MAP_AREA_LONGITUDE_MIN
    ) {
      return false; // Coordinates are outside the map area
    }
  }

  return true; // All coordinates are within the map area
};

export const getBinDetailedStatusLabel = (binStatus: DetailedBinStatus) =>
  detailedBinStatusLabels[binStatus];

export const getBinColorByDetailedStatus = (binStatus: DetailedBinStatus) => {
  return detailedBinStatusColors[binStatus];
};

export const getBinColorByGeneralStatus = (binStatus: GeneralBinStatus) => {
  return generalBinStatusColors[binStatus];
};

export const getBinColorByGeneralStatusTitles = (
  binStatus: GeneralBinStatus,
) => {
  return generalBinStatusColorsTitles[binStatus];
};

export const getBinBackgroundColorByGeneralStatus = (
  binStatus: GeneralBinStatus,
) => {
  return generalBinStatusBackgroundColors[binStatus];
};

export const getTruckColorStatus = (truckStatus: TruckStatusForList) => {
  return truckStatusColors[truckStatus];
};

export const getTruckBackgroundStatus = (truckStatus: TruckStatusForList) => {
  return truckBackgrounsColors[truckStatus];
};

export const getDetailedBinStatus = (bin: BinWithPickup): DetailedBinStatus => {
  if (bin) {
    if (bin.lastPickup?.status === 'inProgress') {
      if (
        bin.lastPickup.events.find(
          (event) => event.type === PickupEventType.DUMP && event.endTime,
        )
      ) {
        return DetailedBinStatus.DUMPED;
      } else if (
        bin.lastPickup.events.find(
          (event) => event.type === PickupEventType.PICKUP && event.endTime,
        )
      ) {
        return DetailedBinStatus.PICKED_UP;
      } else return DetailedBinStatus.PICK_UP_SCHEDULED;
    }
    if (isBinMissing(bin) && bin.pickupRequest?.time) {
      return DetailedBinStatus.PICK_UP_REQUESTED;
    }
    if (bin.pickupRequest?.time) {
      return DetailedBinStatus.PICK_UP_REQUESTED;
    }

    if (isBinMissing(bin)) {
      return DetailedBinStatus.BIN_MISSING;
    }

    if (bin.lastPickup?.status === 'complete') {
      if (bin.binLevel === 0) {
        return DetailedBinStatus.JOB_COMPLETE;
      }
      if (bin.binLevel > 0) {
        return DetailedBinStatus.BACK_AT_SOURCE;
      }
    }
    if (bin.lastPickup?.status === 'incomplete') {
      if (bin.binLevel === 0) {
        return DetailedBinStatus.JOB_INCOMPLETE;
      }
      if (
        bin.lastPickup?.events?.length &&
        bin.lastPickup?.events[bin.lastPickup?.events?.length - 1]?.type !==
          'delivered'
      ) {
        return DetailedBinStatus.JOB_INCOMPLETE;
      }
      if (bin.binLevel > 0) {
        return DetailedBinStatus.BACK_AT_SOURCE;
      }
    }
  }
  return DetailedBinStatus.BACK_AT_SOURCE;
};

export const isBinMissing = (bin: BinWithPickup) => {
  if (
    bin.lastPickup?.events?.length &&
    bin.lastLocation &&
    bin.lastPickup?.events[bin.lastPickup?.events?.length - 1]?.type ===
      'delivered' &&
    bin.dropoffPoint !== bin.lastLocation.zone
  ) {
    return true;
  } else return false;
};

export const getGeneralBinStatus = (bin: BinWithPickup) => {
  if (bin.lastPickup?.status === PickupStatus.IN_PROGRESS) {
    return GeneralBinStatus.IN_PROGRESS;
  } else if (bin.pickupRequest) return GeneralBinStatus.REQUESTED;
  else if (isBinMissing(bin)) return GeneralBinStatus.MISSING;
  else if (bin.binLevel === 0) return GeneralBinStatus.EMPTY;
  else if (bin.binLevel <= 25) return GeneralBinStatus.TWENTY_FIVE;
  else if (bin.binLevel <= 50) return GeneralBinStatus.FIFTY;
  else if (bin.binLevel <= 75) return GeneralBinStatus.SEVENTY_FIVE;
  else if (bin.binLevel <= 100) return GeneralBinStatus.HUNDRED;
  else return GeneralBinStatus.EMPTY;
};

export const countBinStatus = (
  bins: BinWithPickup[],
  status: string,
): number => {
  return bins.reduce((accum, bin) => {
    if (status === getGeneralBinStatus(bin)) return accum + 1;
    else return accum;
  }, 0);
};

export const countTruckStatus = (
  truckData: GetAllTrucksData,
  status: string,
): number => {
  if (includes(TRUCK_FILTER_VALUES['Truck Availability'], status)) {
    const data = truckData[status as keyof GetAllTrucksData];
    if (!data) return 0;
    return data.length;
  } else {
    return keys(truckData).reduce((accum, key) => {
      const trucks: TruckData[] | undefined =
        truckData[key as keyof GetAllTrucksData];
      return (
        accum +
        (trucks?.reduce((acc, data) => {
          if (status === data.status) return acc + 1;
          else return acc;
        }, 0) || 0)
      );
    }, 0);
  }
};

export const getTruckText = (
  data: GetAllTrucksData,
  value: string,
): 'Truck' | 'Trucks' => {
  const count = countTruckStatus(data, value);
  return count === 1 ? 'Truck' : 'Trucks';
};

export const groupBinsByStatus = (bins: BinWithPickup[]): GroupedItem[] => {
  const groupedBins: (GroupedItem & BinsGroupedItemList)[] = [];
  bins.forEach((bin: BinWithPickup) => {
    const status = getGeneralBinStatus(bin);

    // Check if a group with the same title (binLevel) already exists
    const existingGroup = groupedBins.find((group) => group.title === status);

    if (existingGroup) {
      // If the group already exists, add the bin to its data array
      existingGroup.data.push(bin);
    } else {
      // If the group doesn't exist, create a new group
      const item: GroupedItem = {
        title: status,
        titleColor: getBinColorByGeneralStatusTitles(status),
        backgroundColor: getBinBackgroundColorByGeneralStatus(status), // TODO
        selectedColor: '#2B2D2F',
        module: MODULES.BINS,
        data: [bin],
      };

      groupedBins.push(item);
    }
  });
  return groupedBins;
};

export const addColorsToGroupedTrucks = (
  allTruckData: GetAllTrucksData,
): GroupedItem[] => {
  const groupedTrucks: GroupedItem[] = Object.entries(allTruckData).map(
    (entries) => {
      const title = entries[0] as keyof typeof TruckStatusForList;
      const status = TruckStatusForList[title];
      const truckData: TruckData[] = entries[1];
      const item: GroupedItem = {
        title: status,
        titleColor: getTruckColorStatus(status), // Adjust color as needed
        backgroundColor: getTruckBackgroundStatus(status), // Adjust color as needed
        selectedColor: '#CCC', // Adjust color as needed
        data: truckData,
        module: MODULES.TRUCKS,
      };
      return item;
    },
  );

  return groupedTrucks;
};

export const getFilterData = (filter: URLSearchParams) => {
  const binFilter: string = filter.get('b') || '';
  const binFilterQueries = binFilter
    .split(',')
    .filter((filter) => Boolean(filter));
  const truckFilter: string = filter.get('t') || '';
  const truckFilterQueries = truckFilter
    .split(',')
    .filter((filter) => Boolean(filter));

  return { binFilterQueries, truckFilterQueries };
};
export const getDurationAgo = (endTime?: Date | string) => {
  // Ensure endTime is valid
  if (!endTime) return '-';

  // Calculate the difference in milliseconds
  const now = moment.utc();
  const end = moment.utc(endTime);
  const duration = moment.duration(now.diff(end));

  // Check if the difference is greater than one day
  if (duration.asDays() >= 1) {
    // Return formatted string for days
    return `${Math.ceil(duration.asDays())} days ago`;
  } else {
    // Return formatted string for hours and minutes
    return `${duration.hours().toString().padStart(2, '0')}:${duration
      .minutes()
      .toString()
      .padStart(2, '0')} h ago`;
  }
};

export const calculateEventDuration = (
  startTime: Date | string,
  endTime: Date | string,
) => {
  const start = moment.utc(startTime);
  const end = moment.utc(endTime);
  const duration = moment.duration(end.diff(start));
  const hours = Math.floor(duration.asHours());
  const minutes = duration.minutes();

  return {
    time: `${hours.toString().padStart(2, '0')}:${minutes
      .toString()
      .padStart(2, '0')} h`,
    duration: duration.asSeconds(),
  };
};

export const createFormData = (
  title: string,
  value: string,
  name: string,
  id: number,
  type: string,
  options?: { value: string; name: string }[],
) => ({ title, value, id, type, options, name });

export const createDropDownField = (field: string) => ({
  name: field,
  value: field,
});

export const createHeader = (binName: string, isBinEditable: boolean) => {
  if (isBinEditable) return `Edit Pickup Request - Bin ${binName}`;
  else return `Request Pickup - Bin ${binName}`;
};

export const displayButtonText = (isBinEditable: boolean) => {
  if (isBinEditable) return 'Save Changes';
  return 'Request pickup';
};

export const applyTruckFilters = (
  trucks: GetAllTrucksData,
  truckFilterQueries: string[],
): GetAllTrucksData => {
  if (isEmpty(truckFilterQueries)) return trucks;
  if (truckFilterQueries.length === TRUCK_FILTERS.length) return trucks;

  // separate out Availability and Status filters
  const statusFilters: string[] = [];
  const availabilityFilters: (keyof GetAllTrucksData)[] = [];
  truckFilterQueries.forEach((item) => {
    if (TRUCK_FILTER_VALUES['Truck Availability'].includes(item)) {
      availabilityFilters.push(item as keyof GetAllTrucksData);
    } else if (TRUCK_FILTER_VALUES['Truck Status'].includes(item)) {
      statusFilters.push(item);
    }
  });

  let filteredTrucks: GetAllTrucksData = {};
  // apply availability filters first
  // this allows us to treat availabilties and statuses as or conditions
  if (isEmpty(availabilityFilters)) {
    filteredTrucks = clone(trucks);
  } else {
    availabilityFilters.forEach((availability: keyof GetAllTrucksData) => {
      filteredTrucks[availability] = trucks[availability];
    });
  }

  // then apply status filters onto the already filtered data
  if (isEmpty(statusFilters)) return filteredTrucks;
  for (const truckStatus of keys(
    filteredTrucks,
  ) as (keyof GetAllTrucksData)[]) {
    filteredTrucks[truckStatus] = filteredTrucks[truckStatus]?.filter((truck) =>
      includes(statusFilters, truck.status),
    );
  }
  return filteredTrucks;
};

export const getLastLocation = (location?: Partial<Location>) => {
  if (location?.zone) return location.zone;
  if (!location?.lat && !location?.long) return '-';
  else return `${location.lat}, ${location.long}`;
};

export const getDateWithTimeAgo = (date?: string | Date) => {
  if (!date) return '-';
  return `${getFormattedDateAndTime(date)} (${getDurationAgo(date)})`;
};

export const getFormattedDateAndTime = (date?: string | Date) => {
  if (!date) return '-';
  return moment(date).format('DD/MM/YYYY, hh:mm A');
};

export const getFormattedTime = (date?: string | Date) => {
  if (!date) return 'N/A';
  return moment(date).format('hh:mm A');
};

// Gets the indiviual truck from the nested array of AllTrucksData
export const findTruckDataByName = (
  name?: string,
  allTrucksData?: GetAllTrucksData,
): TruckData | undefined => {
  if (!name || !allTrucksData) return undefined;
  for (const truckArray of Object.values(allTrucksData)) {
    if (truckArray) {
      const foundTruck = truckArray.find(
        (truck: TruckData) => truck.name === name,
      );
      if (foundTruck) {
        return foundTruck;
      }
    }
  }
  return undefined; // Return undefined if no truck is found with the given name
};

export const getErrorMessage = (error: unknown): string => {
  if (typeof error === 'object' && error && 'message' in error) {
    return error.message as string;
  } else return 'Something went wrong while processing this request';
};

export const convertMinsToHHMM = (minutes: number = 0): string => {
  const roundedMinutes: number = Math.round(minutes);
  const hrs = Math.floor(roundedMinutes / 60)
    .toString()
    .padStart(2, '0');
  const mins = (roundedMinutes % 60).toString().padStart(2, '0');

  return `${hrs}:${mins}`;
};

// because sorted data can either be an array of data or a nested array
// sometimes we need to flatmap the data to get the data length
export const getSortedDataLength = (
  dataArray: GroupedItem[] | TruckData[] | BinWithPickup[],
): number => {
  return dataArray.flatMap((groupedItem) =>
    'title' in groupedItem // this means groupedItem is of type GroupedItem
      ? groupedItem.data
      : [groupedItem],
  ).length;
};

export const getBinJobStatusCompleteIncomplete = (bin: BinWithPickup) => {
  if (bin.lastPickup?.status === 'complete') {
    return DetailedBinStatus.JOB_COMPLETE;
  } else if (bin.lastPickup?.status === 'incomplete') {
    return DetailedBinStatus.JOB_INCOMPLETE;
  } else return DetailedBinStatus.STATUS_UNAVAILABLE;
};
