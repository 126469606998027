import React from 'react';
import './styles.scss';

export const getBinColorByLevel = (binLevel) => {
  if (binLevel === 0) {
    return { backgroundColor: '#E2E2E2', borderColor: '#7B7B7B' };
  } else if (binLevel > 0 && binLevel <= 25) {
    return { backgroundColor: '#64E5D1', borderColor: '#21937D' };
  } else if (binLevel > 25 && binLevel <= 50) {
    return { backgroundColor: '#C4ED3D', borderColor: '#749B3D' };
  } else if (binLevel > 50 && binLevel <= 75) {
    return { backgroundColor: '#FFAF36', borderColor: '#C16F00' };
  } else if (binLevel > 75 && binLevel <= 100) {
    return { backgroundColor: '#FF6A6A', borderColor: '#BE3737' };
  } else {
    return { backgroundColor: '#E2E2E2', borderColor: '#7B7B7B' };
  }
};

const BinLevelTile = ({ level, isSelected, onClick }) => {
  return (
    <div
      className={`bin-level-tile ${isSelected ? 'bin-level-tile-active' : ''} `}
      onClick={onClick}
    >
      <div
        style={{
          backgroundColor: getBinColorByLevel(isSelected ? level : -1)
            ?.backgroundColor,
          borderColor: getBinColorByLevel(isSelected ? level : -1)?.borderColor,
        }}
        className={`bin_level-bigCircle`}
      />
      <div
        style={{
          backgroundColor: getBinColorByLevel(isSelected ? level : -1)
            ?.borderColor,
        }}
        className={`bin_level-circle `}
      />

      <div className="bin_level-title">
        {level === 0 ? 'Empty' : `${level} %`}
      </div>
    </div>
  );
};

export default BinLevelTile;
