import { ReactNode } from 'react';
import {
  BinPickup,
  PickupEvent,
  PickupEventType,
  TruckData,
} from '../../utils/types';
import {
  calculateEventDuration,
  convertMinsToHHMM,
  getFormattedDateAndTime,
  getFormattedTime,
  getLastLocation,
} from '../../utils/helpers';
import { truckColorMapping } from '../../utils/constants';
import { Button, Dropdown } from 'react-bootstrap';
import { Dot } from './Timeline';
import { LegendProps, SubsectionField, TimelineStatus } from './types';
import { capitaliseFirstLetter } from '../../utils/helper';
import { getPickupStatusWithDot } from './JobDetails';
import StatusDot from '../../../dustControl/components/StatusDot';
import './styles.scss';
export const renderHeader = (
  binName: string,
  binPickupRef?: string,
): ReactNode => {
  return (
    <div className="truck-text">
      {binName}
      <i className="truck-number">{binPickupRef ? ` (${binPickupRef})` : ''}</i>
    </div>
  );
};

const LegendItem: React.FC<LegendProps> = ({ status, label }) => (
  <Dropdown.Item
    className="d-flex align-items-center justify-content-start"
    disabled
  >
    <Dot Dotstyles={{ marginLeft: -4, marginRight: '16px' }} status={status} />
    <div
      className="bin_timeline_label text-black fw-bold "
      style={{ fontSize: '14px' }}
    >
      {label}
    </div>
  </Dropdown.Item>
);

export const renderLegend = (): ReactNode => {
  return (
    <Dropdown className="d-flex">
      <Dropdown.Toggle
        bsPrefix="custom-toggle"
        id="legend-dropdown-toggle"
        className="legend-button"
      >
        Legend
      </Dropdown.Toggle>

      <Dropdown.Menu
        className="bin_dropdown"
        bsPrefix="dropdown-menu dropdown-menu-m"
      >
        <LegendItem status={'completed'} label="Completed" />
        <LegendItem status={'current'} label="Current" />
        <LegendItem status={'disconnected'} label="No Connection" />
        <LegendItem status={'upcoming'} label="Upcoming/Incomplete" />
      </Dropdown.Menu>
    </Dropdown>
  );
};

export const getPickupEndTime = (pickup: BinPickup) =>
  pickup.endTime ? getFormattedTime(pickup.endTime) : 'Ongoing';

export const getPickupTotalDuration = (pickup: BinPickup) =>
  pickup.events[0]
    ? calculateEventDuration(
        pickup.events[0].startTime,
        pickup.endTime ?? new Date(),
      ).time
    : '-';

export const isDropoffOnly = (pickup: BinPickup) => {
  const deliverEvent = pickup.events.find(
    (e) => e.type === PickupEventType.DELIVER && e.endTime,
  );
  const dumpEvent = pickup.events.find(
    (e) => e.type === PickupEventType.DUMP && e.endTime,
  );
  const transitDumpEvent = pickup.events.find(
    (e) => e.type === PickupEventType.TRANSIT_DUMP && e.endTime,
  );
  if (deliverEvent && !dumpEvent && !transitDumpEvent) return true;
  else return false;
};

export const getTimelineEventList = (pickup: BinPickup) => {
  if (isDropoffOnly(pickup))
    return {
      ARRIVED_AT_PICKUP: 'Arrived at pickup point',
      LOADED: 'Loaded',
      TRAVEL_TO_DROP_OFF: 'Travelling to drop-off point',
      ARRIVED_AT_DROP_OFF: 'Arrived at drop-off point',
      BIN_DELIVERED: 'Bin delivered',
    };
  return {
    ARRIVED_AT_PICKUP: 'Arrived at pickup point',
    LOADED: 'Loaded',
    TRAVEL_TO_DUMP: 'Travelling to dump point',
    ARRIVED_AT_DUMP: 'Arrived at dump point',
    SCRAP_DUMPED: 'Scrap dumped',
    TRAVEL_TO_DROP_OFF: 'Travelling to drop-off point',
    ARRIVED_AT_DROP_OFF: 'Arrived at drop-off point',
    BIN_DELIVERED: 'Bin delivered',
  };
};

export const getTimelineStatus = ({
  eventEndTime,
  eventStartTime,
}: {
  eventEndTime: Date | null;
  eventStartTime: Date | null;
}): TimelineStatus => {
  if (eventEndTime) return TimelineStatus.COMPLETED;
  else if (eventStartTime) return TimelineStatus.CURRENT;
  return TimelineStatus.UPCOMING;
};

export const getTimelineDescription = (
  event: PickupEvent,
  status: TimelineStatus,
  initialDescriptionText?: string,
  descriptionText?: string,
): string => {
  if (typeof descriptionText === 'string') return descriptionText;
  if (status === TimelineStatus.COMPLETED) {
    return getLastLocation(event);
  } else if (status === TimelineStatus.DISCONNECTED) {
    return '';
  } else return initialDescriptionText ?? '';
};

export const buildTimelineArray = (pickup: BinPickup) => {
  // timelineKey == TIMELINE_STATUS.ARRIVED_AT_PICKUP then endTime is of event.type == "transitToPickup"
  // timelineKey == TIMELINE_STATUS.LOADED then endTime is of event.type == "pickedUp"
  // timelineKey == TIMELINE_STATUS.TRAVEL_TO_DUMP then startTime is of event.type == "transitToDump"
  // timelineKey == TIMELINE_STATUS.ARRIVED_AT_DUMP then endTime is of event.type == "transitToPickup"
  // timelineKey == TIMELINE_STATUS.SCRAP_DUMPED then endTime is of event.type == "dumped"
  // timelineKey == TIMELINE_STATUS.TRAVEL_TO_DROP_OFF then startTime is of event.type == "transitToDump"
  // timelineKey == TIMELINE_STATUS.ARRIVED_AT_DROP_OFF then endTime is of event.type == "transitToDump"
  // timelineKey == TIMELINE_STATUS.BIN_DELIVERED then endTime is of event.type == "delivered"
  let inProgressIndex: number = 0;
  let firstUpcomingEventIndex: number | undefined;
  let timelineEvents = Object.entries(getTimelineEventList(pickup)).map(
    ([timelineKey, timelineText], index) => {
      let eventType: PickupEventType;
      let endTime: 'endTime' | 'startTime';
      let startTime: 'endTime' | 'startTime';
      let descriptionText: string | undefined;
      let initialDescriptionText: string | undefined; // for events that will be updated once completed
      switch (timelineKey) {
        default:
        case 'ARRIVED_AT_PICKUP':
          eventType = PickupEventType.TRANSIT_PICKUP;
          startTime = 'endTime';
          endTime = 'endTime';
          initialDescriptionText = pickup.bin.pickupPoint;
          break;
        case 'LOADED':
          eventType = PickupEventType.PICKUP;
          startTime = 'startTime';
          endTime = 'endTime';
          descriptionText = `Bin ${pickup.bin.name}`;
          break;
        case 'TRAVEL_TO_DUMP':
          eventType = PickupEventType.TRANSIT_DUMP;
          startTime = 'startTime';
          endTime = 'endTime';
          initialDescriptionText = pickup.bin.dumpPoint;
          break;
        case 'ARRIVED_AT_DUMP':
          eventType = PickupEventType.TRANSIT_DUMP;
          startTime = 'endTime';
          endTime = 'endTime';
          initialDescriptionText = pickup.bin.dumpPoint;
          break;
        case 'SCRAP_DUMPED':
          eventType = PickupEventType.DUMP;
          startTime = 'startTime';
          endTime = 'endTime';
          descriptionText = pickup.bin.scrapType;
          break;
        case 'TRAVEL_TO_DROP_OFF':
          eventType = PickupEventType.TRANSIT_DROPOFF;
          startTime = 'startTime';
          endTime = 'endTime';
          initialDescriptionText = pickup.bin.dropoffPoint;
          break;
        case 'ARRIVED_AT_DROP_OFF':
          eventType = PickupEventType.TRANSIT_DROPOFF;
          startTime = 'endTime';
          endTime = 'endTime';
          initialDescriptionText = pickup.bin.dropoffPoint;
          break;
        case 'BIN_DELIVERED':
          eventType = PickupEventType.DELIVER;
          startTime = 'startTime';
          endTime = 'endTime';
          descriptionText = '';
          break;
      }

      const eventIndex = pickup.events.findIndex(
        (event) => event.type === eventType,
      );
      const event = pickup.events[eventIndex];
      const eventEndTime = event?.[endTime] ?? null;
      const eventStartTime = event?.[startTime] ?? null;

      // status will be "current" if there is an entry for next timelineKey inside historyDetail.events, and there exists value for eventTime key inside  that  historyDetail.events,
      const status = getTimelineStatus({
        eventEndTime,
        eventStartTime,
      });

      // used to calculate disconnected events after mapping is complete
      if (status !== TimelineStatus.UPCOMING) inProgressIndex = index;
      else if (firstUpcomingEventIndex === undefined) {
        firstUpcomingEventIndex = index;
      }

      const description: string = getTimelineDescription(
        event,
        status,
        initialDescriptionText,
        descriptionText,
      );

      return {
        text: timelineText,
        description: description ? `(${description})` : '',
        endTime: eventEndTime,
        status,
      };
    },
  );

  if (
    firstUpcomingEventIndex !== undefined &&
    firstUpcomingEventIndex < inProgressIndex
  ) {
    // an event was marked as upcoming but a later event was marked as complete or in progress
    // which means an event was disconnected
    timelineEvents = timelineEvents.map((timelineEvent, index) => {
      if (
        timelineEvent.status === TimelineStatus.UPCOMING &&
        index < inProgressIndex
      ) {
        timelineEvent.status = TimelineStatus.DISCONNECTED;
      }
      return timelineEvent;
    });
  }

  return timelineEvents;
};

export const getShiftSummaryFields = (truck: TruckData): SubsectionField[] => [
  { title: 'Last Seen:', value: getLastLocation(truck.lastLocation) },
  {
    title: 'Active:',
    value: (
      <StatusDot
        style={{ background: truckColorMapping.Active }}
        className="large"
      >
        {`${convertMinsToHHMM(truck.shiftSummary.active)} h`}{' '}
      </StatusDot>
    ),
  },
  {
    title: 'Idle:',
    value: (
      <StatusDot
        style={{ background: truckColorMapping.Idle }}
        className="large"
      >{`${convertMinsToHHMM(truck.shiftSummary.idle)} h`}</StatusDot>
    ),
  },
  {
    title: 'Parked:',
    value: (
      <StatusDot
        style={{ background: truckColorMapping.Parked }}
        className="large"
      >{`${convertMinsToHHMM(truck.shiftSummary.parked)} h`}</StatusDot>
    ),
  },
  {
    title: 'Total Bin Loads:',
    value: truck.shiftSummary.totalBinLoad.toString(),
  },
  {
    title: 'Delays:',
    value: `${convertMinsToHHMM(truck.shiftSummary.delays)} h`,
  },
  {
    title: 'Pre-Start Checks:',
    value: capitaliseFirstLetter(truck.shiftSummary.prestartChecks),
  },
  {
    title: 'Engine Hours (Daily):',
    value: `${convertMinsToHHMM(truck.engineHourSummary.dailyEngineHour)} h`,
  },
  {
    title: 'Engine Hours (Lifetime):',
    value: `${truck.engineHourSummary.lifetimeEngineHour} h`,
  },
];

export const getJobSummaryFields = (
  pickup: BinPickup,
  isTruckSection: boolean,
): SubsectionField[] => {
  const truckSpecificFields: SubsectionField[] = isTruckSection
    ? []
    : [
        {
          title: 'Last Seen:',
          value: pickup.events.at(-1)?.endTime
            ? getLastLocation(pickup.events.at(-1))
            : '-',
        },
        { title: 'Truck:', value: pickup.truckName },
        {
          title: 'Operator:',
          value: pickup.operator
            ? `${pickup.operator.firstName} ${pickup.operator.lastName}`
            : '-',
        },
      ];

  return [
    { title: 'Job Ref:', value: pickup.pickupRef },
    ...truckSpecificFields,
    ...(isTruckSection
      ? [{ title: 'Bin ID:', value: `Bin ${pickup.bin.name}` }]
      : []), //The Bin ID is only added if the history is for the truck
    { title: 'Job Type:', value: capitaliseFirstLetter(pickup.type) },
    { title: 'Job Status:', value: getPickupStatusWithDot(pickup.status) },
    {
      title: 'Pickup Requested:',
      value: getFormattedDateAndTime(pickup.bin.pickupRequest?.time),
    },
    { title: 'Scrap Type:', value: pickup.bin.scrapType },
    {
      title: 'Pickup:',
      value: getLastLocation(
        pickup.events.find((e) => e.type === PickupEventType.PICKUP),
      ),
    },
    {
      title: 'Dump:',
      value: (() => {
        const dumpEvent = pickup.events.find(
          (e) => e.type === PickupEventType.DUMP,
        );
        return dumpEvent && dumpEvent.endTime
          ? getLastLocation(dumpEvent)
          : '-';
      })(),
    },
    {
      title: 'Drop-off:',
      value: getLastLocation(
        pickup.events.find((e) => e.type === PickupEventType.DELIVER),
      ),
    },
    {
      title: 'Cycle Duration:',
      value: `${getFormattedDateAndTime(
        pickup.events[0].startTime,
      )} - ${getPickupEndTime(pickup)}`,
      subvalue: `(${getPickupTotalDuration(pickup)})`,
    },
  ];
};
