import SubHeader from '../../components/SubHeader';
import InfiniteScroll from 'react-infinite-scroll-component';
import * as toast from '../../components/toast';
import React, {
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from 'react';
import { API_METHODS, ENDPOINTS } from '../../utils/constants';
import LoadingScreen from '../../components/loading';
import moment from 'moment';
import PdfModal from '../../components/PdfModal';
import JobDailyReport, {
  formatTime,
} from '../../components/reports/job-daily-report';
import ModalView from '../../components/ModalView';
import DownloadModal from '../../components/DownloadModal';
import { UserContext } from '../../../pages/service/userContext';

const tableHeader = [
  'Location',
  'Water consumed in zone',
  'Zone pump activity',
  'Daily Water consumption',
  'Trucks Activity',
];
const ZONES = [1, 2, 3, 4, 5, 6];
const truckActivityHeader = [
  'Truck',
  'Water consumption',
  'Pump Time',
  'Refill',
  'Engine Hours',
];
const refillWaterConsumptions = [
  { label: 'Total:', propertyName: 'towerTotal' },
  { label: 'Tower 1:', propertyName: 'tower1' },
  { label: 'Tower 2:', propertyName: 'tower2' },
  { label: 'Unknown:', propertyName: 'unknown' },
];
const INTIAL_GROUPING = 'day';
const ZoneReport = () => {
  const { ApiHandler } = useContext(UserContext);
  const [loading, setLoading] = useState(false);
  const [date, setDate] = useState(INTIAL_GROUPING);
  const [zoneReportData, setZoneReportData] = useState([]);
  const [hasMore, setHasMore] = useState(true);
  const offsetRef = useRef(0);
  const [filterZones, setFilterZones] = useState(ZONES);

  const [PDFModalData, setPDFModalData] = useState(null);
  const [downloading, setDownloading] = useState(false);

  const [filteredData, setFilteredData] = useState({
    trucks: [],
    delays: [],
    zones: [],
    status: [],
    types: [],
    startDate: '',
    endDate: '',
  });
  const [abortController, setAbortController] = useState();
  const [showDownload, setShowDownload] = useState(false);

  const getZones = useCallback(async (filter, signalAbort) => {
    let allFilter = { ...filter, limit: 3 };
    if (!allFilter.offset) allFilter.offset = offsetRef.current;
    if (!allFilter.grouping) allFilter.grouping = INTIAL_GROUPING;
    if (allFilter.endDate === '') delete allFilter.endDate;
    if (allFilter.startDate === '') delete allFilter.startDate;
    try {
      setLoading(true);
      let data = {
        endPoint: ENDPOINTS.getDailyZones,
        method: API_METHODS.POST,
        reqParam: allFilter,
      };
      if (signalAbort) data.signal = signalAbort.signal;
      const zones = await ApiHandler(data);
      if (!zones.data?.length && zones.status === 202) return setHasMore(false);
      if (offsetRef.current) {
        setHasMore(true);
        setZoneReportData((prev) => [...prev, ...zones.data]);
      } else {
        setHasMore(true);
        setZoneReportData(zones?.data);
      }
    } catch (err) {
      toast.error(err?.message);
    } finally {
      setLoading(false);
      setAbortController(undefined);
    }
  }, []);

  useEffect(() => {
    const abortController = new AbortController();
    getZones({ grouping: INTIAL_GROUPING }, { signal: abortController.signal });
    return () => {
      abortController.abort();
    };
  }, [getZones]);

  const onDateSelect = async (event) => {
    if (abortController) abortController.abort();
    const newAbortController = new AbortController();
    setAbortController(newAbortController);
    const { value } = event.target;
    setDate(value);
    setZoneReportData([]);
    offsetRef.current = 0;
    await getZones({ ...filteredData, grouping: value }, newAbortController);
  };
  const fetchMoreData = async () => {
    offsetRef.current += 3;
    //shouldn't abort previous controller, as fetch more should only be called when previous API has completed
    const newAbortController = new AbortController();
    setAbortController(newAbortController);
    await getZones({ ...filteredData, grouping: date }, newAbortController);
  };

  const onFilterChange = async (filterData) => {
    offsetRef.current = 0;
    setZoneReportData([]);
    setFilteredData(filterData);
    if (filterData.zones.length) setFilterZones(filterData.zones);
    else setFilterZones(ZONES);

    if (abortController) abortController.abort();
    const newAbortController = new AbortController();
    setAbortController(newAbortController);

    await getZones({ ...filterData, grouping: date }, newAbortController);
  };

  const downloadReport = async () => {
    try {
      setDownloading(true);

      const reqParam = { ...filteredData };
      delete reqParam.operator;
      reqParam.startDate = PDFModalData.date;
      reqParam.endDate = PDFModalData.endDate;

      const response = await ApiHandler({
        endPoint: ENDPOINTS.getZonesReport,
        method: API_METHODS.POST,
        reqParam: reqParam,
        blobType: true,
      });

      const url = window.URL.createObjectURL(new Blob([response?.data]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', 'zoneReport.pdf');
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    } catch (err) {
      toast.error(err.message);
    }

    setDownloading(false);
  };

  const onCreate = async (payload) => {
    try {
      setDownloading(true);
      const response = await ApiHandler({
        endPoint: ENDPOINTS.getZonesReport,
        method: API_METHODS.POST,
        reqParam: payload,
        blobType: true,
      });
      const url = window.URL.createObjectURL(new Blob([response?.data]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', 'zoneReport.pdf');
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    } catch (err) {
      toast.error(err.message);
    }
    setDownloading(false);
  };

  return (
    <div className="d-flex flex-column w-100 zone-report-main-container">
      <div className="filter-container d-flex justify-content-end align-items-center mb-2">
        <select onChange={onDateSelect} value={date} className="date-select">
          <option value={'day'}>Day</option>
          <option value={'week'}>Week</option>
          <option value={'month'}>Month</option>
          <option value={'year'}>Year</option>
        </select>
        <SubHeader
          SubHeaderVisible={true}
          toShowFilter={true}
          filteredData={filteredData}
          setFilteredData={setFilteredData}
          onSaveClick={onFilterChange}
          showDownload
          onDownloadClick={() => setShowDownload(true)}
          hideSearch
        />
      </div>

      <table className="zone_header">
        <thead>
          <tr>
            {tableHeader.map((item) => (
              <th className="py-2 px-4" align="left" key={item}>
                {item}
              </th>
            ))}
          </tr>
        </thead>
      </table>
      <div id="zone-report-container">
        <InfiniteScroll
          scrollableTarget={'zone-report-container'}
          dataLength={zoneReportData.length}
          next={fetchMoreData}
          hasMore={hasMore}
          loader={loading && <LoadingScreen />}
        >
          <table className="zone-report-table">
            <tbody>
              {zoneReportData?.map((zoneReport, index) => (
                <React.Fragment key={index}>
                  <tr
                    className="position-relative"
                    onClick={() => setPDFModalData(zoneReport)}
                  >
                    <td colSpan={6} className="py-2">
                      <DateRange
                        date={date}
                        fromDate={zoneReport?.date}
                        toDate={zoneReport?.endDate}
                      />
                    </td>
                  </tr>
                  {(filteredData.zones?.length
                    ? filteredData.zones
                    : ZONES
                  ).map((zone, index) => (
                    <tr className="top-width" key={zone}>
                      <td className="bottom-width center-zone-text">
                        <p className="margin-auto">Zone {zone}</p>
                      </td>
                      <td className="bottom-width center-zone-text">
                        <p className="margin-auto">
                          {zoneReport[`zone${zone}`]?.waterUsage ?? 0} L
                        </p>
                      </td>
                      <td className="bottom-width center-zone-text">
                        <p className="margin-auto">
                          {formatTime(
                            zoneReport[`zone${zone}`]?.pumpTime,
                            true,
                          )}
                        </p>
                      </td>
                      {index === 0 && (
                        <>
                          <td
                            rowSpan={filterZones.length}
                            className="span-7-cell align-center"
                            align="top"
                          >
                            <div className="daily-water gap-2">
                              <div>
                                {refillWaterConsumptions.map(
                                  ({ label, propertyName }) => (
                                    <div
                                      className="d-flex flex-row justify-content-between"
                                      key={label}
                                    >
                                      <span>{label}</span>
                                      <span className="water-data">
                                        {zoneReport[propertyName] ?? 0} L
                                      </span>
                                    </div>
                                  ),
                                )}
                              </div>
                            </div>
                          </td>

                          <td
                            align="top"
                            rowSpan={filterZones.length}
                            className="span-7-cell align-top"
                          >
                            <div className="d-flex truck-activity-table-container">
                              <table className="truck-activity-table">
                                <thead>
                                  <tr className="truck-header">
                                    {truckActivityHeader.map((item) => (
                                      <th
                                        style={{
                                          padding: '8px 0px 10px 10px',
                                        }}
                                        key={item}
                                      >
                                        {item}
                                      </th>
                                    ))}
                                  </tr>
                                  <tr className="sub-data-header">
                                    {truckActivityHeader.map((item) => {
                                      return (
                                        <th
                                          style={{
                                            padding: '8px 0px 10px 10px',
                                          }}
                                          align="left"
                                          key={item}
                                        >
                                          {item === 'Refill' ? (
                                            <div className="d-flex flex-row align-items-center">
                                              <span
                                                style={{
                                                  paddingLeft: '5px',
                                                }}
                                              >
                                                Tower 1
                                              </span>
                                              <span
                                                style={{
                                                  paddingLeft: '30px',
                                                }}
                                              >
                                                Tower 2
                                              </span>
                                            </div>
                                          ) : item === 'Engine Hours' ? (
                                            <div className="d-flex flex-row align-items-center">
                                              <span
                                                style={{
                                                  paddingLeft: '5px',
                                                }}
                                              >
                                                Today
                                              </span>
                                              <span
                                                style={{
                                                  paddingLeft: '30px',
                                                }}
                                              >
                                                Total
                                              </span>
                                            </div>
                                          ) : (
                                            ''
                                          )}
                                        </th>
                                      );
                                    })}
                                  </tr>
                                </thead>
                                <tbody>
                                  {zoneReport.trucks.map((truck) => (
                                    <tr
                                      className="bottom-width"
                                      key={truck.name}
                                    >
                                      <td
                                        className="px-3"
                                        style={{ padding: '17.5px' }}
                                      >
                                        {truck.name}
                                      </td>
                                      <td
                                        className="px-3"
                                        style={{ padding: '17.5px' }}
                                      >
                                        {truck.waterConsumption} L
                                      </td>
                                      <td
                                        className="px-3"
                                        style={{ padding: '17.5px' }}
                                      >
                                        {formatTime(truck.pumpTime, true) ?? 0}
                                      </td>
                                      <td className="px-3">
                                        {/* tower 1 and 2 data */}
                                        <div className="d-flex flex-row align-items-center sub-data-item">
                                          <span>{truck.tower1} L</span>
                                          <span style={{ paddingLeft: '35px' }}>
                                            {truck.tower2} L
                                          </span>
                                        </div>
                                      </td>
                                      <td className="px-3">
                                        <div className="d-flex flex-row align-items-center sub-data-item">
                                          <span>
                                            {formatTime(
                                              truck.todayEngineHour ?? 0,
                                              false,
                                            )}
                                          </span>
                                          <span style={{ paddingLeft: '30px' }}>
                                            {truck.engineHour ?? 0} h
                                          </span>
                                        </div>
                                      </td>
                                    </tr>
                                  ))}
                                </tbody>
                              </table>
                            </div>
                          </td>
                        </>
                      )}
                    </tr>
                  ))}
                </React.Fragment>
              ))}
            </tbody>
          </table>
        </InfiniteScroll>
      </div>

      {showDownload && (
        <ModalView
          onClose={() => setShowDownload(false)}
          backgroundColor={'white'}
          hide
        >
          <DownloadModal
            data={filteredData}
            onCancel={() => setShowDownload(false)}
            onCreate={onCreate}
            downloading={downloading}
            type="zone"
          />
        </ModalView>
      )}

      {PDFModalData && (
        <PdfModal
          onClose={() => setPDFModalData(null)}
          loading={downloading}
          onDownloadPress={downloadReport}
        >
          <JobDailyReport date={date} data={PDFModalData} />
        </PdfModal>
      )}
    </div>
  );
};

const capitaliseFirstLetter = (word) => {
  const capitalized = word.charAt(0).toUpperCase() + word.slice(1);
  return capitalized;
};

const DateRange = ({ fromDate, toDate, date }) => {
  const formatMonthYear = (date) => {
    if (date === 'month') return 'MMMM';
    else return 'YYYY';
  };
  return (
    <p className="date-container">
      {date === 'day' ? (
        <>
          {/* {moment(toDate).calendar(null, {
            sameDay: "[Today] - ",
            lastDay: "[Yesterday] - ",
            nextDay: "[Tomorrow] - ",
            lastWeek: "[]",
            sameElse: "[]",
          })} */}
          {moment(toDate).format('DD/MM/YYYY')}
        </>
      ) : date === 'week' ? (
        <>
          {capitaliseFirstLetter(date)}: {moment(fromDate).format('DD/MM/YY')} -{' '}
          {moment(toDate).format('DD/MM/YY')}
        </>
      ) : date === 'month' ? (
        <>
          {capitaliseFirstLetter(date)}:{' '}
          {moment(fromDate).format(formatMonthYear(date))}-
          {`${fromDate.split('-')[0].substring(2, 4)}`}
        </>
      ) : (
        <>
          {capitaliseFirstLetter(date)}:{' '}
          {moment(fromDate).format(formatMonthYear(date))}
        </>
      )}
    </p>
  );
};

export default ZoneReport;
