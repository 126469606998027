import {
  BinWithPickup,
  TruckData,
  GetAllTrucksData,
  Bin,
} from '../../utils/types';

export type DetailsProps =
  | {
      trucks: GetAllTrucksData;
      detailComponentType: 'Bin';
      bins: BinWithPickup[];
      detailData: BinWithPickup; // Unified detail data property
      toggleShowDetailsVisibility: (isDetailsScreenVisible: boolean) => void;
    }
  | {
      trucks: GetAllTrucksData;
      detailComponentType: 'Truck';
      bins: BinWithPickup[];
      detailData: TruckData; // Unified detail data property
      toggleShowDetailsVisibility: (isDetailsScreenVisible: boolean) => void;
    };

export interface TruckDetailsProps {
  trucks: GetAllTrucksData;
  truckData: TruckData;
  setActionModal: React.Dispatch<React.SetStateAction<JSX.Element>>;
}

export type BinDetailsProps = {
  binWithPickupData: BinWithPickup;
};

export enum BinLevel {
  ZERO = 0,
  TWENTY_FIVE = 25,
  FIFTY = 50,
  SEVENTY_FIVE = 75,
  HUNDRED = 100,
}

export type BinDetailsItem = {
  icon: JSX.Element;
  data: {
    label: string;
    value: string;
  };
};

export enum BinDetailsSection {
  OTHER_DETAILS = 'Other Details (Defaults)',
}

export type DetailComponentType = 'Bin' | 'Truck';
