import { useEffect, useRef, useState } from 'react';
import {
  PieChart,
  Pie,
  Tooltip,
  ResponsiveContainer,
  Cell,
  Sector,
} from 'recharts';

const InitialData = [
  { name: 'Zone 1', value: 0 },
  { name: 'Zone 2', value: 0 },
  { name: 'Zone 3', value: 0 },
  { name: 'Zone 4', value: 0 },
  { name: 'Zone 5', value: 0 },
  { name: 'Zone 6', value: 0 },
];
const defaultPieData = [{ value: 1, color: '#d2d2d2' }];
const COLORS = [
  '#138BAA',
  '#FAF265',
  '#4C4B66',
  '#FFC850',
  '#00C48C',
  '#6C63FF',
];
function PieChartGraph(props) {
  const [data, setData] = useState(InitialData);
  const smallPieSegments = useRef([false, false, false, false, false, false]);
  useEffect(() => {
    const tempData = [...InitialData];
    props.data.forEach((value, index) => {
      tempData[index].value = value;
    });
    setData(tempData);
  }, [props.data]);
  const allValuesAreZero = data.every((obj) => obj.value === 0);

  const renderActiveShape = (props) => {
    const RADIAN = Math.PI / 180;
    const {
      cx,
      cy,
      midAngle,
      innerRadius,
      outerRadius,
      startAngle,
      endAngle,
      fill,
      value,
      percent,
      name,
    } = props;

    if (value === 0) return;
    const zoneNumber = name.at(-1);
    let valueTextPosition = 16;

    const smallPieSegmentsCopy = [...smallPieSegments.current];
    if (percent < 0.02) {
      smallPieSegments.current[zoneNumber] = zoneNumber;
      const multiplier = //cannot be 1 because default multiplier is 1
        smallPieSegments.current
          .filter(Boolean)
          .findIndex((segment) => segment === zoneNumber) === 5
          ? 0
          : smallPieSegments.current
              .filter(Boolean)
              .findIndex((segment) => segment === zoneNumber) + 2;
      valueTextPosition = multiplier * valueTextPosition;
    } else smallPieSegmentsCopy[zoneNumber] = false;
    smallPieSegments.current = smallPieSegmentsCopy;

    // use ex and mx to affect how they are spaced
    // ideally in intervals of three so they don't overlap eachtoher
    const sin = Math.sin(-RADIAN * midAngle);
    const cos = Math.cos(-RADIAN * midAngle);
    const sx = cx + (outerRadius - 20) * cos;
    const sy = cy + (outerRadius - 20) * sin;
    const mx = cx + (outerRadius + 11) * cos;
    const my = cy + (outerRadius + valueTextPosition) * sin;
    let ex = mx + (cos >= 0 ? 1 : -1) * valueTextPosition;
    let ey = my;
    const textAnchor = cos >= 0 ? 'start' : 'end';

    return (
      <g>
        {/* pie slice with color */}
        <Sector
          cx={cx}
          cy={cy}
          innerRadius={innerRadius}
          outerRadius={outerRadius}
          startAngle={startAngle}
          endAngle={endAngle}
          fill={fill}
        />

        {/* Inner dot */}
        <circle cx={sx} cy={sy} r={2} fill="#2B2D2F" stroke="none" />

        {/* line */}
        <path
          d={`M${sx},${sy}L${mx},${my}L${ex},${ey}`}
          stroke="#2B2D2F"
          fill="none"
        />

        {/* litres text */}
        <text
          x={ex + (cos >= 0 ? 1 : -1) * 2}
          y={ey}
          textAnchor={textAnchor}
          fill="#333"
          fontSize={13}
          fontWeight={600}
        >
          {value.toLocaleString('en-US')}
        </text>
      </g>
    );
  };

  return (
    <ResponsiveContainer width="100%" height="100%">
      {allValuesAreZero ? (
        <PieChart>
          <Pie
            outerRadius={70}
            isAnimationActive={false}
            dataKey="value"
            data={defaultPieData}
          >
            {defaultPieData.map((entry, index) => (
              <Cell
                key={`cell-${index}`}
                fill={entry.color}
                stroke={entry.color}
              />
            ))}
          </Pie>
        </PieChart>
      ) : (
        <PieChart>
          <Pie
            dataKey="value"
            isAnimationActive={false}
            data={data}
            cx="50%"
            cy="50%"
            outerRadius={70}
            activeShape={renderActiveShape}
            activeIndex={[0, 1, 2, 3, 4, 5]}
          >
            {data.map((entry, index) => (
              <Cell
                key={`cell-${index}`}
                fill={COLORS[index % COLORS.length]}
              />
            ))}
          </Pie>
          <Tooltip />
        </PieChart>
      )}
    </ResponsiveContainer>
  );
}

export default PieChartGraph;
