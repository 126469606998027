import { CSSProperties } from 'react';
import BlueBin from '../../assets/images/BinBlue';
import GreyBin from '../../assets/images/BinGrey';
import RedBin from '../../assets/images/BinRed';
import GreenBin from '../../assets/images/BinGreen';
import YellowBin from '../../assets/images/BinYellow';
import { BinLevel, BinDetailsItem } from './types';
import { BinWithPickup, TruckStatus } from '../../utils/types';
import { BinDetailsLabel } from '../../utils/constants';
import { TruckIconGreen } from '../../assets/images/TruckGreen';
import { TruckIconGrey } from '../../assets/images/TruckGrey';
/*
  TODO: Move the icon/logo module exports to a single index module in the images directory 
  so that these imports can be grouped from one module
*/
import { ScrapIcon } from '../../assets/images/scrap';
import { TruckIcon } from '../../assets/images/Truck';
import { HourglassIcon } from '../../assets/images/hourglass';
import {
  getDurationAgo,
  getLastLocation,
  isBinMissing,
} from '../../utils/helpers';
import { DefaultMarker } from '../../assets/images/DefaultMarker';
import HazardIcon from '../../assets/images/alert.svg';
import { DefaultRedMarker } from '../../assets/images/DefaultRedMarker';
import { DefaultGrayAlert } from '../../assets/images/alertGray';
import { DefaultYellowAlert } from '../../assets/images/alertYellow';

/*
 The below function is used by the renderBinStatusLabelForBinDetails function in the renders module
 to display the bin status in a capsule styled div
*/

export const binStatusLabelBackgroundStyle = (
  backgroundColour: string,
): CSSProperties => {
  const style: CSSProperties = {
    backgroundColor: backgroundColour,
    padding: '5px 12px 5px 14px',
    marginTop: ' 10px',
    borderRadius: '20px',
    textAlign: 'left',
    width: 'fit-content',
    fontStyle: 'italic',
    fontSize: '14px',
    color: '#000000',
    letterSpacing: '0px',
    font: 'italic normal 600 14px/19px Open Sans',
  };
  return style;
};

/*
  The colour of the bin icon in the bin details component (details component)
  differs (changes) based on the bin level. The below function is used to return the 
  correct bin icon component based on the bin level
*/
export const getBinIconBasedOnLevel = (
  binLevel: number,
  size: number = 40,
): JSX.Element => {
  const iconHeight: number = size ?? 40;
  const iconWidth: number = size ?? 40;
  if (binLevel === BinLevel.ZERO) {
    return <GreyBin height={iconHeight} width={iconWidth} />;
  } else if (binLevel === BinLevel.TWENTY_FIVE) {
    return <BlueBin height={iconHeight} width={iconWidth} />;
  } else if (binLevel === BinLevel.FIFTY) {
    return <GreenBin height={iconHeight} width={iconWidth} />;
  } else if (binLevel === BinLevel.SEVENTY_FIVE) {
    return <YellowBin height={iconHeight} width={iconWidth} />;
  } else {
    return <RedBin height={iconHeight} width={iconWidth} />;
  }
};

/*
  The colour of the truck icon in the truck details component and truck flyout
  differs (changes) based on the truck status (parked or active).
  The below function is used to return the icon
*/
export const getTruckIconBasedOnStatus = (
  truckStatus: string,
  size: number,
): JSX.Element => {
  const iconHeight: number = size;
  const iconWidth: number = size;
  if (truckStatus === TruckStatus.Active) {
    return <TruckIconGreen height={iconHeight} width={iconWidth} />;
  } else if (truckStatus === TruckStatus.Idle) {
    return <TruckIcon height={iconHeight} width={iconWidth} />;
  } else {
    return <TruckIconGrey height={iconHeight} width={iconWidth} />;
  }
};

/*
  The blow function loads the bin details in the details section
  Eg: bin level, lastSeen, last emptied etc. It also renders the corresponding 
  icons.
*/

export const getContentForBinDetails = (
  binWithPickupData: BinWithPickup,
): BinDetailsItem[] => {
  const iconWidth: number = 30;
  const iconHeight: number = 30;
  const binLevelItem: BinDetailsItem = {
    icon: getBinIconBasedOnLevel(binWithPickupData.binLevel),
    data: {
      label: BinDetailsLabel.BIN_LEVEL,
      value: `${binWithPickupData.binLevel}%`,
    },
  };

  const binLastSeenLocationItem: BinDetailsItem = {
    icon: isBinMissing(binWithPickupData) ? (
      <DefaultRedMarker height={iconHeight} width={iconWidth} />
    ) : (
      <DefaultMarker height={iconHeight} width={iconWidth} />
    ),
    data: {
      label: BinDetailsLabel.LAST_SEEN_LOCATION,
      value: getLastLocation(binWithPickupData.lastLocation),
    },
  };

  const binScrapTypeItem: BinDetailsItem = {
    icon: <ScrapIcon width={iconWidth} height={iconHeight} />,
    data: {
      label: BinDetailsLabel.SCRAP_TYPE,
      value: binWithPickupData.scrapType,
    },
  };

  const binTruckItem: BinDetailsItem = {
    icon: <TruckIcon height={iconHeight} width={iconWidth} />,
    data: {
      label: BinDetailsLabel.TRUCK,
      value: binWithPickupData.lastPickup?.truckName ?? '-',
    },
  };

  const lastEmptiedItem: BinDetailsItem = {
    icon: <HourglassIcon height={iconHeight} width={iconWidth} />,
    data: {
      label: BinDetailsLabel.LAST_EMPTIED,
      value:
        binWithPickupData?.lastEmptiedTime !== undefined
          ? convertTimeStampToBinDetailsCustomFormat(
              binWithPickupData?.lastEmptiedTime,
            )
          : '-',
    },
  };

  const lastUpdatedItem: BinDetailsItem = {
    icon: <HourglassIcon height={iconHeight} width={iconWidth} />,
    data: {
      label: BinDetailsLabel.LAST_UPDATED,
      value:
        binWithPickupData?.lastUpdatedTime !== undefined
          ? convertTimeStampToBinDetailsCustomFormat(
              binWithPickupData?.lastUpdatedTime,
            )
          : '-',
    },
  };

  const hazardItem: BinDetailsItem = {
    icon: binWithPickupData.hazards ? (
      <DefaultYellowAlert width={iconWidth} height={iconHeight} />
    ) : (
      <DefaultGrayAlert width={iconWidth} height={iconHeight} />
    ),
    data: {
      label: BinDetailsLabel.HAZARDS,
      value: binWithPickupData.hazards ? binWithPickupData.hazards : '-',
    },
  };

  return [
    binLevelItem,
    binLastSeenLocationItem,
    binScrapTypeItem,
    binTruckItem,
    lastEmptiedItem,
    lastUpdatedItem,
    hazardItem,
  ];
};

/*
 Similar to the above. But the difference is, the below function loads the constant values of the bin
 like the bin pickup zone, drop off zone and the dump zone
*/
export const getRenderContentForBinDefaultDetails = (
  binWithPickupData: BinWithPickup,
): BinDetailsItem[] => {
  const iconWidth: number = 30;
  const iconHeight: number = 30;

  const binPickupPointItem: BinDetailsItem = {
    icon: <DefaultMarker width={iconWidth} height={iconHeight} />,
    data: {
      label: BinDetailsLabel.PICKUP_POINT,
      value: binWithPickupData.pickupPoint,
    },
  };

  const binDumpPointItem: BinDetailsItem = {
    icon: <DefaultMarker width={iconWidth} height={iconHeight} />,
    data: {
      label: BinDetailsLabel.DUMP_POINT,
      value: binWithPickupData.dumpPoint,
    },
  };

  const binDropOffItem: BinDetailsItem = {
    icon: <DefaultMarker width={iconWidth} height={iconHeight} />,
    data: {
      label: BinDetailsLabel.DROP_OFF_POINT,
      value: binWithPickupData.dropoffPoint,
    },
  };

  return [binPickupPointItem, binDumpPointItem, binDropOffItem];
};

/*
 The below function converts the timestamp string into 
 an appropiate format acceptable by the bin design
*/
const convertTimeStampToBinDetailsCustomFormat = (
  timestamp: string,
): string => {
  const lastAction: string = getDurationAgo(timestamp);
  const timestampInNZT: string = formatNZT(timestamp);
  return `${lastAction} (${timestampInNZT})`;
};

export const convertBinLastActionTime = (timestamp: string): string => {
  const lastAction: string = getDurationAgo(timestamp);
  return `${lastAction}`;
};
const formatNZT = (timestamp: string): string => {
  // Create a Date object from the timestamp
  const date: Date = new Date(timestamp);

  // Specify options for formatting
  const options: Intl.DateTimeFormatOptions = {
    day: '2-digit',
    month: '2-digit',
    year: 'numeric',
    hour: '2-digit',
    minute: '2-digit',
    hour12: true, // Use 12-hour clock format with AM/PM
    timeZone: 'Pacific/Auckland', // Set timezone to New Zealand Time
  };

  // Use Intl.DateTimeFormat to format the date in NZT
  const formatter = new Intl.DateTimeFormat('en-NZ', options);
  return `${formatter.format(date)}`;
};
