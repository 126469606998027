export const WebSocketURL =
  process.env.REACT_APP_ENV === 'staging'
    ? 'wss://ws.optitrac.com/steelserv-staging?application='
    : 'wss://ws.optitrac.com/steelserv-production?application=';

export enum SocketRoom {
  SCRAP_BINS = 'scrap_bins',
  ENGINE_HOURS = 'engine_hours',
  WATER_CARTS = 'water_carts',
}

export enum SocketClient {
  ADMIN = 'admin',
}

export type EngineHoursSocketData = {
  engineStatus: EngineEvent;
  truckName: string;
  engineHours?: {
    truckName: string;
    deviceId: string;
    calculatedEngineHours: number;
    calculatedEngineMinutes: number;
    totalEngineHoursInMinutes: number;
    currentEngineStatus: EngineEvent;
    lastUpdatedTime?: Date;
  };
};

export enum EngineEvent {
  ON = 'ON',
  OFF = 'OFF',
}
