import React, {
  useState,
  useEffect,
  useCallback,
  useRef,
  useContext,
} from 'react';
import { Table } from 'react-bootstrap';
import * as toast from '../../components/toast';
import { API_METHODS, ENDPOINTS } from '../../utils/constants';
import {
  endOfDay,
  formatDateToDDMMYYYY,
  getTime,
  getZones,
  groupByDate,
  startOfDay,
  toHoursAndMinutes,
} from '../../utils/helper';
import SubHeader from '../SubHeader';
import moment from 'moment';
import InfiniteScroll from 'react-infinite-scroll-component';
import ModalView from '../ModalView';
import LoadingScreen from '../loading';
import DownloadModal from '../DownloadModal';
import PdfModal from '../PdfModal';
import JobDailyReport from '../reports/job-daily-report';
import { UserContext } from '../../../pages/service/userContext';
import { useDebounce } from '../../../hooks/useDebounce';

const nonEmpty = (object) => {
  return Object.keys(object).length > 0;
};

const tableHeader = [
  'Ref No.',
  'Truck',
  'Location',
  'Duration',
  'Total pump time',
  'Delays',
  'Water consumed',
  'Job status',
  'Job Type',
  'Operator',
];

// const delayEvents = (list) => {
//   if (!list) {
//     return "";
//   }
//   return list.reduce((prev, curr) => {
//     return (prev ? prev + " ," : "") + curr["reason"];
//   }, "");
// };

const isEmpty = (obj) => {
  for (const property in obj) {
    return false;
  }
  return true;
};

const TableBody = (props) => {
  const { data, onDownloadPress, filteredZones } = props;

  const showJobStatus = (job) => {
    switch (job.status) {
      case 'incomplete':
        return 'Incomplete';
      case 'complete':
      case 'inactive':
        return 'Complete';
      case 'active':
        return 'In progress';
      default:
        return '-';
    }
  };

  return (
    <>
      {Object.keys(data)
        .sort((a, b) => moment(b) - moment(a))
        .map((key, index) => {
          return (
            <tbody key={index}>
              {data[key] && data[key].length > 0 && (
                <tr>
                  <td colSpan={3} className="gap-1 border-0">
                    <div
                      className="d-flex gap-2 mb-3"
                      onClick={() =>
                        onDownloadPress(data[key], key, data[key]?.type)
                      }
                    >
                      {
                        <div className="job-date d-flex text-white justify-content-center p-2 rounded-3">
                          {formatDateToDDMMYYYY(new Date(key))}
                        </div>
                      }
                    </div>
                  </td>
                </tr>
              )}

              {nonEmpty(data) &&
                data[key]
                  ?.sort((a, b) => moment(b.start_time) - moment(a.start_time))
                  .map((job, index) => (
                    <tr
                      className="table-text row-text pl-0"
                      key={index}
                      style={{
                        backgroundColor: job?.type && '#FFEDED',
                      }}
                    >
                      <td className="py-3 col0-width">{job?.number ?? '-'}</td>
                      <td className="py-3 col1-width ">{job?.truck.name}</td>
                      <td className="py-3 col2-width">
                        {getZones(job, filteredZones)}
                      </td>
                      <td className="py-3 col3-width">
                        {job?.type
                          ? '-'
                          : `${getTime(job.start_time)} - ${
                              job.end_time ? getTime(job.end_time) : 'Active'
                            }`}{' '}
                        <span className="sub-data">
                          {job?.type
                            ? ''
                            : `(` + toHoursAndMinutes(job.jobMinutes) + `)` ||
                              '(0 hour)'}
                        </span>
                      </td>
                      <td className="py-3 col4-width">
                        {toHoursAndMinutes(job?.pumpMinutes) || '0 hour'}
                      </td>
                      <td className="py-3 col5-width ">
                        {job?.delay_events ? (
                          <>
                            {job?.delay_events}
                            <span className="sub-data">
                              {' '}
                              ({job?.delayMinutes + ` mins`})
                            </span>
                          </>
                        ) : (
                          '-'
                        )}
                      </td>
                      <td className="py-3 col6-width">
                        {(job.litres ?? '0') + ' L'}{' '}
                        <span className="sub-data">
                          {' '}
                          {job.tank ? `(` + job.tank + `)` : ''}
                        </span>
                      </td>
                      <td className="py-3 col7-width">
                        {job?.type ? '-' : showJobStatus(job)}
                      </td>
                      <td className="py-3 col8-width">
                        {/* Don't display status for unscheduled jobs */}
                        {job?.type ? 'Unscheduled' : 'Scheduled'}
                      </td>
                      <td className="py-3 col9-width">
                        {(job?.operator?.first_name ?? '-') +
                          ' ' +
                          (job?.operator?.last_name ?? '')}
                      </td>
                    </tr>
                  ))}
            </tbody>
          );
        })}
    </>
  );
};

function JobsHistory() {
  const { ApiHandler } = useContext(UserContext);
  const query = useRef('');
  const fetchedStartDate = useRef(moment().subtract(7, 'd'));
  const fetchedEndDate = useRef(moment());
  const data = useRef([]); //original data
  const isFirstLoad = useRef(true); // used to get skip the offset on first render
  const [searchedData, setSearchedData] = useState({});
  const [loading, setLoading] = useState(false);
  const [filteredData, setFilteredData] = useState({
    trucks: [],
    delays: [],
    zones: [],
    startDate: '',
    endDate: '',
    status: [],
    types: [],
    operator: '',
  });
  const [totalFetchedLength, setTotalFetchedLength] = useState(0);
  const [downloading, setDownloading] = useState(false);
  const [abortController, setAbortController] = useState(null);
  const [hasMore, setHasMore] = useState(true);
  const [showDownload, setShowDownload] = useState(false);
  const [zonePDFData, setZonePDFData] = useState({});
  const [zonePDFJobs, setZonePDFJobs] = useState([]);
  const [buttonPressLoading, setButtonPressLoading] = useState(false);
  const [reportDate, setReportDate] = useState('');

  const getJobs = useCallback(
    async (controller, filterData = filteredData) => {
      try {
        setLoading(true);
        if (
          filterData.startDate &&
          moment(fetchedEndDate.current) < moment(filterData.startDate)
        )
          return setHasMore(false);
        if (
          filterData.startDate &&
          moment(fetchedStartDate.current) < moment(filterData.startDate)
        ) {
          fetchedStartDate.current = filterData.startDate;
        }
        if (
          filterData.endDate &&
          moment(fetchedEndDate.current) > moment(filterData.endDate)
        ) {
          fetchedEndDate.current = filterData.endDate;
        }

        const reqParam = {
          trucks: filterData.trucks,
          delays: filterData.delays,
          zones: filterData.zones,
          startDate: fetchedStartDate.current,
          endDate: fetchedEndDate.current,
          status: filterData.status,
          operator: query.current,
          types: filterData.types,
        };
        if (!query.current) {
          delete reqParam.operator;
        }
        let response = await ApiHandler({
          endPoint: ENDPOINTS.getJobs,
          method: API_METHODS.POST,
          reqParam: reqParam,
          signal: controller,
        });
        if (response.status === 202) {
          setHasMore(false);
        } else {
          setHasMore(true);
          let dataLengthbefore = data.current.length;

          const newData = [
            ...response.data.jobs,
            ...response.data.unscheduledJob,
          ];
          if (!data.current.length) data.current = newData;
          else {
            newData.forEach((item) => {
              if (
                !data.current.some(
                  (currentItem) => currentItem.number === item.number,
                )
              ) {
                data.current.push(item);
              }
            });
          }
          let dataLengthAfter = data.current.length;
          isFirstLoad.current = false;
          const groupedData = groupByDate(data.current, 'start_time');
          setSearchedData(groupedData);
          let length = 0;
          Object.keys(groupedData).forEach((key, index) => {
            length += groupedData[key].length ?? 0;
          });
          setTotalFetchedLength(length + Object.keys(groupedData)?.length ?? 0);
          if (dataLengthbefore === dataLengthAfter) await fetchMore();
        }
      } catch (error) {
        toast.error(error?.message);
      } finally {
        setLoading(false);
      }
    },
    [ApiHandler, filteredData],
  );

  const fetchMore = useCallback(async () => {
    fetchedEndDate.current = moment(fetchedStartDate.current).subtract(1, 'd');
    fetchedStartDate.current = moment(fetchedStartDate.current).subtract(
      7,
      'd',
    );
    const newAbortController = new AbortController();
    setAbortController(newAbortController);
    await getJobs(newAbortController.signal);
  }, [getJobs]);

  // handle initial data load to ensure enough data is displayed
  useEffect(() => {
    const controller = new AbortController();
    if (isFirstLoad.current) {
      getJobs(controller.signal);
    } else if (totalFetchedLength > 15 || !hasMore) {
      return;
    } else {
      fetchMore(controller.signal);
    }
    return () => {
      controller.abort();
    };
  }, [fetchMore, getJobs, totalFetchedLength, hasMore]);

  const onFilterChange = (filterData) => {
    fetchedEndDate.current = moment(
      filterData.endDate ? filterData?.endDate : new Date(),
    );
    fetchedStartDate.current = moment(
      filterData.endDate ? filterData?.endDate : new Date(),
    ).subtract(7, 'd');

    if (
      filterData.startDate &&
      fetchedStartDate.current < moment(filterData.startDate)
    ) {
      fetchedStartDate.current = moment(filterData.startDate);
    }

    if (
      filterData.endDate &&
      fetchedEndDate.current > moment(filterData.endDate)
    ) {
      fetchedEndDate.current = moment(filterData.endDate);
    }
    setFilteredData(filterData);

    data.current = [];
    if (abortController) {
      abortController.abort();
    }
    const newAbortController = new AbortController();
    setAbortController(newAbortController);
    setSearchedData({});
    getJobs(newAbortController.signal, filterData);
  };

  const debouncedRequest = useDebounce(async () => {
    // add any api call that you want to delay here.
    getJobs(abortController.signal);
  });

  const onSearchChange = (e) => {
    if (abortController) abortController.abort();
    const newAbortController = new AbortController();
    setAbortController(newAbortController);
    if (filteredData.startDate) {
      fetchedStartDate.current = filteredData.startDate;
    } else {
      fetchedStartDate.current = moment(new Date()).subtract(7, 'd');
    }
    if (filteredData.endDate) {
      fetchedEndDate.current = filteredData.endDate;
    } else {
      fetchedEndDate.current = moment(new Date());
    }
    setSearchedData({});
    query.current = e.target.value;
    data.current = [];
    debouncedRequest();
  };

  const onDownloadPress = async (data, date, type) => {
    setButtonPressLoading(true);
    let reqParam = { ...filteredData };
    delete reqParam.operator;
    const startDate =
      startOfDay(moment(date, 'YYYY-MM-DD').toISOString()).toISOString() ??
      new Date();
    const endDate =
      endOfDay(moment(date, 'YYYY-MM-DD').toISOString()).toISOString() ??
      new Date();
    reqParam.startDate = startDate;
    reqParam.endDate = endDate;
    reqParam.offset = 0;
    reqParam.limit = 1;
    reqParam.grouping = 'day';
    try {
      const zones = await ApiHandler({
        endPoint: ENDPOINTS.getDailyZones,
        method: API_METHODS.POST,
        reqParam,
      });
      setZonePDFData(zones.data[0]);
    } catch (error) {
      toast.error(error?.message);
    }
    setZonePDFJobs(data);
    setReportDate(date);
    setButtonPressLoading(false);
  };

  const onCreate = async (payload) => {
    try {
      setDownloading(true);
      let response;
      if (Object.keys(zonePDFData).length > 0) {
        let reqParam = { ...filteredData };
        delete reqParam.operator;
        const startDate =
          startOfDay(
            moment(reportDate, 'YYYY-MM-DD').toISOString(),
          ).toISOString() ?? new Date();
        const endDate =
          endOfDay(
            moment(reportDate, 'YYYY-MM-DD').toISOString(),
          ).toISOString() ?? new Date();
        reqParam.startDate = startDate;
        reqParam.endDate = endDate;
        response = await ApiHandler({
          endPoint: ENDPOINTS.getJobDailyReport,
          method: API_METHODS.POST,
          reqParam: reqParam,
          blobType: true,
        });
      } else {
        response = await ApiHandler({
          endPoint: ENDPOINTS.getJobDailyReport,
          method: API_METHODS.POST,
          reqParam: payload,
          blobType: true,
        });
      }
      const url = window.URL.createObjectURL(new Blob([response?.data]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', 'jobReport.pdf');
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      setShowDownload(false);
    } catch (err) {
      toast.error(err.message);
    }
    setDownloading(false);
  };

  return (
    <div className="jobs-tab pe-4">
      <div style={{ position: 'sticky', top: 0 }}>
        <div className="align-self-end mb-3">
          <SubHeader
            SubHeaderVisible={true}
            toShowFilter={true}
            filteredData={filteredData}
            searchedData={searchedData}
            setSearchedData={setSearchedData}
            onSearchChange={onSearchChange}
            onSaveClick={onFilterChange}
            showDownload
            onDownloadClick={() => setShowDownload(true)}
          />
        </div>

        <Table>
          <thead className="job-history-table-header">
            <tr>
              {tableHeader.map((item, index) => (
                <th
                  align="left"
                  className={`table-text text-start col${index}-width`}
                  key={index}
                >
                  {item}
                </th>
              ))}
            </tr>
          </thead>
        </Table>
      </div>
      <div id="job-history-report-container">
        <InfiniteScroll
          className="infiniteScroll"
          scrollableTarget={'job-history-report-container'}
          dataLength={totalFetchedLength}
          next={fetchMore}
          endMessage={
            <p style={{ textAlign: 'center' }}>
              <b>End of available data reached</b>
            </p>
          }
          hasMore={hasMore}
          loader={loading && <LoadingScreen />}
        >
          <Table
            responsive
            className="table-hover table overflow-scroll table-fixed"
          >
            <TableBody
              filteredZones={filteredData.zones}
              data={searchedData}
              onDownloadPress={onDownloadPress}
              fetchMore={fetchMore}
            />
          </Table>
        </InfiniteScroll>
      </div>
      {!isEmpty(zonePDFData) && (
        <PdfModal
          onClose={() => {
            setZonePDFData({});
            setZonePDFJobs([]);
          }}
          loading={downloading}
          onDownloadPress={onCreate}
        >
          <>
            {!isEmpty(zonePDFData) ? (
              <JobDailyReport
                jobs={zonePDFJobs}
                data={zonePDFData}
                filteredZones={filteredData.zones}
              />
            ) : null}
          </>
        </PdfModal>
      )}
      {showDownload && (
        <ModalView
          onClose={() => setShowDownload(false)}
          backgroundColor={'white'}
          hide
        >
          <DownloadModal
            data={filteredData}
            onCancel={() => setShowDownload(false)}
            onCreate={onCreate}
            downloading={downloading}
            type="job"
          />
        </ModalView>
      )}
      {buttonPressLoading && (
        <ModalView backgroundColor={'transparent'} hide={true}>
          <LoadingScreen />
        </ModalView>
      )}
    </div>
  );
}

export default JobsHistory;
