import React from 'react';

export default function ConfirmationPopup(props) {
  const { title, description, cancelText, confirmText, onCancel, onConfirm } =
    props;

  return (
    <div style={styles.overlay}>
      <div style={styles.popup}>
        <span style={styles.title}>{title}</span>
        {description && <span style={styles.description}>{description}</span>}
        <div style={styles.buttonContainer}>
          <button style={styles.cancelButton} onClick={onCancel}>
            {cancelText}
          </button>
          <button style={styles.confirmButton} onClick={onConfirm}>
            {confirmText}
          </button>
        </div>
      </div>
    </div>
  );
}

const styles = {
  overlay: {
    width: '100vw',
    height: '100vh',
    background: 'rgba(110, 110, 110, 0.3)',
    position: 'absolute',
    top: 0,
    right: 0,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    zIndex: 10000,
  },
  popup: {
    background: '#FFFFFF',
    boxShadow: '0px 3px 6px #00000029',
    borderRadius: '2rem',
    width: '37rem',
    height: '14.5rem',
    padding: '1.25rem 1.9rem 0',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-evenly',
    alignItems: 'center',
    marginBottom: '3.75rem',
  },
  title: {
    font: 'normal bold 1rem/1.125rem Open Sans',
    color: '#1A1A1A',
  },
  description: {
    paddingBottom: '0.625rem',
    font: 'normal normal 0.8rem Open Sans',
    letterSpacing: '0.015rem',
    color: '#1A1A1A',
    alignItems: 'center',
    textAlign: 'center',
    lineHeight: 2,
  },
  buttonContainer: {
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
  },
  cancelButton: {
    width: '5rem',
    height: '1.5rem',
    font: 'normal bold 0.8rem Open Sans',
    color: '#2B2D2F',
    display: 'flex',
    alignItems: 'center',
    backgroundColor: '#fff',
    justifyContent: 'center',
    border: '0.015rem solid #2B2D2F',
    borderRadius: '0.25rem',
    marginRight: '1rem',
  },
  confirmButton: {
    width: '5rem',
    height: '1.5625rem',
    font: 'normal bold 0.8rem Open Sans',
    background: '#2B2D2F',
    color: 'white',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    borderRadius: '0.25rem',
    border: '0.015rem solid #2B2D2F',
  },
};
