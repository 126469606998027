import { Image } from 'react-bootstrap';
import { AiOutlineEye, AiOutlineEyeInvisible } from 'react-icons/ai';
import { IoMdTrash } from 'react-icons/io';
import { MdEdit } from 'react-icons/md';
import info from './../../assets/images/info.svg';
import _ from 'lodash';
import { CallWithAuth } from '../../../pages/service/apiActions';
import { DELETE_SOURCE_DUMP, EDIT_SOURCE_DUMP } from '../../action/apiPath';
import { toast } from 'react-toastify';

const ListItem = ({ icon, text, onClick }) => (
  <li className="d-flex m-0 p-1 pt-2 ps-2" onClick={onClick}>
    {icon}
    {text}
  </li>
);

const Settings = ({
  data,
  module,
  onEditPolygon = () => {},
  reloadForcefully = () => {},
  onDemandDetailPopup = () => {},
  status,
  setPopupConfirmation = () => {},
  onSelectRow = () => {},
}) => {
  const onDeleteConfirm = async () => {
    try {
      const response = await CallWithAuth('POST', DELETE_SOURCE_DUMP, {
        _id: data?._id,
      });
      if (response.res.status === 201 || response.res.status === 200) {
        reloadForcefully();
        toast.success(`${_.capitalize(module)} point deleted successfully`, {
          autoClose: 3000,
          hideProgressBar: false,
        });
      }
    } catch (error) {
      toast.error('Server Error', {
        position: 'bottom-right',
        autoClose: 3000,
        hideProgressBar: false,
      });
    }
    setPopupConfirmation('');
  };

  const onEnableDisableGeofence = async () => {
    try {
      const response = await CallWithAuth('POST', EDIT_SOURCE_DUMP, {
        _id: data?._id,
        status: status == 'Active' ? 'Inactive' : 'Active',
      });
      if (response.res.status === 201 || response.res.status === 200) {
        reloadForcefully();
        toast.success(
          `${status == 'Active' ? 'Inactive' : 'Active'} successful`,
          {
            autoClose: 3000,
            hideProgressBar: false,
          },
        );
      }
    } catch (error) {
      toast.error('Server Error', {
        position: 'bottom-right',
        autoClose: 3000,
        hideProgressBar: false,
      });
    }
    setPopupConfirmation('');
    onSelectRow({});
  };

  return (
    <div>
      <ul
        style={{
          fontSize: '1rem',
          borderRadius: '0.8rem',
          padding: '0px 10px',
          width: 'fit-content',
        }}
      >
        <ListItem
          icon={<MdEdit style={{ fontSize: '1rem', marginRight: '0.75rem' }} />}
          text="Edit"
          onClick={() => {
            onSelectRow(data);
            onEditPolygon(data);
          }}
        />

        <ListItem
          icon={
            status == 'Inactive' ? (
              <AiOutlineEye
                style={{ fontSize: '1rem', marginRight: '0.75rem' }}
              />
            ) : (
              <AiOutlineEyeInvisible
                style={{ fontSize: '1rem', marginRight: '0.75rem' }}
              />
            )
          }
          text={
            status == 'Inactive'
              ? `Enable ${_.capitalize(module)} Point`
              : `Disable ${_.capitalize(module)} Point`
          }
          onClick={() => {
            setPopupConfirmation({
              title: `Are you sure you want to ${
                status == 'Inactive' ? 'enable' : 'disable'
              } ${data?.name} ?`,
              description:
                status == 'Inactive'
                  ? 'This geofence will now be visible on the active tab.'
                  : `This geofence will not be visible on the map and its activity will not be accounted for. You can still find it under inactive ${module} points.`,
              cancelText: 'Cancel',
              confirmText: `${status == 'Inactive' ? 'Enable' : 'Disable'}`,
              onCancel: () => {
                setPopupConfirmation('');
              },
              onConfirm: () => {
                onEnableDisableGeofence();
              },
            });
          }}
        />

        <ListItem
          icon={
            <Image
              style={{ height: '1rem', width: '1rem', marginRight: '0.85rem' }}
              src={info}
            />
          }
          text={`${_.capitalize(module)} Point Details`}
          onClick={() => {
            onDemandDetailPopup();
          }}
        />

        {status == 'Inactive' && (
          <ListItem
            icon={
              <IoMdTrash style={{ fontSize: '1rem', marginRight: '0.75rem' }} />
            }
            text="Delete"
            onClick={() => {
              setPopupConfirmation({
                title: `Do you wish to delete ${data?.name} ?`,
                description: '',
                cancelText: 'Cancel',
                confirmText: 'Delete',
                onCancel: () => {
                  setPopupConfirmation('');
                },
                onConfirm: () => {
                  onDeleteConfirm();
                },
              });
            }}
          />
        )}
      </ul>
    </div>
  );
};

export default Settings;
