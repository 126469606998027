import { LatLng, LatLngBounds } from 'leaflet';
import React, { useEffect, useState } from 'react';
import minusIcon from './../assets/svgs/minus.svg';
import plusIcon from './../assets/svgs/plus.svg';
import { TitlePopup } from './TitlePopup';
import { DetailPopup } from './DetailPopup';
import { GeofencePopup } from './GeofencePopup';
import { MaterialPopup } from './MaterialPopup';
import { map as l_map, isEmpty as l_isEmpty } from "lodash";

// hiddenAnnotation = the popup which opens when selecting a geofence
// alwaysVisibleAnnotations = the title annotation which always visible on screen, and at tort point of geofence

let map;
let mapkit = undefined;
let polylineStyle;
let globalCreateMode = false
let polygonAnnotations = [];
let currentPolygonFlyout
let currentPolygon = null
let alwaysVisibleAnnotations = []
let hiddenAnnotations = []
let currentHiddenAnnotation = null
let hiddenAnnotationWhenSelectedFromTable = null
let polylineOverlays = [];
let points = []
let markerAnnotations = [];
let currentMarkerFlyout
let currentMarker = null
let createModeMarker = null
let pointMarker = null
let currentDetailPopup = null

let globalType = "select";

export const Map = ({
    mapRef,
    type,
    materials,
    getMaterialColor,
    module,
    polygons = [],
    markers = {},
    detailPopup = {},
    selectedMarker = {},
    selectedPolygon = {},
    selectedPoint = {},
    onMarkerClick = () => { },
    onPolygonClick = () => { },
    onDetailPopupClose = () => { },
    resetState,
    polygonType = "Active", // Active , Inactive
    selectedTruckForLoadMarkers
}) => {

    const [setupCompleted, setSetupCompleted] = useState(false);
    const [mode, setMode] = useState(type);

    useEffect(() => {
        globalCreateMode = type == "create" ? true : false
        setMode(type ?? "select")
        const htmlElement = document.getElementById("map-container");
        if (type === "create") {
            htmlElement.classList.add("mm-custom-cursor");
        } else {
            htmlElement.classList.remove("mm-custom-cursor");
        }
    }, [type])

    useEffect(() => {
        resetPolylines()
    }, [resetState])

    const removeMarkers = () => {
        if (!markerAnnotations?.length) return;
        markerAnnotations.forEach((annotation) => {
            map.removeAnnotation(annotation)
        });
        markerAnnotations = []
    }

    const removeAlwaysVisibleAnnotations = () => {
        if (!alwaysVisibleAnnotations?.length) return;
        alwaysVisibleAnnotations.forEach((annotation) => {
            map.removeAnnotation(annotation)
        });
        alwaysVisibleAnnotations = []
    }

    const removeAnnotation = () => {
        if (currentMarkerFlyout?.visible) {
            currentMarkerFlyout.visible = false;
            map.removeAnnotation(currentMarkerFlyout)
        }
    }

    const handleAnnotation = ({ marker, coordinates }) => {

        removeAnnotation()
        const markerCoord = new mapkit.Coordinate(coordinates.latitude, coordinates.longitude);
        const options = {
            data: marker,
            anchorOffset: new DOMPoint(0, 50),
        };
        let annotation = new mapkit.Annotation(markerCoord, MaterialPopup, options);
        map.setCenterAnimated(markerCoord);
        map.addAnnotation(annotation)

        currentMarkerFlyout = annotation

        annotation.visible = true;


    }

    const createMarker = ({ coordinates, marker, onMarkerClick, isSelected, unknown, highlighted = false }) => {

        const markerCoord = new mapkit.Coordinate(coordinates?.latitude, coordinates?.longitude);
        let color = "rgba(0,0,0,0)";
        materials?.forEach((material, index) => {
            if (material?.MaterialType == marker?.material) {
                color = material?.color
            }
        })
        // border:3px solid red; border-radius:20px; 
        let svgContent;
        if (highlighted === true) {
            svgContent =
                `<?xml version="1.0" encoding="utf-8"?>
            <svg viewBox="0 0 150 150" width="40" height="40" xmlns="http://www.w3.org/2000/svg">
                <ellipse style="fill: ${!unknown ? color : 'rgba(255, 0, 0, 1 )'}; stroke-width: 7; stroke: rgb(255, 255, 255);" cx="76.913" cy="74.565" rx="28.056" ry="29.967" transform="matrix(0.9999999999999999, 0, 0, 0.9999999999999999, 0, -7.105427357601002e-15)"/>

                <ellipse style="fill: none; stroke-width: 9; stroke: #64D6DB;" cx="74.913" cy="74.565" rx="50.0" ry="50.0" transform="matrix(0.9999999999999999, 0, 0, 0.9999999999999999, 0, -7.105427357601002e-15)"/>
            </svg>
            `;
        } else {
            svgContent =
                `<?xml version="1.0" encoding="utf-8"?>
            <svg viewBox="0 0 150 150" width="40" height="40" xmlns="http://www.w3.org/2000/svg">
                <ellipse style="fill: ${!unknown ? color : "rgba(255, 0, 0, 1 )"}; stroke-width: 7; stroke: rgb(255, 255, 255 );" cx="76.913" cy="74.565" rx="28.056" ry="29.967" transform="matrix(0.9999999999999999, 0, 0, 0.9999999999999999, 0, -7.105427357601002e-15)"/>
            </svg>
            `;
        }

        const markerAnnotation = new mapkit.ImageAnnotation(
            markerCoord,
            {
                url: { 1: `data:image/svg+xml;base64,${btoa(svgContent)}` },
                anchorOffset: new DOMPoint(0, 0),
            }
        );

        if (!unknown) {
            markerAnnotation.addEventListener('select', () => {
                handleAnnotation({ marker, coordinates });
                onMarkerClick({ marker, select: true });
            });
        }

        markerAnnotation.addEventListener('deselect', () => {
            removeAnnotation()
            onMarkerClick({ marker, select: false });
        });

        markerAnnotations.push(markerAnnotation);
        map.addAnnotation(markerAnnotation);
    }

    const isLinesIntersecting = (line1, line2) => {
        const { latitude: x1, longitude: y1 } = line1[0];
        const { latitude: x2, longitude: y2 } = line1[1];
        const { latitude: x3, longitude: y3 } = line2[0];
        const { latitude: x4, longitude: y4 } = line2[1];

        const crossProduct = (x1 - x2) * (y3 - y4) - (y1 - y2) * (x3 - x4);
        if (crossProduct === 0) {
            return false; // Lines are parallel
        }

        const x_component = ((x1 - x3) * (y3 - y4) - (y1 - y3) * (x3 - x4)) / crossProduct;
        const y_component = -((x1 - x2) * (y1 - y3) - (y1 - y2) * (x1 - x3)) / crossProduct;

        if (x_component >= 0 && x_component <= 1 && y_component >= 0 && y_component <= 1) {
            return true; // Intersection detected
        }

        return false; // No intersection
    };

    const createHiddenAnnotationWhenSelectedFromTable = ({
        data,
    }) => {
        let coordinates;
        // this will return first point in geofence to show detailed flyout
        let firstPoint = new mapkit.Coordinate(
            parseFloat(data?.coordinates[0]?.latitude ?? "0.000000") ?? -37.190076,
            parseFloat(data?.coordinates[0]?.longitude ?? "0.000000") ?? 174.725014
        );

        // gettting first coordinate in geofence
        coordinates = data?.coordinates?.map((coordinate) => {
            const lat = parseFloat(coordinate?.latitude ?? "0.000000")
            const lng = parseFloat(coordinate?.longitude ?? "0.000000")
            firstPoint = new mapkit.Coordinate(lat, lng);
            return new mapkit.Coordinate(lat, lng);
        });


        // bounds returns the coordinates values to make map center align
        const bounds = new LatLngBounds(
            [coordinates[0]]?.map((firstCoordinate) => {
                if (firstCoordinate?.latitude && firstCoordinate?.longitude) {
                    return new LatLng(firstCoordinate.latitude, firstCoordinate.longitude);
                } else return new LatLng(-37.186454, 174.718004);
            })
        );

        const center = new mapkit.Coordinate(
            bounds.getCenter().lat,
            bounds.getCenter().lng
        );

        let hiddenAnnotation = new mapkit.Annotation(
            center,
            GeofencePopup,
            {
                data,
                visible: true,
                getMaterialColor: getMaterialColor,
                onSelect: (event, select) => { },
                onClose: (event, select) => {
                    if (hiddenAnnotationWhenSelectedFromTable) {
                        hiddenAnnotationWhenSelectedFromTable.visible = false
                        hiddenAnnotationWhenSelectedFromTable = null
                    }
                },
            }
        );

        if (hiddenAnnotationWhenSelectedFromTable) {
            hiddenAnnotationWhenSelectedFromTable.visible = false
            hiddenAnnotationWhenSelectedFromTable = null
        }
        hiddenAnnotation.visible = true
        hiddenAnnotationWhenSelectedFromTable = hiddenAnnotation
        map.addAnnotation(hiddenAnnotation);
    };

    const createPolygon = ({
        data,
        mode,
    }) => {

        if (currentPolygon) {
            currentPolygon.visible = false;
            currentPolygon = null
        }

        let coordinates;
        let t = new mapkit.Coordinate(
            parseFloat(data?.coordinates[0]?.latitude ?? "0.000000") ?? -37.190076,
            parseFloat(data?.coordinates[0]?.longitude ?? "0.000000") ?? 174.725014
        );

        coordinates = data?.coordinates?.map((coordinate) => {
            const lat = parseFloat(coordinate?.latitude ?? "0.000000")
            const lng = parseFloat(coordinate?.longitude ?? "0.000000")
            t = new mapkit.Coordinate(lat, lng);
            return new mapkit.Coordinate(lat, lng);
        });

        const initialStyle = new mapkit.Style({
            fillColor: "#FDC93A33",
            fillOpacity: 0.5,
            lineWidth: 1.5,
            lineJoin: "round",
            strokeColor: "#FFBF10",
        });

        const dumpStyle = new mapkit.Style({
            fillColor: "#3AB2FD46",
            fillOpacity: 0.5,
            lineWidth: 1.5,
            lineJoin: "round",
            strokeColor: "#3693FF",
        });


        const selectedSourceStyle = new mapkit.Style({
            fillColor: "#FDC93A33",
            fillOpacity: 1 ,
            lineWidth: 2.5,
            lineJoin: "round",
            strokeColor: "#FFBF10",
        });


        const selectedStyle = new mapkit.Style({
            fillColor: "#3AB2FD66",
            fillOpacity: 1,
            lineWidth: 2.5,
            lineJoin: "round",
            strokeColor: "#3151FF",
        });

        const bounds = new LatLngBounds(
            coordinates?.map((p) => {
                if (p?.latitude && p?.longitude) {
                    return new LatLng(p.latitude, p.longitude);
                } else return new LatLng(-37.186454, 174.718004);
            })
        );

        const center = new mapkit.Coordinate(
            bounds.getCenter().lat,
            bounds.getCenter().lng
        );

        const northPoint = new mapkit.Coordinate(
            parseFloat(data?.coordinates[0]?.latitude),
            parseFloat(data?.coordinates[0]?.longitude)
        );

        let hiddenAnnotation = new mapkit.Annotation(
            center,
            GeofencePopup,
            {
                data,
                visible: false,
                getMaterialColor: getMaterialColor,
                onSelect: (event, select) => { },
                onClose: (event, select) => {
                    if (currentHiddenAnnotation) {
                        currentHiddenAnnotation.visible = false
                        currentHiddenAnnotation = null
                    }
                    if (hiddenAnnotationWhenSelectedFromTable) {
                        hiddenAnnotationWhenSelectedFromTable.visible = false
                        hiddenAnnotationWhenSelectedFromTable = null
                    }
                },
            }
        );
        if (mode === "-default") hiddenAnnotations.push(map.addAnnotation(hiddenAnnotation));

        let visibleAnnotation = new mapkit.Annotation(
            northPoint,
            TitlePopup,
            {
                data: { title: `${data?.name}` },
                visible: true,
                onSelect: (event, select) => {
                    if (globalType === "select")
                        onPolygonClick(data)
                },
                onClose: (event, select) => {
                    if (globalType === "select") {
                        if (currentHiddenAnnotation) {
                            currentHiddenAnnotation.visible = false
                            currentHiddenAnnotation = null
                        }
                        if (hiddenAnnotationWhenSelectedFromTable) {
                            hiddenAnnotationWhenSelectedFromTable.visible = false
                            hiddenAnnotationWhenSelectedFromTable = null
                        }
                        onPolygonClick({})
                    }
                },
            }
        );
        if (mode == "-default") alwaysVisibleAnnotations.push(map.addAnnotation(visibleAnnotation));


        let polygon = new mapkit.PolygonOverlay(coordinates, {
            style: mode == "-default" ? module == "dump" ? dumpStyle : initialStyle : module == "dump" ? selectedStyle : selectedSourceStyle
        });

        return polygon;
    };

    const resetPolylines = () => {
        currentMarker && map.removeAnnotation(currentMarker);
        if (!polylineOverlays || !map) return;
        map.removeOverlays(polylineOverlays);
        points = [];
        polylineOverlays = [];
    };

    const singleTapEvent = async ({
        event,
        isPolygonClicked,
        latlng
    }) => {

        let coordinates;
        isPolygonClicked
            ? (coordinates = latlng)
            : (coordinates = map.convertPointOnPageToCoordinate(event.pointOnPage));

        points.push(coordinates);

        if (points.length === 1) {
            const { latitude, longitude } = map.convertPointOnPageToCoordinate(event.pointOnPage)
            let marker = new mapkit.MarkerAnnotation(
                map.convertPointOnPageToCoordinate(event.pointOnPage),
                { color: "red" }
            );
            createModeMarker = marker
            map.addAnnotation(marker);
            onPolygonClick({
                coordinates: [{ latitude: latitude?.toFixed(6)?.toString(), longitude: longitude?.toFixed(6)?.toString() }],
                isIntersecting: false,
            });
            return;
        }

        if (createModeMarker) {
            map.removeAnnotation(createModeMarker);
            createModeMarker = null
        }

        let polyline = new mapkit.PolylineOverlay(
            [points[points.length - 2], points[points.length - 1]],
            { style: polylineStyle }
        );

        polyline.addEventListener("select", function (event) {
            singleTapEvent({
                latlng: event.target._impl?._points[0],
                isPolygonClicked: true,
            });
        });

        let overlay = map.addOverlay(polyline);
        polylineOverlays.push(overlay);

        let localPoints = points

        const isIntersecting = points.some((point, i) => {
            if (i < points.length - 3) {
                const currentLine = [points[i], points[i + 1]];
                return isLinesIntersecting(currentLine, [
                    points[points.length - 2],
                    points[points.length - 1],
                ]);
            }
            return false;
        });

        const transformedArr = localPoints
            .slice(0, isIntersecting ? localPoints?.length - 1 : localPoints.length)
            .map(({ latitude, longitude }) => {
                return {
                    latitude:
                        typeof latitude == "number" ? (latitude?.toFixed(6)?.toString()) : latitude,
                    longitude:
                        typeof longitude == "number"
                            ? (longitude?.toFixed(6))?.toString()
                            : longitude,
                };
            });

        onPolygonClick({
            coordinates: transformedArr,
            isIntersecting: isIntersecting,
        });

        if (!isIntersecting) return;

        setMode("select")
        let polygon = createPolygon({
            data: { coordinates: points.slice(0, points.length - 1) },
            mode: "create",
        });

        currentPolygon = polygon;
        map.addOverlay(polygon)
        resetPolylines();
    };

    const setupMapKitJs = async () => {
        if (!window.mapkit || window.mapkit.loadedLibraries?.length === 0) {
            await new Promise((resolve) => {
                window.initMapKit = resolve;
            });
            delete window.initMapKit;
        }

        const jwt = process.env.REACT_APP_APPLE_MAP_TOKEN;
        window.mapkit.init({
            authorizationCallback: (done) => {
                done(jwt);
            },
            language: 'en',
        });
    };

    const setupMap = () => {
        const mapCenter = new mapkit.Coordinate(-37.2052003, 174.7239819);

        const mapRegion = new mapkit.CoordinateRegion(
            mapCenter,
            new mapkit.CoordinateSpan(0.06, 0.06)
        );

        map = new mapkit.Map('map-container', {
            center: mapCenter,
            mapType: mapkit.Map.MapTypes.Satellite,
            region: mapRegion,
            showsCompass: mapkit.FeatureVisibility.Hidden,
        });
        mapRef.current = map;
        map.cameraZoomRange = new mapkit.CameraZoomRange(100, 8000);
        map.cameraBoundary = mapRegion.toMapRect();
        map.cameraDistance = 3300;
        map.addEventListener('single-tap', (event) => {
            if (currentMarkerFlyout?.visible) {
                currentMarkerFlyout.visible = false;
                map.removeAnnotation(currentMarkerFlyout)
            }
            if (currentMarker != null) {
                map.removeAnnotation(currentMarker)
                currentMarker = null
                onMarkerClick(null);
            }
            onMarkerClick({ marker: {}, select: false });
            if (globalCreateMode) singleTapEvent({
                event,
            });

        });
    };

    const fetch = async () => {
        await setupMapKitJs();
        mapkit = window.mapkit;
        setupMap();
        polylineStyle = new mapkit.Style({
            lineWidth: 2,
            lineJoin: "round",
            lineDash: [8, 4],
            strokeColor: "#53D7FE",
        });
        setSetupCompleted(true);
    }

    useEffect(() => {
        fetch()
        return () => {
            delete window.initMapKit;
        };
    }, []);

    useEffect(() => {
        if (!setupCompleted) return;
        if (map && typeof map === 'object') {
            if (type == "create" || type == "edit") {
                map.isZoomEnabled = false;
                map.isScrollEnabled = false;
            } else {
                map.isZoomEnabled = true;
                map.isScrollEnabled = true;
            }
        } else {
            console.log("map is not a valid object");
        }

        globalType = type;

    }, [setupCompleted, type]);

    useEffect(() => {

        removeMarkers();
        if (currentHiddenAnnotation) {
            currentHiddenAnnotation.visible = false
            currentHiddenAnnotation = null
        }
        if (hiddenAnnotationWhenSelectedFromTable) {
            hiddenAnnotationWhenSelectedFromTable.visible = false
            hiddenAnnotationWhenSelectedFromTable = null
        }
        let boundMarkers = []
        if (module === "dump") {
            l_map(markers, (value, key, index) => {
                if (selectedTruckForLoadMarkers && selectedTruckForLoadMarkers != key) {
                    return
                }
                value.forEach((marker, index) => {
                    if (marker?.dumpLat && marker?.dumpLon) {
                        boundMarkers?.push({ coordinates: { latitude: marker?.dumpLat, longitude: marker?.dumpLon } })
                        createMarker({
                            coordinates: { latitude: marker?.dumpLat, longitude: marker?.dumpLon },
                            marker: marker,
                            onMarkerClick: onMarkerClick,
                            unknown: !(marker?.dumpPoint && marker.dumpActive === "Active") ? true : false,
                            highlighted: selectedPolygon?.name === 'Unknown'
                        });
                    }
                });
            })
        }

        if (module === "source") {
            l_map(markers, (value, key, index) => {
                if (selectedTruckForLoadMarkers && selectedTruckForLoadMarkers != key) {
                    return
                }
                value.forEach((marker, index) => {
                    if (marker?.sourceLat && marker?.sourceLon) {
                        boundMarkers?.push({ coordinates: { latitude: marker?.sourceLat, longitude: marker?.sourceLon } })
                        createMarker({
                            coordinates: { latitude: marker?.sourceLat, longitude: marker?.sourceLon },
                            marker: marker,
                            onMarkerClick: onMarkerClick,
                            unknown: !(marker?.sourcePoint && marker.sourceActive === "Active") ? true : false,
                            highlighted: selectedPolygon?.name === 'Unknown'
                        });
                    }
                });
            })
        }

        if (selectedTruckForLoadMarkers) {
            const boundCoordinates = boundMarkers?.map(({ coordinates }) => {
                return new LatLng(coordinates?.latitude, coordinates?.longitude);
            });

            if (!l_isEmpty(boundCoordinates)) {
                const bounds = new LatLngBounds(boundCoordinates);
                if (bounds.getCenter().lat && bounds.getCenter().lng) {
                    const center = new mapkit.Coordinate(
                        bounds.getCenter().lat,
                        bounds.getCenter().lng
                    );
                    map.setCenterAnimated(center);
                }
            }
        }


    }, [JSON?.stringify(markers), materials, module, selectedTruckForLoadMarkers, selectedPolygon?.name])

    const makeUnknownMarkerOnRowSelection = () => {
        if (module === "dump") {
            l_map(markers, (value, key, index) => {
                if (selectedTruckForLoadMarkers && selectedTruckForLoadMarkers != key) {
                    return
                }
                value.forEach((marker, index) => {
                    if (marker?.dumpLat && marker?.dumpLon) {
                        createMarker({
                            coordinates: { latitude: marker?.dumpLat, longitude: marker?.dumpLon },
                            marker: marker,
                            onMarkerClick: onMarkerClick,
                            unknown: !(marker?.dumpPoint && marker.dumpActive === "Active") ? true : false,
                            highlighted: true
                        });
                    }
                });
            })
        }

        if (module === "source") {
            l_map(markers, (value, key, index) => {
                if (selectedTruckForLoadMarkers && selectedTruckForLoadMarkers != key) {
                    return
                }
                value.forEach((marker, index) => {
                    if (marker?.sourceLat && marker?.sourceLon) {
                        createMarker({
                            coordinates: { latitude: marker?.sourceLat, longitude: marker?.sourceLon },
                            marker: marker,
                            onMarkerClick: onMarkerClick,
                            unknown: !(marker?.sourcePoint && marker.sourceActive === "Active") ? true : false,
                            highlighted: true
                        });
                    }
                });
            })
        }
    }

    useEffect(() => {

        if (!setupCompleted) return;

        if (currentDetailPopup) {
            map.removeAnnotation(currentDetailPopup)
            currentDetailPopup = null
        }

        let coordinates;
        if (!detailPopup?.coordinates?.length) return;

        let firstPoint = new mapkit.Coordinate(
            parseFloat(detailPopup?.coordinates[0]?.latitude ?? "0.000000") ?? -37.190076,
            parseFloat(detailPopup?.coordinates[0]?.longitude ?? "0.000000") ?? 174.725014
        );

        coordinates = detailPopup?.coordinates?.map((coordinate) => {
            const lat = parseFloat(coordinate?.latitude ?? "0.000000")
            const lng = parseFloat(coordinate?.longitude ?? "0.000000")
            if (lat && lng) {
                firstPoint = new mapkit.Coordinate(lat, lng);
                return new mapkit.Coordinate(lat, lng);
            } else {
                return firstPoint;
            }
        });

        const bounds = new LatLngBounds(
            [coordinates[0]]?.map((firstCoordinate) => {
                if (firstCoordinate?.latitude && firstCoordinate?.longitude) {
                    return new LatLng(firstCoordinate.latitude, firstCoordinate.longitude);
                } else return new LatLng(-37.186454, 174.718004);
            })
        );

        const center = new mapkit.Coordinate(
            bounds.getCenter().lat,
            bounds.getCenter().lng 
        );

        let detailAnnotation = new mapkit.Annotation(
            center,
            DetailPopup,
            {
                data: detailPopup,
                visible: true,
                onClose: (event, select) => {
                    onDetailPopupClose()
                    if (currentDetailPopup) {
                        map.removeAnnotation(currentDetailPopup)
                        currentDetailPopup = null
                    }
                },
            }
        );

        currentDetailPopup = map.addAnnotation(detailAnnotation);
    }, [JSON.stringify(detailPopup)])

    useEffect(() => {
        if (!setupCompleted) return;

        if (currentMarkerFlyout?.visible) {
            currentMarkerFlyout.visible = false;
            map.removeAnnotation(currentMarkerFlyout)
        }

        if (currentMarker) {
            map.removeAnnotation(currentMarker)
            currentMarker = null
        }

        if (selectedMarker && Object.keys(selectedMarker).length > 0) {
            createMarker({ marker: selectedMarker, isSelected: true, onMarkerClick: onMarkerClick })
            const mapCenter = new mapkit.Coordinate(selectedMarker?.coordinates?.latitude, selectedMarker?.coordinates?.longitude)
            map.setCenterAnimated(mapCenter);
        }

    }, [selectedMarker])

    useEffect(() => {
        if (!setupCompleted) return;
        if (currentPolygonFlyout?.visible) {
            currentPolygonFlyout.visible = false;
            map.removeAnnotation(currentPolygonFlyout)
        }

        if (selectedPolygon?.name === "Unknown") {
            makeUnknownMarkerOnRowSelection();
        } else {
            if (hiddenAnnotationWhenSelectedFromTable) {
                hiddenAnnotationWhenSelectedFromTable.visible = false
                hiddenAnnotationWhenSelectedFromTable = null
            }
            if (currentPolygon?.visible) {
                currentPolygon.visible = false;
                currentPolygon = null
            }

            const isPolygonIntersecting = selectedPolygon?.isIntersecting ?? true

            if (selectedPolygon?.coordinates?.length && isPolygonIntersecting) {
                const polygon = createPolygon({
                    data: selectedPolygon,
                    mode: selectedPolygon?.mode ?? "select"
                })

                if (selectedPolygon?.name && type != "edit") {
                    createHiddenAnnotationWhenSelectedFromTable({ data: selectedPolygon });
                } else if (type == "edit") {
                    if (hiddenAnnotationWhenSelectedFromTable) {
                        hiddenAnnotationWhenSelectedFromTable.visible = false
                        hiddenAnnotationWhenSelectedFromTable = null
                    }
                }

                currentPolygon = polygon;
                map.addOverlay(polygon)
            }
        }

    }, [selectedPolygon?.coordinates, selectedPolygon?.mode, type])

    useEffect(() => {

        if (pointMarker) {
            map.removeAnnotation(pointMarker)
            pointMarker = null
        }

        removeAlwaysVisibleAnnotations()
        if (currentPolygonFlyout?.visible) {
            currentPolygonFlyout.visible = false;
            map.removeAnnotation(currentPolygonFlyout)
        }

        if (currentPolygon?.visible) {
            currentPolygon.visible = false;
            currentPolygon = null
        }

        polygonAnnotations.forEach((overlay) => {
            if (overlay?.visible == true || overlay?.visible == false)
                overlay.visible = false;
        });

        let allCoordinates = []
        polygons
            ?.filter((dumpPoint) => dumpPoint?.coordinates?.length > 2)
            ?.filter((dumpPoint) => dumpPoint?.status === polygonType)
            ?.forEach((polygon, index) => {
                const poly = createPolygon({
                    data: polygon,
                    mode: "-default"
                })

                polygon?.coordinates?.map((coordinate) => {
                    const lat = parseFloat(coordinate?.latitude ?? "0.000000")
                    const lng = parseFloat(coordinate?.longitude ?? "0.000000")
                    if (lat && lng) {
                        allCoordinates?.push({ latitude: lat, longitude: lng })
                    }
                })

                polygonAnnotations.push(map.addOverlay(poly))
            });

        const boundCoordinates = allCoordinates?.map((coordinate) => {
            return new LatLng(coordinate?.latitude, coordinate?.longitude);
        });

        if (!l_isEmpty(boundCoordinates)) {
            const bounds = new LatLngBounds(boundCoordinates);
            if (bounds.getCenter()?.lat && bounds.getCenter()?.lng) {
                const center = new mapkit.Coordinate(
                    bounds.getCenter().lat,
                    bounds.getCenter().lng
                );
                mapRef.current._impl.zoomLevel = 16.2;
                map.setCenterAnimated(center);
            }
        }

    }, [polygons, materials, polygonType])

    useEffect(() => {
        if (!setupCompleted) return;
        if (pointMarker) {
            map.removeAnnotation(pointMarker)
            pointMarker = null
        }

        if (parseFloat(selectedPoint.latitude ?? '0') && parseFloat(selectedPoint?.longitude ?? "0")) {
            const pointCoordinates = new mapkit.Coordinate(parseFloat(selectedPoint?.latitude), parseFloat(selectedPoint?.longitude))
            let marker = new mapkit.MarkerAnnotation(
                pointCoordinates,
                { color: "blue" }
            );
            pointMarker = marker
            map.addAnnotation(pointMarker);
            map.setCenterAnimated(pointCoordinates);

        }
    }, [selectedPoint?.latitude, selectedPoint?.longitude])

    const handleZoom = (delta) => {
        let counter = 0;
        const interval = setInterval(() => {
            mapRef.current._impl.zoomLevel += delta;
            counter += 0.05;
            if (counter >= 1) {
                clearInterval(interval);
            }
        }, 20);
    };

    return (
        <>
            <section
                id="map-container"
                style={{ height: '100%', width: '100%' }}
            />

            <div style={{ bottom: "0rem", marginBottom: '2rem', position: 'absolute', zIndex: 1, borderRadius: "4px ", right: "1rem", backgroundColor: 'white' }}>
                <div className="d-flex align-items-center justify-content-center cursor-pointer p-2" onClick={() => handleZoom(0.06)}>
                    <img src={plusIcon} alt="Zoom In" style={{ width: "1.5rem", height: "1.5rem" }} />
                </div>
                <div className="d-flex align-items-center justify-content-center cursor-pointer p-2" onClick={() => handleZoom(-0.06)}>
                    <img src={minusIcon} alt="Zoom Out" style={{ width: "1rem", height: "1rem" }} />
                </div>
            </div>

            <div
                style={{
                    bottom: "0rem",
                    marginBottom: "2rem",
                    position: "absolute",
                    zIndex: 1,
                    right: "4.1rem",
                    backgroundColor: "white",
                    borderRadius: "4px ",
                }}
            >
                <div
                    style={{
                        cursor: "pointer",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        height: "2rem",
                    }}
                    onClick={(e) => {
                        e.preventDefault();
                        mapRef.current._impl.zoomLevel = 1;
                    }}
                >
                    <div style={{ fontSize: "0.8rem" }} className="p-1 px-2">
                        Fit Screen
                    </div>
                </div>
            </div>


        </>
    );
};
