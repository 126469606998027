import moment from 'moment';
import React, { useContext, useEffect, useState, userContext } from 'react';
import SubHeader from '../../../dustControl/components/SubHeader';
import PdfModal from '../../../preStartChecks/Components/PdfModal';
import { CallWithAuth } from '../../../pages/service/apiActions';
import { GET_HISTORY } from '../../action/apiPath';
import MaterialMovementReport from '../Report/MaterialMovementReport';
import HistoryTable from './HistoryTable';
import { UserContext } from '../../../pages/service/userContext';
import { ENDPOINTS } from '../../../dustControl/utils/constants';
import { API_METHODS } from '../../../preStartChecks/utils/constants';
import * as toast from '../../../dustControl/components/toast';
import { PDFDocument } from 'pdf-lib';

const MaterialMovementsHistory = ({}) => {
  const { ApiHandler } = useContext(UserContext);

  const [loading, setLoading] = useState(false);
  const [showPDF, setShowPDF] = useState(false);
  const [filters, setFilters] = useState({
    date: moment().format('yyyy-MM-DD'),
    // date: moment().format("2023-10-30"),
    searchQuery: '',
    trucks: [],
    excavators: [],
    operators: [],
    dumppoint: [],
    sourcepoint: [],
    materials: [],
  });
  const [filteredData, setFilteredData] = useState({});
  const [formattedData, setFormattedData] = useState([]);
  const [data, setData] = useState({});

  const formatDataIntoArray = (data) => {
    let entries = [];
    Object.entries(data)?.map(([key, value], index) => {
      value?.map((load) => {
        let entry = {
          truckName: key,
          ...load,
        };
        entries.push(entry);
      });
    });
    return entries;
  };

  const makeTruckWiseData = (data) => {
    let objectWiseData = {};
    data?.map((entry) => {
      const objectTitle = entry?.truckName || 'Unknown';
      objectWiseData[objectTitle] = objectWiseData[objectTitle] || [];
      objectWiseData[objectTitle].push(entry);
    });

    return objectWiseData;
  };

  const fetchTrucks = async () => {
    try {
      setLoading(true);
      let response = await CallWithAuth(
        'GET',
        `${GET_HISTORY}?date=${filters?.date}`,
      );
      if (response?.res?.data?.status === 200) {
        const statusType =
          typeof response?.res?.data?.data === 'object' ? 'object' : 'other';
        if (statusType === 'object') {
          if (Object?.keys(response?.res?.data?.data)?.length > 0) {
            const structuredData = formatDataIntoArray(
              response?.res?.data?.data,
            );
            setFormattedData(structuredData);
            setData(response?.res?.data?.data);
          } else {
            setFormattedData([]);
            setData([]);
          }
        }
      }
      setLoading(false);
    } catch (error) {
      toast.error(error?.message);
      setLoading(false);
      throw error;
    }
  };

  const onDownload = async () => {
    try {
      setLoading(true);
      const response = await ApiHandler({
        endPoint: ENDPOINTS?.getMaterialPdf,
        method: API_METHODS.PUT,
        reqParam: {
          searchQuery: filters?.searchQuery,
          trucks: filters?.trucks,
          excavators: filters?.excavators,
          sourcepoint: filters?.sourcepoint,
          dumppoint: filters?.dumppoint,
          date: filters?.date,
          operators: filters?.operators,
          materials: filters?.materials,
        },
        blobType: true,
      });

      const pdfBytes = response?.data;
      const pdfDoc = await PDFDocument.load(pdfBytes);
      const lastPageIndex = pdfDoc.getPageCount() - 1;
      pdfDoc.removePage(lastPageIndex);
      const modifiedPdfBytes = await pdfDoc.save();
      const url = window.URL.createObjectURL(new Blob([modifiedPdfBytes]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', 'summary.pdf');
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);

      setLoading(false);
    } catch (error) {
      console.log(error);
      toast.error(error?.message);
      setLoading(false);
    }
  };

  useEffect(() => {
    if (filters?.date) {
      setData({});
      fetchTrucks();
    }
  }, [filters?.date]);

  useEffect(() => {
    const handleMaterial = ({ data, materials }) => {
      if (materials && materials.length > 0) {
        const filteredData = data.filter((d) =>
          materials?.includes(d?.material),
        );
        return filteredData;
      } else {
        return data;
      }
    };

    const handleSearch = ({ data, searchQuery }) => {
      if (searchQuery === '') {
        return data;
      }
      return data.filter((item) => {
        return item?.truckName
          ?.toLowerCase()
          ?.includes(searchQuery?.toLowerCase());
      });
    };

    const handleTruckName = ({ data, trucks }) => {
      if (trucks && trucks.length > 0) {
        const filteredData = data.filter((d) => trucks?.includes(d.truckName));
        return filteredData;
      } else {
        return data;
      }
    };

    const handleExcavatorName = ({ data, excavators }) => {
      if (excavators && excavators.length > 0) {
        const filteredData = data.filter((d) =>
          excavators?.includes(d?.contactTitle ?? '-'),
        );
        return filteredData;
      } else {
        return data;
      }
    };

    const handleOperators = ({ data, operators }) => {
      if (operators && operators.length > 0) {
        const filteredData = data.filter(
          (d) =>
            operators?.includes(d?.tr_operator) ||
            operators?.includes(d?.ex_operator),
        );
        return filteredData;
      } else {
        return data;
      }
    };

    const handleSourcePoint = ({ data, sourcepoint }) => {
      if (sourcepoint && sourcepoint.length > 0) {
        const filteredData = data.filter((d) =>
          sourcepoint?.includes(d?.sourcePoint),
        );
        return filteredData;
      } else {
        return data;
      }
    };

    const handleDumpPoint = ({ data, dumppoint }) => {
      if (dumppoint && dumppoint.length > 0) {
        const filteredData = data.filter((d) =>
          dumppoint?.includes(d?.dumpPoint),
        );
        return filteredData;
      } else {
        return data;
      }
    };

    const filterData = () => {
      let unFilteredData = formattedData;
      unFilteredData = handleSearch({
        data: unFilteredData,
        searchQuery: filters?.searchQuery,
      });

      unFilteredData = handleMaterial({
        data: unFilteredData,
        materials: filters?.materials,
      });
      unFilteredData = handleTruckName({
        data: unFilteredData,
        trucks: filters?.trucks,
      });
      unFilteredData = handleExcavatorName({
        data: unFilteredData,
        excavators: filters?.excavators,
      });
      unFilteredData = handleOperators({
        data: unFilteredData,
        operators: filters?.operators,
      });
      unFilteredData = handleSourcePoint({
        data: unFilteredData,
        sourcepoint: filters?.sourcepoint,
      });
      unFilteredData = handleDumpPoint({
        data: unFilteredData,
        dumppoint: filters?.dumppoint,
      });
      setFilteredData(makeTruckWiseData(unFilteredData));
    };

    try {
      filterData();
    } catch (error) {
      console.log('-----', error);
    }
  }, [
    filters?.materials,
    filters?.searchQuery,
    filters?.trucks,
    filters?.excavators,
    filters?.operators,
    filters?.dumppoint,
    filters?.sourcepoint,
    filters?.date,
    data,
  ]);

  return (
    <div>
      <div
        style={{ width: 'calc(100vw - 210px)' }}
        className=" d-flex justify-content-between align-items-center"
      >
        <div className="">
          <p style={{ font: 'normal normal 600 0.9rem Open Sans' }}>
            MATERIAL MOVEMENTS HISTORY
          </p>
        </div>

        <div className="">
          <SubHeader
            SubHeaderVisible
            date
            selectedDate={filters?.date}
            setSelectedDate={(v) => {
              setFilters({ ...filters, date: v });
            }}
            onSearchChange={(e) => {
              setFilters({ ...filters, searchQuery: e?.target?.value });
            }}
            showDownload
            onDownloadClick={() => {
              setShowPDF(true);
            }}
            toShowFilter
            filteredData={filters}
            setFilteredData={setFilters}
            materialMovementFilterModal={true}
          />
        </div>
      </div>
      <div className="mt-2">
        <HistoryTable loading={loading} data={filteredData} />
      </div>
      {showPDF && (
        <PdfModal
          onDownload={() => {
            onDownload();
          }}
          closeModal={() => {
            setShowPDF(false);
          }}
          loading={loading}
        >
          <div className="report-outer-view">
            <MaterialMovementReport
              propFilters={filters}
              selectedDate={filters?.date}
            />
          </div>
        </PdfModal>
      )}
    </div>
  );
};

export default MaterialMovementsHistory;
